import FilesRunner from "./FilesRunner.vue";
import FilesRunnerPagination from "./FilesRunnerPagination.vue";
import WalletsWork from "./WalletsWork.vue";

const FilesRunnerPlugin = {
  install(Vue) {
    Vue.component("files-runner", FilesRunner);
    Vue.component("files-runner-pagination", FilesRunnerPagination);
    Vue.component("files-runner-wallets", WalletsWork);
  }
};

export default FilesRunnerPlugin;
