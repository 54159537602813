export default {
	"default" : 						              ["transaction-id", "seed", "transaction-type", "digest", "signature"],
	"RegisterNodeTransaction"	:		        ["address", "public-key"],
	"UnregisterNodeTransaction"	:		      ["address", "public-key"],
	"TransferAssetsTransaction"	:		      ["from", "to", "fee", "description", { "recursive": "asset", "fields": ["amount","code"] } ],
	"RegisterNodeTransactionWithAmount":  ["address","amount","public-key"],
	"RegisterTokenTransaction" :		      ["from", "to", "amount", "code", "fee", "description", "precision"],
	"SystemRegisterNodeTransaction":	    ["address","request-public-key", "public-key"],
	"BlockchainConfigTransaction":		    null,
	"SystemUnregisterNodeTransaction":	  ["address", "request-public-key", "public-key"],
	"ContractTransaction" :				        ["public-key", "contract-id", "message-id", "message-contract"],
	"RegisterContract":					          ["public-key", "contract-id"],
	"AccessRightContract" :				        ["public-key", "contract-id", "access"],
	"CreatePoll":						              ["public-key", "description"],
	"VotingPoll":						              ["public-key"],
	"VoteCount":						              ["public-key","block-id", "votes"],
	"CreateTokenRateVoting":			        ["public-key"],
	"PostTokenRate":					            ["public-key", "course"],
	"GetTokenRate":						            ["public-key", "course", "counter-block-id"],	
	"UserDefineTransaction":	            ["message-id", "message"],	
	"Emission":	                          ["public-key", "code"],	
	"UpdateEmission":	                    ["public-key"],	
	"test_type":						              ["parA", "parB"]	
};
