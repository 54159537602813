<template>
<div class="content">
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header>
          <h3 class="title">Block (id={{$route.params.id}}) stats</h3>
        </md-card-header>
        <md-card-content>
          <md-table class="table-striped" table-header-color="green" v-for="item in last_blocks_table_data" :key="item.id">
            <md-table-row >
              <md-table-cell>ID</md-table-cell>
              <md-table-cell>{{item.id}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell>Transaction count</md-table-cell>
              <md-table-cell>{{item.nt}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell>Block digest</md-table-cell>
              <md-table-cell>{{item.block_digest}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell>Creation time</md-table-cell>
              <md-table-cell>{{item.timestamp}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell>Round</md-table-cell>
              <md-table-cell>{{item.round}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell>Merkle root</md-table-cell>
              <md-table-cell>{{item.merkle_root}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell>Previous block digest</md-table-cell>
              <md-table-cell>{{item.prev_block_digest}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell>Block header digest</md-table-cell>
              <md-table-cell>{{item.block_header_digest}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell>Fee transactions</md-table-cell>
              <md-table-cell>{{item.fee_transactions}}</md-table-cell>
            </md-table-row>
            <md-table-row>
              <md-table-cell colspan="2">
                <h3>Block transactions</h3>
                <!-- BEGIN transactions table -->
                <md-table
                  class="table-hover"
                >
                  <md-table-row>
                    <md-table-head>ID</md-table-head>
                    <md-table-head>From</md-table-head>
                    <md-table-head>To</md-table-head>
                    <md-table-head>Type</md-table-head>
                    <md-table-head>Info</md-table-head>
                  </md-table-row>
                  <md-table-row v-for="transaction in item.transactions" :key="transaction.id">

                    <md-table-cell md-label="Tr Id">{{
                      transaction.tr_id
                    }}</md-table-cell>
                    <md-table-cell md-label="From" >
                      {{transaction.from}}
                    </md-table-cell>
                    <md-table-cell md-label="To" >
                      {{transaction.to}}
                    </md-table-cell>
                    <md-table-cell md-label="Fee" >
                      {{transaction.type}}
                    </md-table-cell>
                    <md-table-cell md-label="Actions">
                      <router-link :to="'/explorer/transaction/'+transaction.tr_id+'/'" target="_blank">
                        <md-button class="md-just-icon">
                          <md-icon>search</md-icon>
                        </md-button>
                      </router-link>
                    </md-table-cell> 
                  </md-table-row>
                </md-table>
                <!-- END transactions table -->
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import globalConfig from "@/globalConfig.js";
//import { SlideYDownTransition } from "vue2-transitions";
export default {
    components: {
  //      SlideYDownTransition,
        //StatsCard,
        //ChartCard,
        //AnimatedNumber,
//        ProductCard,
//        GlobalSalesCard,
//        GlobalSalesTable,
//        AsyncWorldMap,
//        Map
    },
    methods: {
      parseTransactionsOutput(jsonResult) {
      let i=0;
      let transaction_data = []
      if (!jsonResult) {
        return [];
      }
      jsonResult.forEach(function (value) {
            i++;
            transaction_data.push({
                "index":        i,
                "seed":         value["seed"],
                "type":      value["transaction-type"],
                "tr_id":        value["transaction-id"],
                "from":         value["from"],
                "to":           value["to"],
                "fee":          value["fee"],
                "signature":    value["signature"],
                "digest":       value["digest"],
                "id":           value["ID"]
            });
        });
      return transaction_data;
    },
      resetTable() {
        this.lock_auto_update = false;
        this.updateBlockInfo();
      },
      updateBlockInfo: function(id=0) {  
        let this_id = id;
        if (id == 0) {
          this_id = this.block_id;
        }
        let blocks_data=[];
        axios.all([
            axios.post(globalConfig.BackendServer,
            {
                "jsonrpc":  "2.0",
                "method":   "get-blockchain-state",
                "params":   [],
                "id":       3,
                "version":  "1.0"
            }),
            axios.post(globalConfig.BackendServer,
            {
                "method":"get-block-by-id",
                "params":{
                    "id": parseInt(this_id)
                    }, 
                "id": 1, 
                "jsonrpc": "2.0", 
                "version": "1.0"
            })
        ]).then(axios.spread((resp, headerBlocks) => {
            this.project_name = resp.data.result["project"];
            this.blocks_count = resp.data.result["block-count"];
            this.nodes_count = resp.data.result["node-count"];
            this.consensus_round = resp.data.result["consensus-round"];
            this.transaction_count = resp.data.result["transaction-count"];
            let i=0;
            console.log(headerBlocks);
            let value = headerBlocks.data.result['block-data'];
            i++;
            blocks_data.push({
                "prev_block_digest":   value["previous-block-digest"],
                "merkle_root":  value["merkle-root"],
                "timestamp":    value["timestamp"],
                "id":           value["id"],
                "round":        value["round"],
                "nt":           value["transaction-count"],
                "block_header_digest":value["block-header-digest"],
                "fee_transactions":value["fee-transactions"],
                "transactions": this.parseTransactionsOutput(value["transactions"])

            });
            this.last_blocks_table_data = blocks_data
            console.log(blocks_data)
        }));
         //this.last_blocks_table_data = blocks_data;

      }
    },
    props: {
      // milliseconds to update map nodes
      update_interval: {
          type: Number,
          default: 20000
      }
    },
    data() {
        return {
            project_name:       "",
            transaction_count:  0,
            consensus_round:    0,
            nodes_count:        0,
            timer:              null,
            blocks_count:       0,
            last_blocks_table_data: [],
            number: "",
            lock_auto_update: false,
            block_id: this.$route.params.id
        };
    },
    mounted() {
        this.block_id = this.$route.params.id;
        this.updateBlockInfo(this.block_id);
        this.timer = setInterval(this.updateBlockInfo, Number(this.update_interval));
    },//mounted
    watch: {

    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
};
</script>
<style lang="scss" scoped>
  #clear_btn .accent_red, .accent_red {
    color: white !important;
    background-color: red !important;
  }
  .text-right {
    display: flex;
  }
</style>
