<template>
  <div class="md-layout-item md-size-100">
    <files-runner-wallets v-model="wallet.wallet">
    </files-runner-wallets>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-aurus">
        <div class="card-icon"> <md-icon>mail_outline</md-icon> </div>
          <h4 class="title">Smart Contracts Files</h4>
      </md-card-header>
      <md-card-content>
        <md-field>
          <label for="pages">Wallet</label>
          <p><br/>{{ (wallet.wallet !== undefined && wallet.wallet.pub !== undefined) ? wallet.wallet.pub : "Not selected" }}</p>
        </md-field>
        <md-field>
          <md-input
            type="search"
            class="mb-3"
            clearable
            style="width: 300px"
            placeholder="Search"
            v-model="files_list.search.query"
          >
          </md-input>
        </md-field>
        <md-table
          :value="files_list_data"
          :md-sort.sync="files_list.sort.cell"
          :md-sort-order.sync="files_list.sort.order"
          :md-sort-fn="customSort"
          class="table-striped table-hover"
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="ID" md-sort-by="id">{{
              item.id
            }}</md-table-cell>
            <md-table-cell md-label="Public Key" md-sort-by="pubk">{{
              item.pubk
            }}</md-table-cell>
            <md-table-cell md-label="File name" md-sort-by="filename">{{
              item.filename
            }}</md-table-cell>
            <md-table-cell md-label="File MD5" md-sort-by="filehash">{{
              item.filehash
            }}</md-table-cell>
            <md-table-cell md-label="Run">
              <md-button
                v-if="wallet.wallet !== undefined && wallet.wallet.loaded && wallet.wallet.priv.length"
                class="md-just-icon md-info md-simple"
                @click.native="runContractFile(item)"
              >
                <md-icon>dvr</md-icon>
              </md-button>
            </md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
      <md-card-actions md-alignment="space-between" v-if="files_list_total">
        <div class="">
          <p class="card-category">
            Showing {{ files_list_from + 1 }} to
            {{ files_list_to_print }}
            of {{ files_list_total }} entries
          </p>
        </div>
        <files-runner-pagination
          class="filesrunnerpagination-no-border filesrunnerpagination-info"
          v-model="files_list.pagination.currentPage"
          :per-page="files_list.pagination.perPage"
          :total="files_list_total"
        >
        </files-runner-pagination>
      </md-card-actions>
    </md-card>

    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-aurus">
        <div class="card-icon"> <md-icon>mail_outline</md-icon> </div>
        <h4 class="title">Runned Files</h4>
      </md-card-header>
      <md-card-content>
        <md-field>
          <md-input
            type="search"
            class="mb-3"
            clearable
            style="width: 300px"
            placeholder="Search"
            v-model="files_runned_list.search.query"
          >
          </md-input>
        </md-field>
        <md-table
          :value="files_runned_list_data"
          :md-sort.sync="files_runned_list.sort.cell"
          :md-sort-order.sync="files_runned_list.sort.order"
          :md-sort-fn="customRunnedSort"
          class="table-striped table-hover"
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="ID" md-sort-by="id">{{
              item.id
            }}</md-table-cell>
            <md-table-cell md-label="Public Key" md-sort-by="pubk">{{
              item.pubk
            }}</md-table-cell>
            <md-table-cell md-label="File name" md-sort-by="filename">{{
              item.filename
            }}</md-table-cell>
            <md-table-cell md-label="File MD5" md-sort-by="filehash">{{
              item.filehash
            }}</md-table-cell>
            <md-table-cell md-label="Date" md-sort-by="filedate">{{
              item.filedate
            }}</md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
      <md-card-actions md-alignment="space-between" v-if="files_runned_list_total">
        <div class="">
          <p class="card-category">
            Showing {{ files_runned_list_from + 1 }} to
            {{ files_runned_list_to_print }}
            of {{ files_runned_list_total }} entries
          </p>
        </div>
        <files-runner-pagination
          class="filesrunnerpagination-no-border filesrunnerpagination-success"
          v-model="files_runned_list.pagination.currentPage"
          :per-page="files_runned_list.pagination.perPage"
          :total="files_runned_list_total"
        >
        </files-runner-pagination>
      </md-card-actions>
    </md-card>
    <p style="text-align:right;font-size:25%;">Rev. 19 [25-09-2019]</p>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import $ from "jquery";
import { setTimeout, clearTimeout } from "timers";
import Fuse from "fuse.js";
import axios from "axios";
import "@/pages/Dashboard/Wasm/shampoox.wasm";
import Shampoox from "@/pages/Dashboard/Wasm/shampoox";

export default {
  name: "files-runner",
  props: {
    alert_timeout: {
      type: Number,
      default: 5
    },
    refresh_list_period: {
      type: Number,
      default: 15
    },
    files_list_per_page: {
      type: Number,
      default: 15
    },
    files_list_req_count: {
      type: Number,
      default: 100
    },
    files_list_transaction_type: {
      type: Number,
      default: 33
    },
    files_list_contract_id: {
      type: Number,
      default: 6613
    },
    files_list_runned_contract_id: {
      type: Number,
      default: 6613
    },
    node_url: {
      type: String,
      default: ""
    }
  },
  computed: {
    apiURL() {
      return this.node_url + "/api";
    },
    files_list_data() {
      let result = this.files_list.data.data;
      if (this.files_list.search.searched.length > 0) {
        result = this.files_list.search.searched;
      }
      return result.slice(this.files_list_from, this.files_list_to);
    },
    files_list_to() {
      let highBound = this.files_list_from + this.files_list.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.files_list_total;
      }
      return highBound;
    },
    files_list_to_print() {
      return this.files_list_to < this.files_list_total
        ? this.files_list_to
        : this.files_list_total;
    },
    files_list_from() {
      return (
        this.files_list.pagination.perPage *
        (this.files_list.pagination.currentPage - 1)
      );
    },
    files_list_total() {
      return this.files_list.search.searched.length > 0
        ? this.files_list.search.searched.length
        : this.files_list.data.data.length;
    },
    searchQuery() {
      return this.files_list.search.query;
    },
    files_list_current_page() {
      return this.files_list.pagination.currentPage;
    },

    files_runned_list_data() {
      let result = this.files_runned_list.data.data;
      if (this.files_runned_list.search.searched.length > 0) {
        result = this.files_runned_list.search.searched;
      }
      return result.slice(this.files_runned_list_from, this.files_runned_list_to);
    },
    files_runned_list_to() {
      let highBound = this.files_runned_list_from + this.files_runned_list.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.files_runned_list_total;
      }
      return highBound;
    },
    files_runned_list_to_print() {
      return this.files_runned_list_to < this.files_runned_list_total
        ? this.files_runned_list_to
        : this.files_runned_list_total;
    },
    files_runned_list_from() {
      return (
        this.files_runned_list.pagination.perPage *
        (this.files_runned_list.pagination.currentPage - 1)
      );
    },
    files_runned_list_total() {
      return this.files_runned_list.search.searched.length > 0
        ? this.files_runned_list.search.searched.length
        : this.files_runned_list.data.data.length;
    },
    searchRunnedQuery() {
      return this.files_runned_list.search.query;
    },
    files_runned_list_current_page() {
      return this.files_runned_list.pagination.currentPage;
    }
  },
  data() {
    return {
      tmo_ref: undefined,
      wallet: {
        wallet: undefined
      },
      files_list: {
        transaction_id: 1,
        data: {
          data: [],
          min: -1,
          max: -1
        },
        refresh_enabled: true,
        pagination: {
          perPage: this.files_list_per_page,
          currentPage: 1
        },
        refreshing: true,
        cread: 0,
        sort: {
          cell: "id",
          order: "asc"
        },
        search: {
          query: "",
          cells_to_search: ["id", "pubk", "filename", "filehash"],
          searched: [],
          fuse: null
        }
      },
      files_runned_list: {
        data: {
          data: [],
          min: -1,
          max: -1
        },
        refresh_enabled: true,
        pagination: {
          perPage: this.files_list_per_page,
          currentPage: 1
        },
        refreshing: true,
        cread: 0,
        sort: {
          cell: "id",
          order: "asc"
        },
        search: {
          query: "",
          cells_to_search: ["id", "pubk", "filename", "filehash"],
          searched: [],
          fuse: null
        }
      }
    };
  },
  methods: {
    /**
     * Функция запроса списка зарегистрированных файлов
     */
    files_list_request_files(_force_update = false) {
      if (_force_update || this.files_list.pagination.currentPage === 1) {
        try {
          if (_force_update && this.tmo_ref !== undefined)
            clearTimeout(this.tmo_ref);
          this.tmo_ref = undefined;
          let start_id = 0;
          let count_id = parseInt(this.files_list_req_count);
          if (this.files_list.pagination.currentPage !== 1) {
            if (this.files_list.data.min < parseInt(this.files_list_req_count))
              count_id =
                parseInt(this.files_list_req_count) - this.files_list.data.min;
            start_id = this.files_list.data.max - this.files_list.data.min;
          } else {
            start_id = this.files_list.cread;
          }
          let reqd = {
            "start-transaction": start_id,
            "count-transaction": count_id,
            "code-contract": [
              parseInt(this.files_list_contract_id),
              parseInt(this.files_list_runned_contract_id)
            ],
            "code-transaction": parseInt(this.files_list_transaction_type)
          };
          axios.post(this.apiURL, this.apiBody(1, "get-transactions-on-id", reqd)).then(
            _response => {
              if (_response.data.error === undefined) {
                let res = _response.data.result;
                if (
                  res !== undefined &&
                  res.transactions !== undefined &&
                  res.transactions.length > 0
                ) {
                  let curr_arr = [];
                  let curr_min = this.files_list.data.min;
                  let curr_max = this.files_list.data.max;

                  let curr_arr_r = [];
                  let curr_min_r = this.files_runned_list.data.min;
                  let curr_max_r = this.files_runned_list.data.max;
                  if (
                    this.files_list.pagination.currentPage === 1 &&
                    this.files_list.data.data.length
                  )
                    this.files_list.cread = this.files_list.cread + count_id;
                  if (
                    this.files_runned_list.pagination.currentPage === 1 &&
                    this.files_runned_list.data.data.length
                  )
                    this.files_runned_list.cread = this.files_runned_list.cread + count_id;
                  for (var i = 0; i < res.transactions.length; i++) {
                    let trns = res.transactions[i];
                    if (
                      parseInt(trns.description["contract-id"]) ===
                      parseInt(this.files_list_contract_id)
                    ) {
                      let trns_id = parseInt(trns.ID);
                      if (
                        this.files_list.data.min > trns_id ||
                        this.files_list.data.max < trns_id
                      ) {
                        if (curr_arr.length === 0 && curr_min === -1) {
                          curr_min = trns_id;
                          curr_max = trns_id;
                        }
                        if (curr_min > trns_id) curr_min = trns_id;
                        if (curr_max < trns_id) curr_max = trns_id;
                        let add_trns = {};
                        let cont;
                        add_trns.id = trns.ID;
                        add_trns.pubk = trns.description["public-key"];
                        cont = trns.description["message-contract"].split(":");
                        add_trns.filename = cont[0];
                        add_trns.filehash = cont[1];
                        curr_arr.push(add_trns);
                      } else {
                        this.files_list.cread = 0;
                      }
                    }
                    if (
                      parseInt(trns.description["contract-id"]) ===
                      parseInt(this.files_list_runned_contract_id)
                    ) {
                      let trns_id = parseInt(trns.ID);
                      if (
                        this.files_runned_list.data.min > trns_id ||
                        this.files_runned_list.data.max < trns_id
                      ) {
                        if (curr_arr_r.length === 0 && curr_min_r === -1) {
                          curr_min_r = trns_id;
                          curr_max_r = trns_id;
                        }
                        if (curr_min_r > trns_id) curr_min_r = trns_id;
                        if (curr_max_r < trns_id) curr_max_r = trns_id;
                        let add_trns = {};
                        let cont;
                        add_trns.id = trns.ID;
                        add_trns.pubk = trns.description["public-key"];
                        cont = trns.description["message-contract"].split(":");
                        add_trns.filename = cont[0];
                        add_trns.filehash = cont[1];
                        add_trns.filedate = ""; // TODO
                        curr_arr_r.push(add_trns);
                      } else {
                        this.files_runned_list.cread = 0;
                      }
                    }
                  }
                  if (curr_arr.length) {
                    if (this.files_list.data.min > curr_min)
                      this.files_list.data.min = curr_min;
                    if (this.files_list.data.max < curr_max)
                      this.files_list.data.max = curr_max;
                    this.files_list.data.data = curr_arr.concat(
                      this.files_list.data.data
                    );
                  }
                  if (curr_arr_r.length) {
                    if (this.files_runned_list.data.min > curr_min_r)
                      this.files_runned_list.data.min = curr_min_r;
                    if (this.files_runned_list.data.max < curr_max_r)
                      this.files_runned_list.data.max = curr_max_r;
                    this.files_runned_list.data.data = curr_arr_r.concat(
                      this.files_runned_list.data.data
                    );
                  }
                } else if (
                  res === undefined ||
                  res.transactions === undefined
                ) {
                  this.$notify({
                    message: "<b>Incorrect response format</b>",
                    icon: "add_alert",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger"
                  });
                }
              } else {
                this.$notify({
                  message:
                    "[" +
                    _response.data.error.code +
                    "] <b>" +
                    _response.data.error.message +
                    "</b>",
                  icon: "add_alert",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger"
                });
              }
              if (this.files_list.refreshing || this.files_runned_list.refreshing) {
                if (this.files_list.cread || this.files_runned_list.cread) {
                  this.files_list_request_files();
                } else {
                  this.tmo_ref = setTimeout(() => {
                    this.files_list_request_files();
                  }, parseInt(this.refresh_list_period) * 1000);
                }
              }
            },
            _e_response => {
              this.$notify({
                message: "<b><pre>" + _e_response.stack + "</pre></b>",
                icon: "add_alert",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
              });
            }
          );
        } catch (e) {
          this.$notify({
            message: "<b><pre>" + e.stack + "</pre></b>",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "rose"
          });
        }
      }
    },

    /**
     * Запуск файла контракта
     */
    runContractFile(item) {
      if (this.wallet.wallet !== undefined && this.wallet.wallet.pub) {
        Swal.fire({
          title: "Command line for file: '" + item.filename + "'",
          type: "question",
          html: `<div class="md-field md-theme-default">
              <input type="text" id="run-file-comm-line" class="md-input">
              </div>`,
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          buttonsStyling: false
        }) .then(result => {
          if (result.value) {
            let fileattr = "";
            let fileattrline = $("#run-file-comm-line");
            if (fileattrline !== undefined && fileattrline.length) fileattr = fileattrline.val();

            Shampoox().then(api => {
              try {
                var error = new api.Error();
                var keyPairs = new api.Pair.fromPrivateKey(this.wallet.wallet.priv, error);
                axios.all([
                  axios.post(
                    this.apiURL,
                    this.apiBody(2, "get-current-block-id", {})
                  )
                ]) .then(axios.spread((res) => {
                  let transaction = new api.Transaction();

                  transaction.runFile(
                    keyPairs,
                    String(res.data.result["current-block-id"]),
                    String(this.files_list.transaction_id),
                    String(this.files_list_contract_id),
                    String(item.filename + ":" + item.filehash),
                    String(item.filename),
                    String(item.filehash),
                    String(fileattr),
                    0.0,
                    error
                  );

                  axios.all([
                    axios.post(
                      this.apiURL,
                      this.apiBody(3, "send-transaction", JSON.parse(transaction.body))
                    )
                  ]) .then(axios.spread((r) => {
                    console.log(r);
                    if(r.data.error === undefined) {
                      Swal.fire({
                        title: "Run file success",
                        type: "success",
                        timer: this.alert_timeout * 1000,
                        confirmButtonClass: "md-button md-success",
                        buttonsStyling: false
                      });
                      this.files_list.transaction_id = this.files_list.transaction_id + 1;
                    } else {
                      Swal.fire({
                        title: "Run file failed",
                        html: "[" + r.data.error.code + "] " + r.data.error.message,
                        type: "error",
                        timer: this.alert_timeout * 1000,
                        confirmButtonClass: "md-button md-success",
                        buttonsStyling: false
                      });
                    }
                  })) .catch(e => {
                    console.log(e.stack);
                    Swal.fire({
                      title: "Run file failed",
                      html: "<pre>" + e.stack + "</pre>",
                      type: "error",
                      timer: this.alert_timeout * 1000,
                      confirmButtonClass: "md-button md-success",
                      buttonsStyling: false
                    });
                  });
                })) .catch(e => {
                  if(e !== undefined) console.log(e.stack);
                  Swal.fire({
                    title: "Run file failed",
                    html: e !== undefined ? "<pre>" + e.stack + "</pre>" : "",
                    type: "error",
                    timer: this.alert_timeout * 1000,
                    confirmButtonClass: "md-button md-success",
                    buttonsStyling: false
                  });
                });
              } catch (e) {
                if(e !== undefined) console.log(e.stack);
                Swal.fire({
                  title: "Run file failed",
                  html: e !== undefined ? "<pre>" + e.stack + "</pre>" : "",
                  type: "error",
                  timer: this.alert_timeout * 1000,
                  confirmButtonClass: "md-button md-success",
                  buttonsStyling: false
                });
              }
            });
          }
        });
      } else {
        Swal.fire({
          title: "Wallet not select",
          type: "warning",
          timer: this.alert_timeout * 1000,
          confirmButtonClass: "md-button md-success",
          buttonsStyling: false
        });
      }
    },

    /**
     * Функция переключения сортировки столбцов в таблице
     */
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.files_list.sort.cell;
        if (this.files_list.sort.order === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    customRunnedSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.files_runned_list.sort.cell;
        if (this.files_runned_list.sort.order === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    apiBody(id, method, params = {}) {
      return {
        method: method,
        params: params,
        id: id,
        jsonrpc: "2.0",
        version: "1.0"
      };
    }
  },
  mounted() {
    /**
     * Запуск автоматического периодического чтения списка файлов
     */
    this.files_list.refreshing = true;
    this.files_runned_list.refreshing = true;
    this.files_list_request_files(true);

    this.files_list.search.fuse = new Fuse(this.files_list.data.data, {
      keys: this.files_list.search.cells_to_search,
      threshold: 0.3
    });
    this.files_runned_list.search.fuse = new Fuse(this.files_runned_list.data.data, {
      keys: this.files_runned_list.search.cells_to_search,
      threshold: 0.3
    });
},
  watch: {
    searchQuery(value) {
      let result = this.files_list.data.data;
      if (value !== "") {
        result = this.files_list.search.fuse.search(
          this.files_list.search.query
        );
        console.log("SEARCH RESULT", result);
      }
      this.files_list.search.searched = result;
    },
    searchRunnedQuery(value) {
      let result = this.files_runned_list.data.data;
      if (value !== "") {
        result = this.files_runned_list.search.fuse.search(
          this.files_runned_list.search.query
        );
      }
      this.files_runned_list.search.searched = result;
    },
    files_list_current_page() {
      let pg_cnt = Math.ceil(this.files_list_total / this.files_list_per_page);
      if (
        (this.files_list.data.min > 1 &&
          this.files_list.pagination.currentPage >= pg_cnt - 1) ||
        this.files_list.pagination.currentPage === 1
      ) {
        this.files_list.refreshing = true;
        this.files_list_request_files(true);
      } else
        this.files_list.refreshing = false;
    },
    files_runned_list_current_page() {
      let pg_cnt = Math.ceil(this.files_list_total / this.files_list_per_page);
      if (
        (this.files_runned_list.data.min > 1 &&
          this.files_runned_list.pagination.currentPage >= pg_cnt - 1) ||
        this.files_runned_list.pagination.currentPage === 1
      ) {
        this.files_runned_list.refreshing = true;
        this.files_runned_list_request_files(true);
      } else
        this.files_runned_list.refreshing = false;
    }
  },
  beforeDestroy() {
    this.files_list.refreshing = false;
    this.files_runned_list.refreshing = false;
    if (this.tmo_ref !== undefined) {
      clearTimeout(this.tmo_ref);
      this.tmo_ref = undefined;
    }
  }
};
</script>

<style lang="scss" scoped></style>
