<template>
<div class="content">
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header>
          <h4 class="title">Transaction #{{$route.params.id}} info</h4>
        </md-card-header>
        <md-card-content>
          <md-table>
            <tbody  v-for="item in last_blocks_table_data" :key="item.tr_id">
              <md-table-row>
                <md-table-cell>Transaction ID</md-table-cell>
                <md-table-cell>{{item.tr_id}}</md-table-cell>
              </md-table-row>
              <md-table-row>
                <md-table-cell>Seed</md-table-cell>
                <md-table-cell>{{item.seed}}</md-table-cell>
              </md-table-row>
              <md-table-row>
                <md-table-cell>Transaction type</md-table-cell>
                <md-table-cell>{{item.tr_type}}</md-table-cell>
              </md-table-row>
              <md-table-row>
                <md-table-cell>From wallet</md-table-cell>
                <md-table-cell>{{item.from}}</md-table-cell>
              </md-table-row>
              <md-table-row>
                <md-table-cell>To wallet</md-table-cell>
                <md-table-cell>{{item.to}}</md-table-cell>
              </md-table-row>
              <md-table-row>
                <md-table-cell>Fee</md-table-cell>
                <md-table-cell>{{item.fee}}</md-table-cell>
              </md-table-row>
              <md-table-row>
                <md-table-cell>Signature</md-table-cell>
                <md-table-cell>{{item.signature}}</md-table-cell>
              </md-table-row>
              <md-table-row>
                <md-table-cell>Type</md-table-cell>
                <md-table-cell>{{item.type}}</md-table-cell>
              </md-table-row>
              <md-table-row>
                <md-table-cell>Digest</md-table-cell>
                <md-table-cell>{{item.digest}}</md-table-cell>
              </md-table-row>
              <md-table-row>
                <md-table-cell>ID</md-table-cell>
                <md-table-cell>{{item.id}}</md-table-cell>
              </md-table-row>
              <md-table-row>
                <md-table-cell colspan="2">
                  <h3>Transaction specific attributes</h3>
                  <md-table
                    class="table-hover"
                  >
                    <tbody v-for="(value, key) in item.ext" :key="key">
                      <md-table-row>
                        <md-table-cell>{{key}}</md-table-cell>
                        <md-table-cell>{{value}}</md-table-cell>
                      </md-table-row>
                    </tbody>
                  </md-table>
                </md-table-cell>
                
              </md-table-row>
            </tbody>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import globalConfig from "@/globalConfig.js";
import transaction_ext_fields from "./transaction_types_fields.js";

export default {
    components: {
//        SlideYDownTransition,
        //StatsCard,
        //ChartCard,
        //AnimatedNumber,
//        ProductCard,
//        GlobalSalesCard,
//        GlobalSalesTable,
//        AsyncWorldMap,
//        Map
    },
    methods: {
      getProperty( propertyName, object ) {
        var parts = propertyName.split( "." ),
          length = parts.length,
          i,
          property = object || this;

        for ( i = 0; i < length; i++ ) {
          property = property[parts[i]];
        }

        return property;
      },
      resetTable() {
        this.lock_auto_update = false;
        this.updateProjectStatus();
      },
      validate() {
          
          this.$validator.validateAll().then(isValid => {
              if (isValid) {
                this.lock_auto_update = true;
                this.updateProjectStatus(this.number);
              }
          });
      },
      updateProjectStatus: function(id=0) {  
        if (id == 0 && this.lock_auto_update) {
          return;
        }
        
        let blocks_data=[];
        let i=0;
        let ext = this.ext_fields;
        let details={};
        axios.all([
          axios.post(globalConfig.BackendServer,
            {                     
                "method":"get-transactions-on-id",
                "params":{
                    "count-transaction": 1,
                    "start-transaction": parseInt(this.$route.params.id)
                    }, 
                "id": 1, 
                "jsonrpc": "2.0", 
                "version": "1.0"
            })
        ]).then(axios.spread((transaction_info) => {
          let transaction = transaction_info.data.result.transactions[0];
          console.log(transaction);
          /* 
             Разбор дополнительных полей, наличие которых зависит от типа транзакции
             Такие поля описаны в transaction_types_fields.js
             Это может быть просто текстовое имя поля, в таком случае оно вытаскивается напрямую из результата запроса, который 
             возвращает бэкенд.
             А может быть специальное описание вида {"recursive": "field_xxx", "fields":["f1","f2",...]}
             Это значит, что в выдаче бэкенда есть поле вида:
             {
               ...
               "field_xxx" : 
                [
                  {"f1":"1234567", "f2":"1234567"}, 
                  ...
                ]
            
             }
             Сложный дескриптор типа "recursive" позволит вытащить из ответа бэкенда все вложенные поля f1 и f2 - и они попадут 
             в табличку с описанием транзакции
          */
          if (transaction.description["transaction-type"] in ext) {
            ext[transaction.description["transaction-type"]].forEach(function (field) {
              if (typeof(field)=="object" && "recursive" in field) {
                let array_field = field["recursive"];
                let level=-1;
                
                transaction.description[array_field].forEach(function (nested_obj) {
                  level++;
                  field["fields"].forEach(function (nested_field) {
                    details[nested_field+"["+level+"]"] = nested_obj[nested_field];
                  });//forEach
                });//forEach
              } else {
                details[field]=transaction.description[field];
              }
            });//forEach
          }
          // результат обработки дополнительных полей попадёт в --> details
          blocks_data.push({
            "tr_id":        transaction.description["transaction-id"],
            "seed":         transaction.description["seed"],
            "tr_type":      transaction.description["transaction-type"],
            "from":         transaction.description["from"],
            "to":           transaction.description["to"],
            "fee":          transaction.description["fee"],
            "signature":    transaction.description["signature"],
            "digest":       transaction["digest"],
            "type":         transaction["type"],
            "id":           transaction["ID"],
            "ext":          details

          });
        }));
       
        this.last_blocks_table_data = blocks_data;
      }
    },
    props: {
      // milliseconds to update map nodes
      update_interval: {
          type: Number,
          default: 20000
      }
    },
    data() {
        return {
            ext_fields: transaction_ext_fields,
            project_name:       "",
            test_items: {"a":1,"b":2,"c":3},
            transaction_count:  0,
            consensus_round:    0,
            nodes_count:        0,
            timer:              null,
            blocks_count:       0,
            last_blocks_table_data: [],
            number: "",
            lock_auto_update: false,
            touched: {
                number: false,
            },
            modelValidations: {
                required: {
                  required: true
                },
                number: {
                  required: true,
                  numeric: true
                }
            }
        };
    },
    mounted() {
        this.updateProjectStatus();
        //this.timer = setInterval(this.updateProjectStatus, Number(this.update_interval));
    },//mounted
    watch: {
        number() {
          this.touched.number = true;
        }
    },
    beforeDestroy() {
        //clearInterval(this.timer)
    }
};
</script>
<style lang="scss" scoped>
  #clear_btn .accent_red, .accent_red {
    color: white !important;
    background-color: red !important;
  }
  .text-right {
    display: flex;
  }
</style>
                                             