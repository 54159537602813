import BackendServer from "@/globalConfig.js"

class FetchCyclic {
    static get BACKENDS_LIST() {
        return BackendServer
    }
    
    static setErrorCallback(func) {
        FetchCyclic.errorCallback = func
    }
    static setSuccessCallback(func) {
        FetchCyclic.successCallback = func
    }
    //static currentBackendIndex = 0
    static post (url, data) {
        return fetch(url, data)
        .then(response=> {
            if (!response.ok) throw new Error(response.status);
            return response;
        })
        .then(FetchCyclic.successCallback)
        .catch(FetchCyclic.errorCallback )
    }
}

export {FetchCyclic}