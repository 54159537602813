<template>
  <div>
    <md-table v-model="transaction_types" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="">{{ item.name }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "transactions-table",
  props: {
    transaction_types: {
        type: Array,
        default: () => ([])
    },  
    tableHeaderColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selected: []
    };
  }
  
};
</script>
