import UserWallet from "./UserWallet.vue";
import UserPagination from "./UserPagination.vue";

const UserWalletFiles = {
  install(Vue) {
    Vue.component("user-wallet", UserWallet);
    Vue.component("user-pagination", UserPagination);
  }
};

export default UserWalletFiles;
