<template>
  <div class="md-layout ">
    <loading  :active.sync="isLoading" 
            :can-cancel="false" 
            :is-full-page="true"
    />

    <div class="md-layout-item md-size-100"> 
      <img src="/img/aurus-full.png" style="height: 50px;width:auto; margin-bottom: 40px;"/>
    </div>
    <div class="md-layout-item md-large-size-75 md-medium-size-100 md-medium-small-100"> 
      <md-card class="wallet-widget-height " >
        <md-card-header class="md-card-header-icon md-card-header-aurus">
            <div class="card-icon"> <md-icon>account_balance_wallet</md-icon></div>
              <h4 v-show="!wallet.loaded" class="title">Existing wallet</h4>
              <h4 v-show="wallet.loaded" class="title" style="overflow-wrap: break-word;">Wallet: {{ wallet.address }}&nbsp;<span v-if="smallLoader">Updating data... </span></h4>
        </md-card-header>
        <md-card-content>
          <div v-show="!wallet.loaded">
            <md-button  class="md-success md-fileinput button-fixed-width-wide" md-alignment="left">
              Log in
              <input type="file" @change="loadContainerFile" />
            </md-button> 
          </div>
          <div v-show="wallet.loaded">
          <span style="text-decoration: underline;"><h4 style="margin-top:15px;margin-bottom:0;">Balance</h4></span>
          <!-- display tokens balance -->
          <md-table v-model="wallet.balance" table-header-color="aurus" class="table-striped table-hover text-accent" >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Asset">{{ item.code }}</md-table-cell>
              <md-table-cell md-label="Balance">{{ item.amount }}</md-table-cell>
            </md-table-row>
          </md-table>
          <div>
            <md-button v-if="wallet.address" class="md-success button-fixed-width-wide button-text-left" md-alignment="left" @click="openTransferAssetForm">
              Send<i class="fas fa-upload" style="padding-left:10px;font-size:0.9rem !important"></i>
            </md-button>
          </div>
          <div>
          <!--
          <md-button v-if="wallet.address" class="md-notice button-fixed-width-wide button-text-left" md-alignment="left" @click="doNothing">
            Receive <i class="fas fa-download" style="padding-left:10px;font-size:0.9rem !important"></i>
          </md-button>
          -->
          </div>

          <div>
          <md-button v-if="wallet.address" class="md-notice button-fixed-width-wide button-text-left" md-alignment="left" @click="toggleTransactionsHistory">
            History<i class="fas fa-exchange-alt" style="padding-left:10px;font-size:0.9rem !important"></i>
          </md-button>
          </div>
          <div>
          <md-button v-if="wallet.address" class="md-notice button-fixed-width-wide " md-alignment="left" @click="CloseWallet">
            Log out <i class="fas fa-sign-out-alt" style="padding-left:10px;font-size:0.9rem !important"></i>
          </md-button>
          </div>
          </div>
        </md-card-content>
      </md-card>
      <md-card class="wallet-widget-height" v-show="!wallet.loaded">
        <md-card-header class="md-card-header-icon md-card-header-aurus">
            <div class="card-icon"> <md-icon>fiber_new</md-icon> </div>
              <h4  class="title">New wallet</h4>
        </md-card-header>
        <md-card-content>
          <md-button  class="md-success button-fixed-width-wide" md-alignment="left" @click="createWallet">
              Create new wallet
          </md-button>
        </md-card-content>
      </md-card>
      <md-card class="wallet-widget-height" v-show="!wallet.loaded">
        <md-card-header class="md-card-header-icon md-card-header-aurus">
            <div class="card-icon"> <md-icon>vpn_key</md-icon> </div>
              <h4  class="title">Restore wallet from existing private key</h4>
        </md-card-header>
        <md-card-content>
           <md-button  class="md-success button-fixed-width-wide"  md-alignment="left" @click="createWalletFromExistKey">
              Restore
            </md-button>
        </md-card-content>
      </md-card>
    </div>


     <div v-show="sendtoken" class="md-layout-item md-small-size-100">
        <md-card class="wallet-widget-height" md-theme="aurus">
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon">
            <md-icon>arrow_upward</md-icon>
          </div>
          <h4 class="title">Transfer</h4>
        </md-card-header>
        <md-card-content>
          <md-field>
            <label>Address</label>
            <md-input v-model="transaction.destination" type="text"></md-input>
          </md-field>
          <!--
            <md-field>
            <label>Asset</label>
            <md-input v-model="transaction.asset" type="text"></md-input>
          </md-field>
          -->
          <md-field >
            <label for="token_list">Asset</label>
            <md-select v-model="transaction.asset" name="token_list">
              <md-option
                v-for="item in wallet.balance"
                :key="item.code"
                :label="item.code"
                :value="item.code"
              >
                {{ item.code }}
              </md-option>
            </md-select>
          </md-field>
          <md-field>
            <label>Amount</label>
            <md-input v-model="transaction.amount" type="text" pattern="^[0-9]+$" placeholder="0"></md-input>
          </md-field>
          <md-field>
            <label>Description (max: 32 characters)</label>
            <md-input v-model="transaction.description" type="text"></md-input>
          </md-field>
          <md-button v-if="wallet.address" class="md-success button-fixed-width" md-alignment="left" @click="sendTransaction" :disabled="!sendBtnEnabled || blockSend">
            Send
          </md-button>
          <md-button v-if="wallet.address" class="md-notice button-fixed-width" md-alignment="left" @click="cancelSend">
            Cancel
          </md-button>
        </md-card-content>
      </md-card>
    </div>

     <div v-show="createasset" class="md-layout-item md-small-size-100">
      <md-card class="wallet-widget-height">
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon">
            <md-icon>mail_outline</md-icon>
          </div>
          <h4 class="title">Asset</h4>
        </md-card-header>
        <md-card-content>
          <md-field>
            <label>Address</label>
            <md-input v-model="asset.destination" type="text"></md-input>
          </md-field>
          <md-field>
            <label>Asset name</label>
            <md-input v-model="asset.name" type="text"></md-input>
          </md-field>
          <md-field>
            <label>Asset description</label>
            <md-input v-model="asset.description" type="text"></md-input>
          </md-field>
          <md-field>
            <label>Amount</label>
            <md-input v-model="asset.amount" type="text"></md-input>
          </md-field>
          <md-button v-if="wallet.address" class="md-success" md-alignment="left" @click="CreateAsset">
            Send
          </md-button>
          <md-button v-if="wallet.address" class="md-fail" md-alignment="left" @click="cancelSend">
            Cancel
          </md-button>
        </md-card-content>
      </md-card>
    </div>

    <div v-show="showHistoryCard && wallet.loaded" class="md-layout-item md-medium-size-100 md-large-size-50">
          <md-card class="wallet-widget-height">
          <md-card-header class="md-card-header-icon md-card-header-aurus">
        
              <div class="card-icon"><md-icon>call_received</md-icon></div>
              <h4 class="title">Incoming transactions</h4>
            </md-card-header>
            <md-card-content>            
          <md-table v-model="wallet.inputTransaction" table-header-color="aurus" class="table-striped table-hover text-accent">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Wallet">{{ item.wallet }}</md-table-cell>
               <md-table-cell md-label="Code">{{ item.code }}</md-table-cell>
              <md-table-cell md-label="Amount">{{ item.amount }}</md-table-cell> 
              <md-table-cell md-label="Description">{{ item.description }}</md-table-cell> 
            </md-table-row>
          </md-table>
            </md-card-content>
          </md-card>
    </div>

    <div v-show="showHistoryCard && wallet.loaded" class="md-layout-item md-medium-size-100 md-large-size-50">
          <md-card class="wallet-widget-height">
            <md-card-header class="md-card-header-icon md-card-header-aurus">
              <div class="card-icon"><md-icon>call_made</md-icon></div>
              <h4 class="title">Outgoing transactions</h4>
            </md-card-header>
            <md-card-content>
              <md-table v-model="wallet.outputTransaction" table-header-color="aurus" class="table-striped table-hover text-accent">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Wallet">{{ item.wallet }}</md-table-cell>
                   <md-table-cell md-label="Code">{{ item.code }}</md-table-cell>
                  <md-table-cell md-label="Amount">{{ item.amount }}</md-table-cell> 
                  <md-table-cell md-label="Description">{{ item.description }}</md-table-cell> 
                </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
    </div>

  </div>
</template>

<script>

import { setTimeout, clearTimeout } from "timers";
import { restoreMnemonicHtml, outputMnemonicHtml, bip39Dictionary } from "./constants.js";
import api from "../../../../utils/api.js";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import $ from "jquery";
import axios from "axios";
import globalConfig from "@/globalConfig.js";
import "@/pages/Dashboard/Wasm/shampoox.wasm"
import Shampoox from "@/pages/Dashboard/Wasm/shampoox";
import "@/pages/Dashboard/Wasm/Container.wasm";
import Container from "@/pages/Dashboard/Wasm/Container";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { parse } from 'path';

export default {
  name: "user-wallet",
  components: {
  Loading
  },
  props: {
    refresh_list_period: {
      type: Number,
      default: 15
    },
    files_list_per_page: {
      type: Number,
      default: 15
    },
    files_list_req_count: {
      type: Number,
      default: 100
    },
    files_list_transaction_type: {
      type: Number,
      default: 33
    },
    files_list_contract_id: {
      type: Number,
      default: 6613
    },
    node_url: {
      type: String,
      default: globalConfig.BackendServer
    },
    wallets: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    sendBtnEnabled() {
      
      let amount_ok=false
      let memo_ok=false
      if (this.transaction.amount) {
        try {
          let tmp = parseFloat(this.transaction.amount)
          amount_ok = true
        } catch {
          amount_ok = false
        }
      }
      if (this.transaction.description.length>0 && this.transaction.description.length <= 32) {
        memo_ok = true
      }
      return amount_ok && memo_ok
    }
  },
  data() {
    //host, wallet, asset, amount, description
    return {
      isLoading:  false,
      smallLoader: false,
      blockSend:  false,
      sendtoken: false,
      createasset: false,
      showHistoryCard : false,
      existPrivateKey: false,
      passphrase: "",
      transaction:{
        destination: "",
        asset:0,
        amount:null,
        description:"",
        body: ""
      },
      asset:{
        destination: "",
        name: "",
        description:"",
        amount:null,
        body: ""
      },
      contract:{
        id:null
      },
      files_list: {
        data: {
          data: [],
          min: -1,
          max: -1
        },
        refresh_enabled: true,
        pagination: {
          perPage: this.files_list_per_page,
          currentPage: 1
        },
        refreshing: true,
        tmo_ref: undefined,
        cread: 0,
        sort: {
          cell: "id",
          order: "asc"
        },
        search: {
          query: "",
          cells_to_search: ["id", "pubk", "filename", "filehash"],
          searched: [],
          fuse: null
        }
      },
      wallet: {
        address: "",
        message: "",
        hash: "",
        keyPair:{},
        inputTransaction:{},
        outputTransaction:{},
        assetCode:{},
        name: "not set",
        size: 0,
        loaded: false,
        table_transaction: false,
        transaction_id: 1,
        progress: {
          show: false,
          value: 0
        },
        notification: {
          show: false,
          message: ""
        },
        balance:[]
      }
    };
  },

  methods: {

    async getLastBlockID() {
      let result = '';
      await api.getLastBlockId()
        .then((data) => {
          result = data.result['current-block-id']
        })
        .catch((err) => {
          console.log(`Error get last block ID ${err}`)
        })
      return result
    },

    async getAssetCode() {
      let assets = [];
      await api.getAssetCode()
        .then((data) => {
          assets = data
        })
        .catch((err) => {
          console.log(`Error get last block ID ${err}`)
        })
    return assets
    },

    getInputTransaction() {
        api.getInputTransaction(this.wallet.address, this.wallet.assetCode)
        .then((res) => {
          this.wallet.inputTransaction = res;
        })
        .catch((err) =>{
          console.log(`Error get input transaction ${err}`)
        })
    },

    getOuptupTransaction(){
        api.getInputTransaction(this.wallet.address, this.wallet.assetCode)
        .then((res) => {
          this.wallet.outputTransaction = res;
        })
        .catch((err) =>{
          console.log(`Error get output transaction ${err}`)
        })
    },


    CloseWallet(){
      this.wallet.keyPair = {};
      this.wallet.address = "";
      this.wallet.balance = [];
      this.wallet.inputTransaction = {};
      this.wallet.outputTransaction = {};
      this.wallet.loaded = false;
      this.existPrivateKey = false;
      this.wallet.table_transaction =false;
      this.sendtoken=false;
      clearInterval(this.timer);
    },
    toggleTransactionsHistory() {
      this.showHistoryCard = !this.showHistoryCard;
    },
    openTransferAssetForm(){
      this.sendtoken=true;
      this.wallet.table_transaction = false;
    },
    
    cancelPrivateKey(){
      this.existPrivateKey=false;
    },
    openCreateAssetForm(){
      this.createasset=true;
      this.wallet.table_transaction = false;
    },
    cancelSend(){
      this.sendtoken=false;
      this.createasset = false;
      this.wallet.table_transaction = true;
    },
    /**
     * Функция генерации крипто контейнера
     */
    createWallet(){
      Swal.fire({
          title: 'Input secret phrase',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          preConfirm: (phrase) => {
            if (!phrase.length) {
              return Swal.showValidationMessage(`Phrase is empty`)
              }
            },
          }).then((result) => {
            if (result.value) {
              Shampoox().then(api => {
                var error = new api.Error();
                let mnemonicCode = ""
                mnemonicCode = api.Bip39.generateMnemonic();
                let keyPairs = new api.Pair.fromMnemonic(mnemonicCode,"",error); //using empty BIP39 passphrase 

                //var keyPairs = new api.Pair.random(error);
                Container().then((ctr) => {
                  let errorContainer = new ctr.Error();
                  let container = ctr.create_container(keyPairs.privateKey, keyPairs.publicKey, String(result.value), errorContainer);
                  if (container === undefined) {
                    Swal.fire(
                      {
                        type: "fail",
                        html: "Container was not created",
                        confirmButtonClass: "md-button md-success",
                        buttonsStyling: false});
                  } else {
                      var string = this.createHexString(container);
                      this.download(string,`${keyPairs.publicKey.slice(keyPairs.publicKey.length - 10)}.ctr`);

/*CREATE OUTPUTS AND ADD COPY BUTTON, HTML TEMLATE FROM CONSTANTS.JS */
                      Swal.fire(
                      {
                        type: "success",
                        html: outputMnemonicHtml,
                        confirmButtonClass: "md-button md-success",
                        buttonsStyling: false,
                        onOpen: () => {
                          let i=0
                          let mnemonicCodeArr=Array.from(mnemonicCode.split(" "))
                          const outputList = Array.from(document.querySelectorAll(".swal2-mnemonic-output"))
                          const copyButton = document.querySelector(".swal2-mnemonic-button_copy")
                          outputList.forEach((item)=>{
                            item.textContent= mnemonicCodeArr[i] ? mnemonicCodeArr[i] : " "
                            i+=1
                          })
/*ADD EVENT LISTENER TO COPY BUTTON AND DEFINE COPY TO CLIPBOARD FUNC */
                          copyButton.addEventListener("click", () => {
                             window.navigator.clipboard.writeText(mnemonicCode)
                             .then(()=>{
                               Swal.fire({
                                type: "success",
                                position: 'top-end',
                                title: 'Copied',
                                showConfirmButton: false,
                                timer: 1000
                              })
                             })
                             .catch((err)=>{
                               console.log(`Error: ${err}`)
                             })
                          })
                        }

                      })
                    }
                  })
                  .catch((err)=>{
                    console.log(`Error: ${err}`)});
                });
              }
        })
  },

    CreateWalletFromMnemonic(secret, mnemonic){

            Shampoox().then(api => {
            var error = new api.Error();
            //var keyPairs =  new api.Pair.fromPrivateKey(this.wallet.keyPair.private_key,error);
            let bip39_validation = api.Bip39.validate_mnemonic(mnemonic)
            if (!bip39_validation) {
              Swal.fire({
                  type: "error",
                  html: "Container wasn't created, wrong mnemonic code",
                  confirmButtonClass: "md-button md-success",
                  buttonsStyling: false
                });
              console.error("BIP39 error", mnemonic)
              return 
            }

            let keyPairs = new api.Pair.fromMnemonic(mnemonic,"",error); //using empty BIP39 passphrase 
            
            Container().then((ctr) => {
              let errorContainer = new ctr.Error();
              let container = ctr.create_container(keyPairs.privateKey, keyPairs.publicKey, String(secret), errorContainer);
              if (container === undefined)
              {
                Swal.fire({
                  type: "error",
                  html: "Container wasn't created",
                  confirmButtonClass: "md-button md-success",
                  buttonsStyling: false
                });
              }
              else{
                var string = this.createHexString(container);
                this.download(string,`${keyPairs.publicKey.slice(keyPairs.publicKey.length - 10)}.ctr`);
                Swal.fire({
                  type: "success",
                  html:" <br> Wallet address:<br>"+ keyPairs.publicKey +" <br> Mnemonic phrase:<br><strong>"+ mnemonic +"</strong> <br> <br> Please keep in mind secret phrase and BIP39 mnemonic phrase. Don't forget to save file container.ctr on 2 different external storages",
                  confirmButtonClass: "md-button md-success",
                  buttonsStyling: false
                });
              }
          });
        });
},
createWalletFromExistKey(){
      //this.existPrivateKey=true;
      let app = this
      Swal.fire({
        //title: 'Choose secret phrase and input mnemonic phrase',
        html: restoreMnemonicHtml,
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",

/*GET VALUE FROM FIRST INPUT AND PASTE TO ALL INPUTS */
        onOpen: () => {
          const confurmButton = Swal.getConfirmButton();
          const inputList = Array.from(document.querySelectorAll("#restore_mnemonic"));
          const inputOne = inputList[0];
          const inputLast = inputList[11];

          confurmButton.setAttribute("disabled", "");

          /*CHECK MNEMONIC AND INTEGRITY AND SET ATTR OF BUTTON */
          function delAttr({ inputIntegrity, preMnemonic }) {
            app.isLoading = true
            Shampoox().then((api) => {
              if (inputIntegrity && api.Bip39.validate_mnemonic(preMnemonic)) {
                confurmButton.removeAttribute("disabled");
              } else {
                confurmButton.setAttribute("disabled", "");
              }
              app.isLoading = false
            });
          }

          /*GET MNEMONIC FROM INPUTS AND INTEGRITY OF FIELDS */
          function getAttrInput() {
            let preMnemonic = "";
            let inputIntegrity = true;

            inputList.forEach((item) => {
              inputIntegrity = inputIntegrity && Boolean(item.value);
              preMnemonic += item.value + " ";
            });
            return {
              inputIntegrity: inputIntegrity,
              preMnemonic: preMnemonic,
            };
          }

          inputLast.addEventListener("input", () => {
            if (bip39Dictionary.includes(inputLast.value))
              delAttr(getAttrInput())
          });

          inputOne.addEventListener("paste", (evt) => {
            evt.preventDefault();
            const paste = (evt.clipboardData || window.clipboardData).getData("text");
            let words = []
            let count=0

            if (paste.split(" ").length == 12) {
              words = paste.split(" ")
              inputList.forEach((item, i) => {
                item.value = words[i];
              });
            } else if(paste.split("\n").length==12){
              words = paste.split("\n")
              inputList.forEach((item, i) => {
                item.value = words[i];
              });
            } else {
              inputList[0].value=paste
            }


            delAttr(getAttrInput())
          });
        },

        preConfirm: () => {
          let phrase = document.getElementById('restore_secret')
          if(!phrase.value.length) {
            Swal.showValidationMessage(`Phrase is empty`)
             phrase.style.borderColor='#f27474'
             phrase.style.boxShadow='0 0 2px #f27474'
          }
          const mnemonicList = Array.from(document.querySelectorAll("#restore_mnemonic"))
          let mnemonic = []
          mnemonicList.forEach((item)=>{
            mnemonic += item.value + " "
          })
          return [phrase.value, mnemonic]
        },
      }).then((result) => {
        if (result.value) {
          //console.log(result.value)
          this.CreateWalletFromMnemonic(result.value[0], result.value[1])
        }
      })
      .catch((err)=>{
          console.log(`Error: ${err}`)
        });
},

parseHexString(str) { 
    var result = [];
    while (str.length >= 8) { 
        result.push(parseInt(str.substring(0, 8), 16));
        str = str.substring(8, str.length);
    }
    return result;
},

createHexString(arr) {
    var result = "";
    var z;

    for (var i = 0; i < arr.length; i++) {
        var str = arr[i].toString(16);

        z = 8 - str.length + 1;
        str = Array(z).join("0") + str;

        result += str;
    }

    return result;
  },

    download(data, filename) {
    var file = new Blob([data], {
    type: 'application/octet-stream'
  });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
        url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
      }
    },


    /**
     * Функция запроса стейта кошелька
     */
      updateWalletStatistic: async function () {
        this.smallLoader = true;
        let assets = await this.getAssetCode();
        if (assets) {
          this.wallet.assetCode = assets
          if (!this.sendtoken)
            this.transaction.asset = assets[0]["name"];
        } else {
          console.warn("error fetching tokens list from backend")
          return
        }
        await this.getInputTransaction();
        await this.getOuptupTransaction();
        api.getWalletState(this.wallet.address, this.wallet.assetCode)
        .then(({balance, isLoaded, tableTransaction}) => {
              this.wallet.balance = balance;
              this.wallet.loaded = isLoaded;
              this.wallet.table_transaction = tableTransaction;
              this.smallLoader = false;
        })
        .catch((err)=>{
          console.log(`Error in get wallet state: ${err}`)
        })
      },
        /**
     * Функция запроса списка зарегистрированных файлов
     */
    files_list_request_files(_force_update = false) {
    },

    /**
     * Функция переключения сортировки столбцов в таблице
     */
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.files_list.sort.cell;
        if (this.files_list.sort.order === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },



    /**
     * Загрузка файла контейнера
     */
    loadContainerFile(infl) {
      
      this.existPrivateKey = false;
      let files = infl.target.files || infl.dataTransfer.files;
      if (!files.length) return;

      let reader = new FileReader();
      reader.onloadstart = ls_e => {
        this.wallet.progress.value = 0;
        if (ls_e.lengthComputable) this.wallet.progress.show = true;
      };
      reader.onprogress = lp_e => {
        if (lp_e.lengthComputable)
          this.wallet.progress.value = parseInt(
            (lp_e.loaded / lp_e.total) * 100,
            10
          );
      };
      reader.onerror = ler_e => {
        this.$notify({
          message:
            "File load failed: [" +
            ler_e.target.error.code +
            "] " +
            ler_e.target.error.message,
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          timeout: 0,
          type: "danger"
        });
      };

      reader.onload = e => {
        try {
          var arrayBufferNew = null;
          arrayBufferNew  = e.target.result;
          var uint8array = this.parseHexString(arrayBufferNew);
          Swal.fire({
              title: 'Input secret phrase',
              input: 'password',
              inputAttributes: {
                autocapitalize: 'off'
                },
              showCancelButton: true,
              showLoaderOnConfirm: true,
              confirmButtonClass: "md-button md-success",
              cancelButtonClass: "md-button md-danger",
              preConfirm: (phrase) => {
                if (!phrase.length) {
                  return Swal.showValidationMessage(`Phrase is empty`)
                  }
                },
            }).then((result) => {
                if (result.value) {
                  Container().then((ctr) => {
                    let errorContainer = new ctr.Error();
                    let keys = ctr.open_container(uint8array, result.value, errorContainer);
                    if (keys === undefined) {
                      Swal.fire({
                        type: "fail",
                        html: "Container not load",
                        confirmButtonClass: "md-button md-success",
                        buttonsStyling: false});
                    } else {
                      Swal.fire({
                        type: "success",
                        html:"<br> Wallet address:<br>"+keys.public_key,
                        confirmButtonClass: "md-button md-success",
                        buttonsStyling: false});
                      this.wallet.keyPair = keys;
                      this.wallet.address = keys.public_key;
                      this.updateWalletStatistic();
                      this.timer = setInterval(this.updateWalletStatistic, Number(20000));                                                              
                    }
                  });
                }
              });
        } catch (e) {
          this.$notify({
            message: "Calculate hash failed: " + e.stack,
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "top",
            timeout: 0,
            type: "danger"
          });
        } finally {
          infl.target.value = null;
        }
        this.wallet.progress.value = 100;
        this.wallet.progress.show = false;
      };
      try {
        reader.readAsBinaryString(files[0]);
      } catch (e) {
        this.$notify({
          message: "File load failed: " + e.stack,
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          timeout: 0,
          type: "danger"
        });
      }
    },


    /**
     * Обработчик кнопки отправки транзакции
     */
    async sendTransaction() {
      this.doTransfer(
        this.node_url,
        this.transaction.destination,
        this.transaction.asset,
        this.transaction.amount,
        this.transaction.description,
      );
    },

      CreateAsset() {
      Shampoox().then(api => {
        let error = new api.Error();
        let trns;
        let Pair = new api.Pair.fromPrivateKey(this.wallet.keyPair.private_key,error);
        let transaction = new api.Transaction();
        this.wallet.table_transaction = true;
        this.createasset = false;
        api.getLastBlockId()
        .then((res) => {
          let ret =  transaction.registerToken(
            Pair,
            String(this.asset.destination),
            String(this.asset.name),
            String(this.asset.description),
            String(res.data.result["current-block-id"]),
            String(this.wallet.transaction_id),
            0,
            Number(this.asset.amount),
            0.0,
            error
          );
          console.error(error);
          this.transaction.body = transaction.body;
          try {
            const params = JSON.parse(this.transaction.body)  
          } catch (e) {
            this.$notify({
                message: "Error creating transaction, "+error.what,
                icon: "add_alert",
                horizontalAlign: "center",
                verticalAlign: "top",
                timeout: 0,
                type: "danger"
              });
              this.blockSend = false
              return 
          }
          api.sendTransaction(params)
          .then((res) => {
            if(res.result!="true") {
              this.$notify({
                message: res.error["message"],
                icon: "add_alert",
                horizontalAlign: "center",
                verticalAlign: "top",
                timeout: 0,
                type: "fail"
              });
            } else {
              this.$notify({
                message: "send successfully",
                icon: "add_alert",
                horizontalAlign: "center",
                verticalAlign: "top",
                timeout: 0,
                type: "success"
              });
              this.wallet.transaction_id =
              this.wallet.transaction_id + 1;
            }
          })
          .catch((err) => {
            console.log(`Error in send transaction ${err}`)
          })
        })
        .catch((err) => {
          console.log(`Error in get curr block ID ${err}`)
        })
      })
    },

    doTransfer(host, wallet, asset, amount, description) {
      if (Number(amount)==0 || !amount) {
        this.$notify({
          message: "Unable to send zero tokens",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          timeout: 0,
          type: "danger"
        });
        return ;
      }
      if (wallet=="") {
        this.$notify({
          message: "Please specify destination wallet",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          timeout: 0,
          type: "danger"
        });
        return ;
      }
      Shampoox().then(async api => {
        var error = new api.Error();
        var Pair = new api.Pair.fromPrivateKey(this.wallet.keyPair.private_key,error);
        var transaction = new api.Transaction();
        this.wallet.table_transaction = true;
        //this.sendtoken = false;
        let assets = this.wallet.assetCode;
        let code = assets.find(item => item.name ==  asset);
        if (code === undefined){
            var assetNames;
              assets.forEach(function (value) { 
                  assetNames = assetNames + " or " +value["name"];
                  });
            this.$notify({
                message: "Asset name "+asset+" not present in blockchain. Asset name must be "+assetNames,
                    icon: "add_alert",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    timeout: 0,
                    type: "fail"
                  });
        }
        var assetCode= code["code"];
        this.blockSend = true;
        let blockID = await this.getLastBlockID()
        if (!blockID) {
          this.$notify({
              message: "Unable to fetch last block id from backend",
              icon: "add_alert",
              horizontalAlign: "center",
              verticalAlign: "top",
              timeout: 0,
              type: "danger"
          })
          this.blockSend = false
          return
        }
        transaction.transfer(
          Pair,
          String(wallet),
          String(blockID),
          String(this.wallet.transaction_id),
          Number(assetCode),
          Number(amount),
          0.0,
          description,
          error
        );
        this.transaction.body = transaction.body;
        try {
          const params = JSON.parse(this.transaction.body)  
        } catch (e) {
          this.$notify({
              message: "Error creating transaction, "+error.what,
              icon: "add_alert",
              horizontalAlign: "center",
              verticalAlign: "top",
              timeout: 0,
              type: "danger"
            });
            this.blockSend = false
            return 
        }
        res = api.sendTransaction(params)
        
        api.sendTransaction(params)
        .then((res) => {
          this.blockSend = false;
          if(res.result != "true") {
            this.$notify({
              message: res.error["message"],
              icon: "add_alert",
              horizontalAlign: "center",
              verticalAlign: "top",
              timeout: 0,
              type: "fail"
            });
          } else {
            this.$notify({
              message: "Sent successfully",
              icon: "add_alert",
              horizontalAlign: "center",
              verticalAlign: "top",
              timeout: 0,
              type: "success"
            });
            this.transaction.amount=0
            this.wallet.transaction_id =
            this.wallet.transaction_id + 1;
          }
        })
        .catch((err)=> {
          this.$notify({
            message: "Unknown error, please refer to console log for details",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "top",
            timeout: 0,
            type: "danger"
          });
          console.error("Got error while executing 'send-transaction': ",err)
          this.blockSend = false
        })
      })
    },
    doNothing() {
    },
  },

  mounted() {

    this.files_list.search.fuse = new Fuse(this.files_list.data.data, {
      keys: this.files_list.search.cells_to_search,
      threshold: 0.3
    });

    /**
     * Запуск автоматического периодического чтения списка файлов
     */
    this.files_list.refreshing = true;
    this.files_list_request_files(true);
  },
  watch: {
    searchQuery(value) {
      let result = this.files_list.data.data;
      if (value !== "") {
        result = this.files_list.search.fuse.search(
          this.files_list.search.query
        );
      }
      this.files_list.search.searched = result;
    },
    files_list_current_page() {
      let pg_cnt = Math.ceil(this.files_list_total / this.files_list_per_page);
      if (
        (this.files_list.data.min > 1 &&
          this.files_list.pagination.currentPage >= pg_cnt - 1) ||
        this.files_list.pagination.currentPage === 1
      ) {
        this.files_list.refreshing = true;
        this.files_list_request_files(true);
      } else if (this.files_list.tmo_ref !== undefined) {
        this.files_list.refreshing = false;
        clearTimeout(this.files_list.tmo_ref);
        this.files_list.tmo_ref = undefined;
      }
    }
  },
  beforeDestroy() {
    this.files_list.refreshing = false;
    if (this.files_list.tmo_ref !== undefined) {
      clearTimeout(this.files_list.tmo_ref);
      this.files_list.tmo_ref = undefined;
    }
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss" scoped>
.wallet-widget-height {
  min-height:200px;
}
.button-fixed-width {
  width: 105px;
}
.button-fixed-width-wide {
  width: 170px;
}
.button-text-left {

}
</style>
