import CertificateAuthority from "./CertificateAuthority.vue";
import CertificateAuthorityPagination from "./CertificateAuthorityPagination.vue";
import WalletsWork from "./WalletsWork.vue";

const CertificateAuthorityPlugin = {
  install(Vue) {
    Vue.component("certificate-authority", CertificateAuthority);
    Vue.component("certificate-authority-pagination", CertificateAuthorityPagination);
    Vue.component("certificate-authority-wallets", WalletsWork);
  }
};

export default CertificateAuthorityPlugin;
