<template>
  <div class="md-layout-item md-size-100">
    <smart-contracts-wallets v-model="wallet.wallet">
    </smart-contracts-wallets>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-aurus">
        <div class="card-icon"> <md-icon>mail_outline</md-icon> </div>
        <h4 class="title">Smart Contracts files list</h4>
      </md-card-header>
      <md-card-content>
        <md-field>
          <md-input
            type="search"
            class="mb-3"
            clearable
            style="width: 300px"
            placeholder="Search"
            v-model="files_list.search.query"
          >
          </md-input>
        </md-field>
        <md-table
          :value="files_list_data"
          :md-sort.sync="files_list.sort.cell"
          :md-sort-order.sync="files_list.sort.order"
          :md-sort-fn="customSort"
          class="table-striped table-hover"
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="ID" md-sort-by="id">{{
              item.id
            }}</md-table-cell>
            <md-table-cell md-label="Public Key" md-sort-by="pubk">{{
              item.pubk
            }}</md-table-cell>
            <md-table-cell md-label="File name" md-sort-by="filename">{{
              item.filename
            }}</md-table-cell>
            <md-table-cell md-label="File MD5" md-sort-by="filehash">{{
              item.filehash
            }}</md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
      <md-card-actions md-alignment="space-between" v-if="files_list_total">
        <div class="">
          <p class="card-category">
            Showing {{ files_list_from + 1 }} to
            {{ files_list_to_print }}
            of {{ files_list_total }} entries
          </p>
        </div>
        <smart-contracts-pagination
          class="smartpagination-no-border smartpagination-success"
          v-model="files_list.pagination.currentPage"
          :per-page="files_list.pagination.perPage"
          :total="files_list_total"
        >
        </smart-contracts-pagination>
      </md-card-actions>
    </md-card>
    <md-card v-if="wallet.wallet">
      <md-card-header class="md-card-header-icon md-card-header-aurus">
        <div class="card-icon"> <md-icon>mail_outline</md-icon> </div>
        <h4 class="title">Smart Contracts add file</h4>
      </md-card-header>
      <md-card-content>
        <md-field>
          <label for="pages">Wallet</label>
          <p><br/>{{ (wallet.wallet !== undefined && wallet.wallet.pub !== undefined) ? wallet.wallet.pub : "Not selected" }}</p>
        </md-field>
        <div>
          <md-button class="md-success md-fileinput" md-alignment="left">
            Open File
            <input type="file" @change="loadContractFile" />
          </md-button>
        </div>
        <md-field v-if="append_file.message.length">
          <label>Message</label>
          <h4><br />{{ append_file.message }}</h4>
        </md-field>
        <md-button class="md-success" md-alignment="left" @click="sendFile">
          Send
        </md-button>
      </md-card-content>
    </md-card>
    <p style="text-align:right;font-size:25%;">Rev. 15 [12-09-2019]</p>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { setTimeout, clearTimeout } from "timers";
import Fuse from "fuse.js";
import axios from "axios";
import { MD5 } from "./MD5.js";
import "@/pages/Dashboard/Wasm/shampoox.wasm";
import Shampoox from "@/pages/Dashboard/Wasm/shampoox";

export default {
  name: "smart-contracts-files",
  props: {
    alert_timeout: {
      type: Number,
      default: 2
    },
    refresh_list_period: {
      type: Number,
      default: 15
    },
    files_list_per_page: {
      type: Number,
      default: 15
    },
    files_list_req_count: {
      type: Number,
      default: 100
    },
    files_list_transaction_type: {
      type: Number,
      default: 33
    },
    files_list_contract_id: {
      type: Number,
      default: 6613
    },
    node_url: {
      type: String,
      default: ""
    }
  },
  computed: {
    apiURL() {
      return this.node_url + "/api";
    },
    files_list_data() {
      let result = this.files_list.data.data;
      if (this.files_list.search.searched.length > 0) {
        result = this.files_list.search.searched;
      }
      return result.slice(this.files_list_from, this.files_list_to);
    },
    files_list_to() {
      let highBound = this.files_list_from + this.files_list.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.files_list_total;
      }
      return highBound;
    },
    files_list_to_print() {
      return this.files_list_to < this.files_list_total
        ? this.files_list_to
        : this.files_list_total;
    },
    files_list_from() {
      return (
        this.files_list.pagination.perPage *
        (this.files_list.pagination.currentPage - 1)
      );
    },
    files_list_total() {
      return this.files_list.search.searched.length > 0
        ? this.files_list.search.searched.length
        : this.files_list.data.data.length;
    },
    searchQuery() {
      return this.files_list.search.query;
    },
    files_list_current_page() {
      return this.files_list.pagination.currentPage;
    }
  },
  data() {
    return {
      wallet: {
        wallet: undefined
      },
      files_list: {
        data: {
          data: [],
          min: -1,
          max: -1
        },
        refresh_enabled: true,
        pagination: {
          perPage: this.files_list_per_page,
          currentPage: 1
        },
        refreshing: true,
        tmo_ref: undefined,
        cread: 0,
        sort: {
          cell: "id",
          order: "asc"
        },
        search: {
          query: "",
          cells_to_search: ["id", "pubk", "filename", "filehash"],
          searched: [],
          fuse: null
        }
      },
      append_file: {
        message: "",
        hash: "",
        name: "not set",
        size: 0,
        transaction_id: 1,
        progress: {
          show: false,
          value: 0
        },
        notification: {
          show: false,
          message: ""
        }
      }
    };
  },
  methods: {
    /**
     * Функция запроса списка зарегистрированных файлов
     */
    files_list_request_files(_force_update = false) {
      if (_force_update || this.files_list.pagination.currentPage === 1) {
        try {
          if (_force_update && this.files_list.tmo_ref !== undefined)
            clearTimeout(this.files_list.last_id.tmo_ref);
          this.files_list.tmo_ref = undefined;
          let start_id = 0;
          let count_id = parseInt(this.files_list_req_count);
          if (this.files_list.pagination.currentPage !== 1) {
            if (this.files_list.data.min < parseInt(this.files_list_req_count))
              count_id =
                parseInt(this.files_list_req_count) - this.files_list.data.min;
            start_id = this.files_list.data.max - this.files_list.data.min;
          } else {
            start_id = this.files_list.cread;
          }
          let reqd = {
            "start-transaction": start_id,
            "count-transaction": count_id,
            "code-contract": parseInt(this.files_list_contract_id),
            "code-transaction": parseInt(this.files_list_transaction_type)
          };
          axios.post(this.apiURL, this.apiBody(1, "get-transactions-on-id", reqd)).then(
            _response => {
              if (_response.data.error === undefined) {
                let res = _response.data.result;
                if (
                  res !== undefined &&
                  res.transactions !== undefined &&
                  res.transactions.length > 0
                ) {
                  let curr_arr = [];
                  let curr_min = this.files_list.data.min;
                  let curr_max = this.files_list.data.max;
                  if (
                    this.files_list.pagination.currentPage === 1 &&
                    this.files_list.data.data.length
                  )
                    this.files_list.cread = this.files_list.cread + count_id;
                  for (var i = 0; i < res.transactions.length; i++) {
                    let trns = res.transactions[i];
                    if (
                      parseInt(trns.description["contract-id"]) ===
                      parseInt(this.files_list_contract_id)
                    ) {
                      let trns_id = parseInt(trns.ID);
                      if (
                        this.files_list.data.min > trns_id ||
                        this.files_list.data.max < trns_id
                      ) {
                        if (curr_arr.length === 0 && curr_min === -1) {
                          curr_min = trns_id;
                          curr_max = trns_id;
                        }
                        if (curr_min > trns_id) curr_min = trns_id;
                        if (curr_max < trns_id) curr_max = trns_id;
                        let add_trns = {};
                        var cont;
                        add_trns.id = trns.ID;
                        add_trns.pubk = trns.description["public-key"];
                        cont = trns.description["message-contract"].split(":");
                        add_trns.filename = cont[0];
                        add_trns.filehash = cont[1];
                        curr_arr.push(add_trns);
                      } else {
                        this.files_list.cread = 0;
                      }
                    }
                    if (
                      parseInt(trns.description["transaction-id"]) >=
                      this.append_file.transaction_id
                    ) {
                      this.append_file.transaction_id =
                        parseInt(trns.description["transaction-id"]) + 1;
                    }
                  }
                  if (curr_arr.length) {
                    if (this.files_list.data.min > curr_min)
                      this.files_list.data.min = curr_min;
                    if (this.files_list.data.max < curr_max)
                      this.files_list.data.max = curr_max;
                    this.files_list.data.data = curr_arr.concat(
                      this.files_list.data.data
                    );
                  }
                } else if (
                  res === undefined ||
                  res.transactions === undefined
                ) {
                  this.$notify({
                    message: "<b>Incorrect response format</b>",
                    icon: "add_alert",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger"
                  });
                }
              } else {
                this.$notify({
                  message:
                    "[" +
                    _response.data.error.code +
                    "] <b>" +
                    _response.data.error.message +
                    "</b>",
                  icon: "add_alert",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger"
                });
              }
              if (this.files_list.refreshing) {
                if (this.files_list.cread) {
                  this.files_list_request_files();
                } else {
                  this.files_list.tmo_ref = setTimeout(() => {
                    this.files_list_request_files();
                  }, parseInt(this.refresh_list_period) * 1000);
                }
              }
            },
            _e_response => {
              this.$notify({
                message: "<b><pre>" + _e_response.stack + "</pre></b>",
                icon: "add_alert",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
              });
            }
          );
        } catch (e) {
          this.$notify({
            message: "<b><pre>" + e.stack + "</pre></b>",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "rose"
          });
        }
      }
    },

    /**
     * Функция переключения сортировки столбцов в таблице
     */
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.files_list.sort.cell;
        if (this.files_list.sort.order === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    /**
     * Добавление файла. Отправка транзакции через API
     */
    apiBody(id, method, params) {
      return {
        method: method,
        params: params,
        id: id,
        jsonrpc: "2.0",
        version: "1.0"
      };
    },

    /**
     * Добавление файла в BC отработка интерфейса
     */
    loadContractFile(infl) {
      let files = infl.target.files || infl.dataTransfer.files;
      if (!files.length) return;

      let reader = new FileReader();
      reader.onloadstart = ls_e => {
        this.append_file.progress.value = 0;
        if (ls_e.lengthComputable) this.append_file.progress.show = true;
      };
      reader.onprogress = lp_e => {
        if (lp_e.lengthComputable)
          this.append_file.progress.value = parseInt(
            (lp_e.loaded / lp_e.total) * 100,
            10
          );
      };
      reader.onerror = ler_e => {
        this.$notify({
          message:
            "File load failed: [" +
            ler_e.target.error.code +
            "] " +
            ler_e.target.error.message,
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger"
        });
      };
      reader.onload = e => {
        try {
          var _hash = MD5(e.target.result);
          this.append_file.name = files[0].name;
          this.append_file.size = files[0].size;
          this.append_file.hash = _hash;
          this.append_file.message =
            this.append_file.name + ":" + this.append_file.hash;
        } catch (e) {
          this.$notify({
            message: "Calculate hash failed: " + e.stack,
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger"
          });
        }
        this.append_file.progress.value = 100;
        this.append_file.progress.show = false;
      };
      try {
        reader.readAsBinaryString(files[0]);
      } catch (e) {
        this.$notify({
          message: "File load failed: " + e.stack,
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger"
        });
      }
    },

    /**
     * Обработчик кнопки отправки файла
     */
    sendFile() {
      if (this.wallet.wallet !== undefined && this.append_file.message.length) {
        Shampoox().then(api => {
          try {
            var error = new api.Error();
            var keyPairs = new api.Pair.fromPrivateKey(this.wallet.wallet.priv, error);
            axios.all([
              axios.post(
                this.apiURL,
                this.apiBody(2, "get-current-block-id", {})
              )
            ]) .then(axios.spread((res) => {
              let transaction = new api.Transaction();

              transaction.fileTransfer(
                keyPairs,
                String(res.data.result["current-block-id"]),
                String(this.append_file.transaction_id),
                String(this.files_list_contract_id),
                String(this.append_file.message),
                String(this.append_file.name),
                String(this.append_file.hash),
                String(this.append_file.size),
                0.0,
                error
              );

              axios.all([
                axios.post(
                  this.apiURL,
                  this.apiBody(3, "send-transaction", JSON.parse(transaction.body))
                )
              ]) .then(axios.spread((r) => {
                console.log(r);
                if(r.data.error === undefined) {
                  Swal.fire({
                    title: "Send file success",
                    type: "success",
                    timer: this.alert_timeout * 1000,
                    confirmButtonClass: "md-button md-success",
                    buttonsStyling: false
                  });
                  this.append_file.transaction_id = this.append_file.transaction_id + 1;
                } else {
                  Swal.fire({
                    title: "Send file failed",
                    html: "[" + r.data.error.code + "] " + r.data.error.message,
                    type: "error",
                    timer: this.alert_timeout * 1000,
                    confirmButtonClass: "md-button md-success",
                    buttonsStyling: false
                  });
                }
              })) .catch(e => {
                console.log(e.stack);
                Swal.fire({
                  title: "Send file failed",
                  // html: "<pre>" + e.stack + "</pre>",
                  type: "error",
                  timer: this.alert_timeout * 1000,
                  confirmButtonClass: "md-button md-success",
                  buttonsStyling: false
                });
              });
            })) .catch(e => {
              if(e !== undefined) console.log(e.stack);
              Swal.fire({
                title: "Send file failed",
                // html: "<pre>" + e.stack + "</pre>",
                type: "error",
                timer: this.alert_timeout * 1000,
                confirmButtonClass: "md-button md-success",
                buttonsStyling: false
              });
            });
          } catch (e) {
            if(e !== undefined) console.log(e.stack);
            Swal.fire({
              title: "File load failed",
              html: e !== undefined ? "<pre>" + e.stack + "</pre>" : "",
              type: "error",
              timer: this.alert_timeout * 1000,
              confirmButtonClass: "md-button md-success",
              buttonsStyling: false
            });
          }
        });
      } else {
        let error_messge = "";
        if (!this.wallet.wallet !== undefined) error_messge = "Wallet not select";
        else error_messge = "File not select";
        Swal.fire({
          title: error_messge,
          type: "warning",
          timer: this.alert_timeout * 1000,
          confirmButtonClass: "md-button md-success",
          buttonsStyling: false
        });
      }
    }
  },
  mounted() {
    this.files_list.search.fuse = new Fuse(this.files_list.data.data, {
      keys: this.files_list.search.cells_to_search,
      threshold: 0.3
    });

    /**
     * Запуск автоматического периодического чтения списка файлов
     */
    this.files_list.refreshing = true;
    this.files_list_request_files(true);
  },
  watch: {
    searchQuery(value) {
      let result = this.files_list.data.data;
      if (value !== "") {
        result = this.files_list.search.fuse.search(
          this.files_list.search.query
        );
        console.log("SEARCH RESULT", result);
      }
      this.files_list.search.searched = result;
    },
    files_list_current_page() {
      let pg_cnt = Math.ceil(this.files_list_total / this.files_list_per_page);
      if (
        (this.files_list.data.min > 1 &&
          this.files_list.pagination.currentPage >= pg_cnt - 1) ||
        this.files_list.pagination.currentPage === 1
      ) {
        this.files_list.refreshing = true;
        this.files_list_request_files(true);
      } else if (this.files_list.tmo_ref !== undefined) {
        this.files_list.refreshing = false;
        clearTimeout(this.files_list.tmo_ref);
        this.files_list.tmo_ref = undefined;
      }
    }
  },
  beforeDestroy() {
    this.files_list.refreshing = false;
    if (this.files_list.tmo_ref !== undefined) {
      clearTimeout(this.files_list.tmo_ref);
      this.files_list.tmo_ref = undefined;
    }
  }
};
</script>

<style lang="scss" scoped></style>
