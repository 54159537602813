<template>
  <md-card class="md-layout walletswork" :class="walletsworkClass">
    <md-card-header class="md-card-header-icon md-card-header-aurus">
      <div class="card-icon"> <md-icon>mail_outline</md-icon> </div>
        <h4 v-show="!loaded" class="title">{{ header }}</h4>
        <h4 v-show="loaded" class="title">{{ header_opened }}</h4>
    </md-card-header>
    <md-card-content>
      <div>
        <md-button v-if="!loaded" class="md-success" md-alignment="left" @click="CreateWallet">
          Create wallet
        </md-button>
        <md-button v-show="!loaded && !fromprivkey" class="md-success md-fileinput" md-alignment="left" @click="OpenFileButton">
          Open wallet
          <input type="file" accept=".ctr" @change="LoadWallet"/>
        </md-button>
        <div v-show="loaded">
          <p>{{ pub }}</p>
        </div>
      </div>
      <md-button v-if="loaded && container.length" class="md-success" md-alignment="left" @click="SaveContainer">
        Save container
      </md-button>
      <md-button v-if="loaded" class="md-fail" md-alignment="left" @click="CloseWallet">
        Close wallet
      </md-button>

      <md-card v-show="!loaded && fromprivkey">
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon"> <md-icon>mail_outline</md-icon> </div>
          <h4 class="title">Create wallet from Private Key</h4>
        </md-card-header>
        <md-card-content>
          <md-field>
            <label>Private Key</label>
            <md-input v-model="priv" type="text"></md-input>
          </md-field>
          <md-field>
            <label>PassPhrase</label>
            <md-input v-model="passphrase" type="text"></md-input>
          </md-field>
          <md-button class="md-success" md-alignment="left" @click="CreateWalletFromPrivate">
            Create Wallet
          </md-button>
          <md-button class="md-fail" md-alignment="left" @click="CloseWallet">
            Cancel
          </md-button>
        </md-card-content>
      </md-card>
    </md-card-content>
  </md-card>
</template>
<script>
import { setTimeout } from "timers";
import $ from "jquery";
import "@/pages/Dashboard/Wasm/shampoox.wasm"
import Shampoox from "@/pages/Dashboard/Wasm/shampoox";
import "@/pages/Dashboard/Wasm/Container.wasm";
import Container from "@/pages/Dashboard/Wasm/Container";
import Swal from "sweetalert2";

export default {
  name: "walletswork",
  props: {
    header_opened: {
      type: String,
      default: "Opened wallet"
    },
    header:{
      type: String,
      default: "Open/Create wallet"
    },
    alert_timeout: {
      type: Number,
      default: 5
    },
    value: {
      type: Object,
      default: function() {
        return undefined;
      }
    }
  },
  computed: {
    walletsworkClass() {
      return `walletswork-${this.type}`;
    },
  },
  data() {
    return {
      loaded: false,
      pub: "",
      priv: "",
      fromprivkey: false,
      passphrase: "",
      container: ""
    };
  },
  methods: {
    parseHexString(str) {
      var result = [];
      while (str.length >= 8) {
        result.push(parseInt(str.substring(0, 8), 16));
        str = str.substring(8, str.length);
      }
      return result;
    },
    createHexString(arr) {
      var result = "";
      var z;
      for (var i = 0; i < arr.length; i++) {
        var str = arr[i].toString(16);
        z = 8 - str.length + 1;
        str = Array(z).join("0") + str;
        result += str;
      }
      return result;
    },
    download(data, filename) {
      var file = new Blob([data], {
        type: 'application/octet-stream'
      });
      if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      else { // Others
        var a = document.createElement("a"),
        url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    },
    // ========================================================================================
    CreateWallet() {
      this.priv = "";
      this.passphrase = "";
      this.pub = "";
      this.container = "";
      this.loaded = false;
      this.fromprivkey = true;
    },
    SaveContainer() {
      if(!this.container.length)
        return;
      this.download(this.container, "container.ctr");
      Swal.fire({
        type: "success",
        html:"<br> Secret phrase: <br> <strong>" + this.passphrase + "</strong> <br> Wallet address:<br>"+ this.pub +" <br> Please keep in mind secret phrase and save file container.ctr in 2 different usb flash",
        confirmButtonClass: "md-button md-success",
        buttonsStyling: false
      });

    },
    CreateWalletFromPrivate() {
      var is_good = false;
      if(!this.priv.length || !this.passphrase.length) {
        Swal.fire({
          title: "Create Crypto Container failed",
          html: !this.priv.length ? "Private key field empty" : "Passphrase field empty",
          type: "error",
          timer: this.alert_timeout * 1000,
          confirmButtonClass: "md-button md-success",
          buttonsStyling: false
        });
        this.priv = "";
        this.passphrase = "";
        this.pub = "";
        this.container = "";
        return;
      }
      try {
        Shampoox().then(api => {
          var error = new api.Error();
          var keyPairs =  new api.Pair.fromPrivateKey(this.priv, error);
          try {
            Container().then((ctr) => {
              try {
                let errorContainer = new ctr.Error();
                let container = ctr.create_container(keyPairs.privateKey, keyPairs.publicKey, String(this.passphrase), errorContainer);
                this.pub = keyPairs.publicKey;
                if (container === undefined) {
                  Swal.fire({
                    type: "fail",
                    html: "Container not created",
                    confirmButtonClass: "md-button md-success",
                    timer: this.alert_timeout * 1000,
                    buttonsStyling: false
                  });
                } else {
                  this.container = this.createHexString(container);
                  this.download(this.container, "container.ctr");
                  Swal.fire({
                    type: "success",
                    html:"<br> Secret phrase: <br> <strong>" + this.passphrase + "</strong> <br> Wallet address:<br>"+ keyPairs.publicKey +" <br> Please keep in mind secret phrase and save file container.ctr in 2 different usb flash",
                    confirmButtonClass: "md-button md-success",
                    buttonsStyling: false
                  });
                  is_good = true;
                  this.loaded = true;
                }
              } catch (e) {
                if(e !== undefined)
                  console.log(e.stack);
                Swal.fire({
                  title: "Conteiner create failed",
                  html: e !== undefined ? "<pre>" + e.stack + "</pre>" : "",
                  type: "error",
                  timer: this.alert_timeout * 1000,
                  confirmButtonClass: "md-button md-success",
                  buttonsStyling: false
                });
              }
            });
          } catch (e) {
            if(e !== undefined)
              console.log(e.stack);
            Swal.fire({
              title: "Conteiner create failed",
              html: e !== undefined ? "<pre>" + e.stack + "</pre>" : "",
              type: "error",
              timer: this.alert_timeout * 1000,
              confirmButtonClass: "md-button md-success",
              buttonsStyling: false
            });
          }
        });
      } catch (e) {
        if(e !== undefined)
          console.log(e.stack);
        Swal.fire({
          title: "Conteiner create failed",
          html: e !== undefined ? "<pre>" + e.stack + "</pre>" : "",
          type: "error",
          timer: this.alert_timeout * 1000,
          confirmButtonClass: "md-button md-success",
          buttonsStyling: false
        });
      }
      if(!is_good) {
        this.priv = "";
        this.passphrase = "";
        this.pub = "";
        this.container = "";
      }
      this.fromprivkey = false;
    },
    OpenFileButton() {
      this.fromprivkey = false;
    },
    LoadWallet(infl) {
      let files = infl.target.files || infl.dataTransfer.files;
      if (!files.length)
        return;
      let reader = new FileReader();
      reader.onerror = ler_e => {
        Swal.fire({
          title: "Conteiner load failed",
          html: "<pre>" + ler_e.target.error.message + "</pre>",
          type: "error",
          timer: this.alert_timeout * 1000,
          confirmButtonClass: "md-button md-success",
          buttonsStyling: false
        });
      };
      reader.onload = e => {
        try {
          var arrayBufferNew = null;
          arrayBufferNew  = e.target.result;
          var uint8array = this.parseHexString(arrayBufferNew);
          Swal.fire({
            title: "Input passphrase",
            html: `<div class="md-field md-theme-default"><input type="password" id="md-input" class="md-input"></div>`,
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            buttonsStyling: false
          }).then(() => {
            var secretphrase = $("#md-input").val();
            Container().then((ctr) => {
              let errorContainer = new ctr.Error();
              let keys = ctr.open_container(uint8array, secretphrase, errorContainer);
              if (keys === undefined) {
                Swal.fire({
                  type: "fail",
                  html: "Container not load",
                  confirmButtonClass: "md-button md-success",
                  buttonsStyling: false
                });
                this.priv = "";
                this.passphrase = "";
                this.pub = "";
                this.container = "";
              } else {
                Swal.fire({
                  type: "success",
                  html:"<br> Secret phrase: <br> <strong>"+ secretphrase +"</strong> <br> Wallet address:<br>"+keys.public_key,
                  confirmButtonClass: "md-button md-success",
                  buttonsStyling: false
                });
                this.passphrase = secretphrase;
                this.container = arrayBufferNew;
                this.priv = keys.private_key;
                this.pub = keys.public_key;
                this.loaded = true;
              }
            });
          });
        } catch (e) {
          if(e !== undefined)
            console.log(e.stack);
          Swal.fire({
            title: "Conteiner load failed",
            html: e !== undefined ? "<pre>" + e.stack + "</pre>" : "",
            type: "error",
            timer: this.alert_timeout * 1000,
            confirmButtonClass: "md-button md-success",
            buttonsStyling: false
          });
        }
      };
      try {
        reader.readAsBinaryString(files[0]);
      } catch (e) {
        if(e !== undefined)
          console.log(e.stack);
        Swal.fire({
          title: "Conteiner create failed",
          html: e !== undefined ? "<pre>" + e.stack + "</pre>" : "",
          type: "error",
          timer: this.alert_timeout * 1000,
          confirmButtonClass: "md-button md-success",
          buttonsStyling: false
        });
      }
    },
    CloseWallet() {
      this.priv = "";
      this.passphrase = "";
      this.pub = "";
      this.container = "";
      this.fromprivkey = false;
      this.loaded = false;
    },

    dataOut() {
      if(this.loaded === false)
        return undefined;
      return {pub: this.pub, priv: this.priv};
    }
  },
  watch: {
    pub() {
      this.$emit("input", this.dataOut());
    },
    priv() {
      this.$emit("input", this.dataOut());
    },
    loaded() {
      this.$emit("input", this.dataOut());
    }
  }
};
</script>
<style lang="scss">
</style>
