var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout "},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"progress-bar"}),_vm._m(0),_c('div',{staticClass:"md-layout-item md-size-75 md-large-size-75 md-medium-size-100 md-medium-small-100"},[_c('md-card',{staticClass:"wallet-widget-height "},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-aurus"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("account_balance_wallet")])],1),_c('h4',{staticClass:"title"},[_vm._v("Exchange wallets (upd. in "+_vm._s(_vm.countdown)+"s)")])]),_c('md-card-content',[_c('md-table',{staticClass:"table-striped table-hover text-accent",attrs:{"table-header-color":"aurus"},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"Balance"}},[(item.balance)?_c('span',[_vm._v(_vm._s(item.balance)+" "+_vm._s(item.in_token))]):_c('span',[_vm._v("0 "+_vm._s(item.in_token))])]),_c('md-table-cell',{attrs:{"md-label":"Address"}},[_vm._v(_vm._s(item.wallet))]),_c('md-table-cell',{attrs:{"md-label":"Providing token"}},[_vm._v(_vm._s(item.out_token))])],1)}}]),model:{value:(_vm.xchangewallets),callback:function ($$v) {_vm.xchangewallets=$$v},expression:"xchangewallets"}})],1)],1),_c('md-card',{staticClass:"wallet-widget-height "},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-aurus"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("account_balance_wallet")])],1),_c('h4',{staticClass:"title"},[_vm._v("Withdraw wallets")])]),_c('md-card-content',[_c('md-table',{staticClass:"table-striped table-hover text-accent",attrs:{"table-header-color":"aurus"},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"Balance"}},[(item.balance)?_c('span',[_vm._v(_vm._s(item.balance)+" "+_vm._s(item.in_token))]):_c('span',[_vm._v("0 "+_vm._s(item.in_token))])]),_c('md-table-cell',{attrs:{"md-label":"Address"}},[_vm._v(_vm._s(item.wallet))])],1)}}]),model:{value:(_vm.withdrawWallets),callback:function ($$v) {_vm.withdrawWallets=$$v},expression:"withdrawWallets"}})],1)],1),_c('md-card',{staticClass:"wallet-widget-height "},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-aurus"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("account_balance_wallet")])],1),_c('h4',{staticClass:"title"},[_vm._v("Exchange (outbound) wallets")])]),_c('md-card-content',[_c('md-table',{staticClass:"table-striped table-hover text-accent",attrs:{"table-header-color":"aurus"},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"Balance"}},[(item.balance)?_c('span',[_vm._v(_vm._s(item.balance)+" "+_vm._s(item.in_token))]):_c('span',[_vm._v("0 "+_vm._s(item.in_token))])]),_c('md-table-cell',{attrs:{"md-label":"Address"}},[_vm._v(_vm._s(item.wallet))])],1)}}]),model:{value:(_vm.outboundWallets),callback:function ($$v) {_vm.outboundWallets=$$v},expression:"outboundWallets"}})],1)],1),_c('md-card',{staticClass:"wallet-widget-height "},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-aurus"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("account_balance_wallet")])],1),_c('h4',{staticClass:"title"},[_vm._v("liteSWAP manual (inbound) ")])]),_c('md-card-content',[_c('h4',{staticClass:"title"},[_vm._v("Address: "+_vm._s(_vm.manualInOutWallets[0].wallet)+" ")]),_c('md-table',{staticClass:"table-striped table-hover text-accent",attrs:{"table-header-color":"aurus"},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"Asset"}},[_vm._v(_vm._s(item.token))]),_c('md-table-cell',{attrs:{"md-label":"Balance"}},[(_vm.manualInOutWallets[0].balance.length>0)?_c('span',[_vm._v(_vm._s(item.amount))]):_c('span',[_vm._v("0")])])],1)}}]),model:{value:(_vm.manualInOutWallets[0].balance),callback:function ($$v) {_vm.$set(_vm.manualInOutWallets[0], "balance", $$v)},expression:"manualInOutWallets[0].balance"}})],1)],1),_c('md-card',{staticClass:"wallet-widget-height "},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-aurus"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("account_balance_wallet")])],1),_c('h4',{staticClass:"title"},[_vm._v("liteSWAP manual (outbound) ")])]),_c('md-card-content',[_c('h4',{staticClass:"title"},[_vm._v("Address: "+_vm._s(_vm.manualInOutWallets[1].wallet)+" ")]),_c('md-table',{staticClass:"table-striped table-hover text-accent",attrs:{"table-header-color":"aurus"},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"Asset"}},[_vm._v(_vm._s(item.token))]),_c('md-table-cell',{attrs:{"md-label":"Balance"}},[(_vm.manualInOutWallets[1].balance.length>0)?_c('span',[_vm._v(_vm._s(item.amount))]):_c('span',[_vm._v("0")])])],1)}}]),model:{value:(_vm.manualInOutWallets[1].balance),callback:function ($$v) {_vm.$set(_vm.manualInOutWallets[1], "balance", $$v)},expression:"manualInOutWallets[1].balance"}})],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout-item md-size-100"},[_c('img',{staticStyle:{"height":"50px","width":"auto","margin-bottom":"40px"},attrs:{"src":"/img/aurus-full.png"}})])}]

export { render, staticRenderFns }