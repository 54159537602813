<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card md-theme="aurus">
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Present wallets</h4>
        </md-card-header>
        <md-card-content>
        <div id="paginated-toolbar" class="flex">
            <form v-on:submit.prevent="walletFilterSubmit">
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  v-model="wallet_filter"
                  data-vv-name="wallet_filter"
                  placeholder="Search by wallet"
                >
                </md-input>
              </md-field>
            </form>
            <md-button 
                @click="updateTableFiltered"
                class="md-primary md-sm"
            >Fetch</md-button>
            <md-button v-if="table_mode != 'default'" v-on:click="clearAndUpdateTable"
                class="md-icon-button accent_red" id="clear_btn"
              ><md-icon class="accent_red">highlight_off</md-icon>
            </md-button>
          </div>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="sortHandler"
            class="table-striped table-hover"
          >
            <md-table-toolbar id="paginated-toolbar">
              
              <md-field >
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="ID">{{
                item.ID
              }}</md-table-cell>
              <md-table-cell md-label="Block ID">{{
                item.BlockID
              }}</md-table-cell>
              <md-table-cell md-label="Wallet" >
                {{item.Wallet}}
              </md-table-cell>
              <md-table-cell md-label="Transactions from wallet" >
                {{item.From}}
              </md-table-cell>
              <md-table-cell md-label="Transactions to wallet" >
                {{item.To}}
              </md-table-cell>
              <md-table-cell md-label="Amount" >
                <md-button class="md-just-icon" @click="getWalletState">
                    <md-icon v-bind:id="item.Wallet">search</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @input="pageChangeFunc"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { Pagination } from "@/components";
//import users from "./users";
//import temp_response from "./temp_resp.js";
import Fuse from "fuse.js";
import axios from "axios";
import globalConfig from "@/globalConfig.js";
import Swal from 'sweetalert2';
export default {
  components: {
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    /* queriedData() {
      console.log("ADSASD");
    }, */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.wallet_count;
    }
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [15, 30, 50],
        total: 0
      },
      table_mode: "default", // [default, from_wallet, ]
      searchQuery: "",
      wallet_filter: "",
      id_filter: "",
      tableData: [],
      wallet_count: -1,
      queriedData: [],
      searchedData: [],
      fuseSearch: null,
      assets: [],
    };
  },
  methods: {
    parseWalletOutput(jsonResult) {
      let wallet_data = []
      let wallets = jsonResult.data.result["wallets"];
      wallets.forEach(function (value) {
            console.log(value);
            wallet_data.push({
                "ID":         value["wallet-id"],
                "BlockID":      value["block-id"],
                "Wallet":        value["wallet"],
                "From":        value["transaction-from"],
                "To":        value["transaction-to"],
            });
        });
      return wallet_data;
    },

    pageChangeFunc(pageNum) {
      console.log("Page changed "+pageNum);
      switch(this.table_mode) {
        case "from_wallet": {
          break;
        }
        default: {
          this.updateTableData(pageNum);
        }
      };
    },

    // fetch and draw table with all wallet
    updateTableData(pageNum=1) {
      let start_offset = this.pagination.perPage*(pageNum-1);
      axios.all([
        axios.post(globalConfig.BackendServer,
        {
            "jsonrpc":  "2.0",
            "method":   "get-wallets",
            "params":   {
              "start-wallet": 0,
              "count-wallet":1
            },
            "id":       3,
            "version":  "1.0"
        }),
        axios.post(globalConfig.BackendServer,
        {
            "method":"get-wallets",
            "params":   {
              "start-wallet": this.wallet_count - (start_offset-1),
              "count-wallet": this.pagination.perPage
            }, 
            "id": 1, 
            "jsonrpc": "2.0", 
            "version": "1.0"
        })
      ]).then(axios.spread((resp, walletHistory) => {
        let wallet_data=[];
        wallet_data = this.parseWalletOutput(walletHistory)
        
        if (this.searchedData.length > 0) {
          this.queriedData = this.searchedData;
        }
        if (this.table_mode === "by_wallet"){
          const newWalletData = []
          wallet_data.forEach((item)=>{
            if(item.Wallet === this.wallet_filter) {
              newWalletData.push(item)
            }
          })
          wallet_data = newWalletData
        }
        this.tableData = wallet_data;
        this.queriedData = wallet_data;
      }));
        
    },

    /*FILTER */
    sortHandler: function(value) {
      console.log("Sorting by "+this.currentSort+ ", order: "+this.currentSortOrder);
      this.updateTableData();
    },

    updateTableFiltered() {
      this.currentSort = "default";
      if (this.wallet_filter!="") {
        this.table_mode = "by_wallet";
      }
      this.updateTableData();
    },
    clearAndUpdateTable: function() {
      this.table_mode = "default";
      this.currentSort = "default";
      this.wallet_filter = "";
      this.wallet_filter_to = "";
      this.transaction_type_filter = "";
      this.id_filter="";
      //this.lock_auto_update = false;
      //this.blocks_count = this.blocks_count_default;
      this.pagination.currentPage = 1;
      this.pagination.prevPage = null;
    },

    /*GET WALLET STATE */
    async getAssetCode(){
        let params = {
          "jsonrpc":  "2.0",
          "method":   "get-blockchain-info",
          "params":   {
            },  
          "id":       3,
          "version":  "1.0"
        }
        let response = await fetch(this.node_url, {method: 'POST',body: JSON.stringify(params)});
        if (response.ok) {
          let assets=[];
          let result =  await response.json()
          result = result.result
          if(typeof result.error === 'undefined'){ 
            result["supported-assets"].forEach(function (value) { 
              assets.push({
                "code":  value["code"],
                "name":    value["name"]
              })
            })
            return assets
          } else {
            return null
          }
        } else {
          return null
        }
    },

    /* GET ASSETS CODES */
    getAssets() {
      const params = {
          "jsonrpc":  "2.0",
          "method":   "get-blockchain-info",
          "params":   {
            },  
          "id":       3,
          "version":  "1.0"
        }

      fetch(globalConfig.BackendServer, {
        method: 'POST',
        body: JSON.stringify(params)})
        .then((res) => {
            if (res.ok) {
              return res.json()
            }
          })
          .then((data)=>{
            if (typeof data.result.error === 'undefined') {
              data.result["supported-assets"].forEach((value) => {
                this.assets.push({
                  "code":  value["code"],
                  "name":    value["name"]
                })
              })
            }
          }).catch((err)=>{
            console.log(`Error get assets ${err}`)
          })
    },

    getWalletState(evt) {
      const walletId=evt.target.id
      const balance=[]
      const self = this

/*GET WALLET DATA */
      axios.all([axios.post(globalConfig.BackendServer,
      {
          "jsonrpc":  "2.0",
          "method":   "get-wallet-state",
          "params":   {
              "public-key": walletId
            }, 
          "id":       3,
          "version":  "1.0"
      })
      ]).then(axios.spread((resp) => {
        if(('exist' in resp.data.result && resp.data.result["exist"]==="0") || resp.data.result.balance.length == 0){
          balance.push({"code": 0,"amount": 0});
        } else {
          if ('balance' in resp.data.result && resp.data.result.balance.length !=0) {
            resp.data.result.balance.forEach(function (value) {
              let code = self.assets.find(item => String(item.code) ==  String(value["code"]));
              balance.push({
                "code":        code["name"],
                "amount":    value["amount"]
              })
            });
          }
        }
        let html = ''
        if (balance[0].code===0) {
          html="Wallet is empty"
        } else {
          balance.forEach((item)=>{
          html += `<p>${item.code}: ${item.amount}<p>`
          })
        }
        Swal.fire({
          html: html,
          confirmButtonClass: "md-button md-success",
          buttonsStyling: false
        });
        console.log(balance)
      })
      )
    },

  },
  mounted() {
    console.log("Init table");
    this.getAssets();
    this.updateTableData();
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["name", "email"],
      threshold: 0.3
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    wallet_filter(value) {
      if(value=="") {
        this.updateTableData();
      }
    },
    searchQueryWallet(value) {
      if (value.length < 5 )
        return;
      console.log(value);
    },
    searchQuery(value) {
      console.log("search query");
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
