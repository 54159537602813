import DropDown from "./components/Dropdown.vue";
import Map from "./components/Map.vue";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("drop-down", DropDown);
    Vue.component("nodes-map", Map);
  }
};

export default GlobalComponents;
