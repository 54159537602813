<template>
<div class="content">
    <div class="md-layout">  
        <div class="md-layout-item md-size-100">
          <md-card>
            <md-card-header>
              <h4 class="title">Present Assets</h4>
            </md-card-header>
            <md-card-content>
              <md-table v-model="assets" table-header-color="aurus" class="table-striped table-hover">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Code">{{ item.Code }}</md-table-cell>
                  <md-table-cell md-label="Name">{{ item.Name.toUpperCase() }}</md-table-cell>
                  <md-table-cell md-label="Precision">{{ item.Precision }}</md-table-cell>
                </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
        </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
import globalConfig from "@/globalConfig.js";

export default {
 components: {
  },
  name: "asset-list",
  props: {
      // milliseconds to update map nodes
      update_interval: {
          type: Number,
          default: 60000
      }
  },
  methods: {
      updateAssetList: function () {
        axios.all([
            axios.post(globalConfig.BackendServer,
            {
                "jsonrpc":  "2.0",
                "method":   "get-blockchain-state",
                "params":   [],
                "id":       3,
                "version":  "1.0"
            })
        ]).then(axios.spread((resp) => {
            let statistic=[];
            console.log(resp);
            resp.data.result["supported-assets"].forEach(function (value) {
                statistic.push({
                        "Code":     value["code"],
                        "Name":     value["name"],
                        "Precision":value["precision"]                        
                    });
                });
            this.assets = statistic;
        }));
    }
  },
    data() {
        return {
            assets: []
        };
    },

    mounted() {
        this.updateAssetList();
        this.timer = setInterval(this.updateAssetList, Number(this.update_interval));
    },//mounted
    watch: {
  },
    beforeDestroy() {
        clearInterval(this.timer)
    }
};
</script>
<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}
</style>
