<template>
  <div class="md-layout ">
    <loading  :active.sync="isLoading" 
            :can-cancel="false" 
            :is-full-page="true"
    />
    <div class="progress-bar"></div>
    <div class="md-layout-item md-size-100"> 
      <img src="/img/aurus-full.png" style="height: 50px;width:auto; margin-bottom: 40px;"/>
    </div>
    <div class="md-layout-item md-size-75 md-large-size-75 md-medium-size-100 md-medium-small-100"> 
      <md-card class="wallet-widget-height " >
        <md-card-header class="md-card-header-icon md-card-header-aurus">
            <div class="card-icon"> <md-icon>account_balance_wallet</md-icon></div>
              <h4 class="title">Exchange wallets (upd. in {{countdown}}s)</h4>
        </md-card-header>
        <md-card-content>
           <md-table v-model="xchangewallets" table-header-color="aurus" class="table-striped table-hover text-accent" >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Balance">
                <span v-if="item.balance">{{ item.balance }} {{ item.in_token }}</span>
                <span v-else>0 {{ item.in_token }}</span>
              </md-table-cell>
              <md-table-cell md-label="Address">{{ item.wallet }}</md-table-cell>
              <md-table-cell md-label="Providing token">{{ item.out_token }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
      <md-card class="wallet-widget-height " >
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon"> <md-icon>account_balance_wallet</md-icon></div>
          <h4 class="title">Withdraw wallets</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="withdrawWallets" table-header-color="aurus" class="table-striped table-hover text-accent" >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Balance">
                <span v-if="item.balance">{{ item.balance }} {{ item.in_token }}</span>
                <span v-else>0 {{ item.in_token }}</span>
              </md-table-cell>
              <md-table-cell md-label="Address">{{ item.wallet }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
      <md-card class="wallet-widget-height " >
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon"> <md-icon>account_balance_wallet</md-icon></div>
          <h4 class="title">Exchange (outbound) wallets</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="outboundWallets" table-header-color="aurus" class="table-striped table-hover text-accent" >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Balance">
                <span v-if="item.balance">{{ item.balance }} {{ item.in_token }}</span>
                <span v-else>0 {{ item.in_token }}</span>
              </md-table-cell>
              <md-table-cell md-label="Address">{{ item.wallet }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
      <md-card class="wallet-widget-height " >
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon"> <md-icon>account_balance_wallet</md-icon></div>
          <h4 class="title">liteSWAP manual (inbound) </h4>
        </md-card-header>
        <md-card-content>
          <h4 class="title">Address: {{manualInOutWallets[0].wallet}} </h4>
          <md-table v-model="manualInOutWallets[0].balance" table-header-color="aurus" class="table-striped table-hover text-accent" >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Asset">{{ item.token }}</md-table-cell>
              <md-table-cell md-label="Balance">
                <span v-if="manualInOutWallets[0].balance.length>0">{{ item.amount }}</span>
                <span v-else>0</span>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
      <md-card class="wallet-widget-height " >
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon"> <md-icon>account_balance_wallet</md-icon></div>
          <h4 class="title">liteSWAP manual (outbound) </h4>
        </md-card-header>
        <md-card-content>
          <h4 class="title">Address: {{manualInOutWallets[1].wallet}} </h4>
          <md-table v-model="manualInOutWallets[1].balance" table-header-color="aurus" class="table-striped table-hover text-accent" >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Asset">{{ item.token }}</md-table-cell>
              <md-table-cell md-label="Balance">
                <span v-if="manualInOutWallets[1].balance.length>0">{{ item.amount }}</span>
                <span v-else>0</span>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>

import { setTimeout, clearTimeout, clearInterval, setInterval } from "timers";
import Swal from "sweetalert2";
import axios from "axios";
import globalConfig from "@/globalConfig.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "user-wallet",
  components: {
  Loading
  },
  props: {

    node_url: {
      type: String,
      default: globalConfig.BackendServer
    }
  },
  computed: {
  },
  data() {
    //host, wallet, asset, amount, description
    return {
      isLoading:  false,
      update_interval: 20000,
      countdown: 0,
      timer: null,
      timerProgressBar: null,
      xchangewallets: [
        {
          in_token: 'AUS',
          out_token: 'USD',
          wallet: '2UDCtCUTLgLpJd9q7zrLoPfhBh94dtRXvNEmSjSCgVhcA5ZXiv',
          balance: null
        },
        {
          in_token: 'USD',
          out_token: 'AUS',
          wallet: '2QA62YVKvNuXG61tY2zTd7AgeMZpaBX5zawgR484V2HqypCo4Y',
          balance: null
        },
        {
          in_token: 'AUS',
          out_token: 'EUR',
          wallet: '2nQHeNfJ6PtvfcumFMVdjMJwfgyDkBvxYa443nmNJ3BKA3fYEz',
          balance: null
        },
        {
          in_token: 'EUR',
          out_token: 'AUS',
          wallet: 'JooDfC3GynVtUPjg9FmkHwQkSJnTh3i6uxLJQs8YhT5Fxh18Z',
          balance: null
        },
        {
          in_token: 'EUR',
          out_token: 'UST',
          wallet: 'mqwds5dVc8VwMrRMKpvCsbPzRxa6Kz8mJPkX2L3LfVprToKdZ',
          balance: null
        },
        {
          in_token: 'EUR',
          out_token: 'USD',
          wallet: '2iVHksKa9QjhK6m3dtW755k9RHfi16Em1NAzbeSpARgjyNrTEo',
          balance: null
        },
        {
          in_token: 'UST',
          out_token: 'USD',
          wallet: '2tPnbuNWPfq24tuuB8diHgLj74NKdobfcLSW198oo8JN3x5CKe',
          balance: null
        },
        {
          in_token: 'UST',
          out_token: 'AUS',
          wallet: '2hGLr2UMLPCcEneqKNC5kVHM7ygvFzdKnQCf91StNPNv3Uq5XY',
          balance: null
        },
        {
          in_token: 'UST',
          out_token: 'EUR',
          wallet: '2fkRoaBZgwdH5HYBRTCqJgjyC6Lzjrj4Jsv1nWhypiHd8ZCJRf',
          balance: null
        },
        {
          in_token: 'USD',
          out_token: 'UST',
          wallet: '2m7mE8Q8N4ehYfSK24RWve86fE7tN2GS4kj227mqNYNNkez3gt',
          balance: null
        },
        {
          in_token: 'USD',
          out_token: 'EUR',
          wallet: '56u9bRaasngGZsrUrmqyFeRUBRnuhkjuAbrmqXAVJbSjVBdhQ',
          balance: null
        },
        {
          in_token: 'AUS',
          out_token: 'UST',
          wallet: 'ZesNcqeM9J6jrftUpvnDvPjFXR49UgZenJm3KPLPpSPzE6zzE',
          balance: null
        },
      ],
      withdrawWallets: [
        {
          in_token: 'AUS',
          wallet: '21rgncwGrFWZeuKPpavFz7oaAAAF84TJzXeagmiBgsFzPePn5i',
          balance: null
        },
        {
          in_token: 'EUR',
          wallet: 'WEyDL7Ggg3fpU8bukDWhPFVBDoVwZfGNSuXV4QqJBtpn189WE',
          balance: null
        },
        {
          in_token: 'USD',
          wallet: '2JhhZFX3L1TZoX4zRc4sTAP5FWvbk34khShEyWKxVhTBZNwXq7',
          balance: null
        },
        {
          in_token: 'UST',
          wallet: 'dtocsG3eM4xJqreRJTovDU99oozsoYCkTb98wdRGC4gPNDu1k',
          balance: null
        },
      ],
      outboundWallets: [
        {
          in_token: 'AUS',
          wallet: '2SBYq94jWUwoxNLc8usxM4GYN3MPsbLcsfvznDyoomqqDbWGyp',
          balance: null
        },
        {
          in_token: 'EUR',
          wallet: '2cfNEwbNa7c4Vkw4X1zNL3K3XCgZZxV3e4cRFpk7nP98QRyNm3',
          balance: null
        },
        {
          in_token: 'USD',
          wallet: '25FHqrXeXHagnrVm9CNGc6DJSWc96LeairBeCnyaHZfckjif1o',
          balance: null
        },
        {
          in_token: 'UST',
          wallet: '2M75um4BLWMEkM29MRQQU6YBAbk9UqnTrK7khGSek697NyCkaE',
          balance: null
        },
      ],
      manualInOutWallets: [
        {
          in_token: 'IN',
          wallet: 'QmToPwF2Zng7t2YAJgAHGQ4XoWbfjSPmr2Ar7BR5VHRH49Q75',
          balance: [] //could be multiple assets
        },
        {
          in_token: 'OUT',
          wallet: 'DSMmLp8X41haNRM3apDdPrgapAaEwC7EC6mHFLrE4wBBBaKgb',
          balance: [] //could be multiple assets
        },
      ]
    }
      
  },

  methods: {
    updateSeconds() {
      this.countdown = (this.countdown - 1)%this.update_interval
      if (this.countdown<0)
        this.countdown= 19
    },
    async getLastBlockID(){
      let response;
      let result;
      let params = {
                "jsonrpc":  "2.0",
                "method":   "get-current-block-id",
                "params":   {
                  }, 
                "id":       3,
                "version":  "1.0"
            };
        response = await fetch(this.node_url, {method: 'POST',body: JSON.stringify(params)});
        if (response.ok) {
          result = await response.json()
          result = result.result
          return result['current-block-id']
        } else {
          return null
        }
    },
    async getAssetCode(){
        let params = {
          "jsonrpc":  "2.0",
          "method":   "get-blockchain-info",
          "params":   {
            },  
          "id":       3,
          "version":  "1.0"
        }
        let response = await fetch(this.node_url, {method: "POST",body: JSON.stringify(params)});
        if (response.ok) {
          let assets=[];
          let result =  await response.json()
          result = result.result
          if(typeof result.error === "undefined"){ 
            result["supported-assets"].forEach(function (value) { 
              assets.push({
                "code":  value["code"].toUpperCase(),
                "name":    value["name"].toUpperCase()
              })
            })
            return assets
          } else {
            return null
          }
        } else {
          return null
        }
    },
    updateServiceWalletStatistics: async function () {
      //this.isLoading = true;
      let assets = await this.getAssetCode()
      //cleanup 
      this.manualInOutWallets[0].balance.forEach((item, index, object) =>{
        if (!item.updated)
          object.splice(index,1)
      })
      this.manualInOutWallets[1].balance.forEach((item, index, object) =>{
        if (!item.updated)
          object.splice(index,1)
      })
      this.xchangewallets.assetCodes = assets
      this.xchangewallets
      .concat(this.withdrawWallets)
      .concat(this.outboundWallets)
      .concat(this.manualInOutWallets)
      .forEach(wallet => {
        axios.all(
        [axios.post(this.node_url,
          {
              "jsonrpc":  "2.0",
              "method":   "get-wallet-state",
              "params":   {
                  "public-key": wallet.wallet
                }, 
              "id":       3,
              "version":  "1.0"
          })
        ]).then(axios.spread((resp) => {

          if('exist' in resp.data.result && resp.data.result["exist"]==="0"){
            wallet.balance = 0;
          }
          else{
            if ('balance' in resp.data.result && resp.data.result.balance) {
              if (wallet.in_token=="IN"||wallet.in_token=="OUT"){
                wallet.balance.forEach(item=> {
                  item.updated = false
                })
              }
              resp.data.result.balance.forEach(function (value) {  
                let code = assets.find(item => String(item.code) ==  String(value["code"]));  
                if (wallet.in_token=="IN"||wallet.in_token=="OUT"){
                  let entry = wallet.balance.find( row => {
                    return row.token == code.name.toUpperCase()
                  })
                  if (entry) {
                    entry.amount = value.amount
                    entry.updated = true
                  } else {
                    wallet.balance.push(
                      {
                        token: code.name.toUpperCase(),
                        amount: value.amount,
                        updated: true
                      }
                    )
                  }
                } else if(wallet.in_token == code.name.toUpperCase()) {
                  wallet.balance = value.amount
                }
              })
            }
          }
          if (!resp.data.result.balance) {
            wallet.balance = null
          }
        }));
      })
      
    },

    setProgressBar() {
      /*GET BAR  */
      const progressBar = document.querySelector(".progress-bar")
      let width = 0
      let time = Number(this.update_interval) / 1000
/*SET INTERVAL AND REDUCE WIDTH ELM */
      this.timerProgressBar = setInterval(() => {
        if(width > 99.9) {
          clearInterval(this.timerProgressBar)
          width = 100
          progressBar.style.width=`${width}%`
        } else {
          width += 0.1
          progressBar.style.width=`${width}%`
        }
      }, time)
    }
},
  
  mounted() {
    let self = this

    this.updateServiceWalletStatistics()
    .then(()=>{
/*START PROGRESS BAR TICK */
      this.setProgressBar()
/*START TIMER */
      this.timer = setInterval(() => {
        self.updateServiceWalletStatistics()
        .then(() => {
          clearInterval(self.timerProgressBar)
          self.setProgressBar()
        })
        .catch((err)=>{
          console.log(`Error: ${err}`)
        })
      }, Number(this.update_interval));
    })
    .catch((err)=>{
      console.log(`Error: ${err}`)
    })
    self.timer_seconds = setInterval(self.updateSeconds, 1000)
  },
  watch: {
    
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timerBar)
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: white !important;
}
.md-field {
  width: 60%;
}

.wallet-widget-height {
  min-height:200px;
}
.button-fixed-width {
  width: 105px;
}
.button-fixed-width-wide {
  width: 170px;
}
.button-text-left {
  
}
</style>
