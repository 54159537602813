import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
//import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Blocks from "@/pages/Dashboard/Explorer/Blocks.vue";
import Block from "@/pages/Dashboard/Explorer/Block.vue";
import ExplorerStatistic from "@/pages/Dashboard/Explorer/ExplorerStatistic.vue"
import Transactions from "@/pages/Dashboard/Explorer/Transactions.vue";
import Transaction from "@/pages/Dashboard/Explorer/Transaction.vue";
import NodesMap from "@/pages/Dashboard/Pages/NodesMap.vue";
import NodesStatistics from "@/pages/Dashboard/Pages/NodesStatistics.vue";
import Asset from "@/pages/Dashboard/Pages/Asset.vue";
import Wallets from "@/pages/Dashboard/Pages/Wallets.vue";
import FileTransfer	from "@/pages/Dashboard/Pages/FileTransfer.vue"
import FileRunner	from "@/pages/Dashboard/Pages/FileRunner.vue"
import CertificateAuthority	from "@/pages/Dashboard/Pages/CertificateAuthority.vue"
import UserWalletManager	from "@/pages/Dashboard/Pages/UserWalletManager.vue"
import NotFoundPage from "@/pages/Dashboard/Pages/NotFoundPage.vue"
import TokenRates from "@/pages/Dashboard/Pages/TokenRates.vue"
import ServiceWallets from "@/pages/Dashboard/Pages/ServiceWallets.vue"
import TokenXChange from "@/pages/Dashboard/Pages/TokenXChange.vue"
/*
import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";
import Lock from "@/pages/Dashboard/Pages/Lock.vue";

// Components pages
import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
import Panels from "@/pages/Dashboard/Components/Panels.vue";
import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Forms pages
import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";

// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Maps pages
*/
import WorldMap from "@/pages/Dashboard/Maps/WorldMap.vue";
/*
// Calendar
import Calendar from "@/pages/Dashboard/Calendar.vue";
// Charts
import Charts from "@/pages/Dashboard/Charts.vue";
import Widgets from "@/pages/Dashboard/Widgets.vue";

*/

let explorerMenu = {
  path: "/explorer",
  component: DashboardLayout,
  name: "Explorer",
  redirect: "/explorer/blocks",

  children: [
    {
      path: "blocks",
      name: "Blocks",
      components: { default: Blocks }
    },
    {
      path: "transactions",
      name: "Transactions",
      components: { default: Transactions }
    },
    {
      path: "statistics",
      name: "Statistics",
      components: { default: ExplorerStatistic }
    },
    {
      path: "block/:id",
      name: "Block",
      components: { default: Block }
    },
    {
      path: "block/", redirect: "/explorer/block/1"
    },
    {
      path: "transaction/", redirect: "/explorer/transaction/1"
    },
    {
      path: "transaction/:id",
      name: "Transaction",
      components: { default: Transaction }
    }
  ]
};
let nodesMenu = {
  path: "/nodes",
  component: DashboardLayout,
  name: "Nodes",
  redirect: "/nodes/statistics",

  children: [
    {
      path: "map",
      name: "Map",
      components: { default: NodesMap }
    },
    {
      path: "statistics",
      name: "Statistics",
      components: { default: NodesStatistics }
    },
    {
      path: "node",
      name: "Node",
      components: { default: User }
    }


  ]
};

let tokensMenu = {
  path: "/tokens",
  component: DashboardLayout,
  name: "Tokens",
  redirect: "/tokens/assets",

  children: [
    {
      path: "assets",
      name: "Assets",
      components: { default: Asset }
    },
    {
      path: "wallet",
      name: "Wallet",
      components: { default: Wallets }
    },
    {
      path: "rates",
      name: "Rates",
      components: { default: TokenRates }
    },
    {
      path: "service_wallets",
      name: "ServiceWallets",
      components: { default: ServiceWallets }
    }/*,
    {
      path: "statistic",
      name: "Statistic",
      components: { default: User }
    } */
  ]
};



let mapTest = {
  path: "/maptest",
  component: WorldMap,
  name: "Pigstry :0",

};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  explorerMenu,
  nodesMenu,
  tokensMenu,
  mapTest,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard }
      }
    ]
  },
  {
    path: '*',
    component: NotFoundPage
  }
];

export default routes;
