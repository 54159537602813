import globalConfig from "@/globalConfig.js";
import { isLoaded } from "google-maps";

class Api {
  constructor({baseUrl, handleCallback = Object}) {
    this._baseUrl = baseUrl
    this._handleCallback = handleCallback
  }

  _getResponseData(res) {
    if (!res.ok) {
      return Promise.reject(`Ошибка: ${res.status}`);
    }
    return res.json();
  }


/*GET IFO */
  getLastBlockId() {
    const params = {
      "jsonrpc":  "2.0",
      "method":   "get-current-block-id",
      "params":   {
        },  
      "id":       3,
      "version":  "1.0"
    }

    return fetch(this._baseUrl, {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then((res)=>{
      return this._getResponseData(res)
    })
  }

  getAssetCode() {
    const params = {
      "jsonrpc":  "2.0",
      "method":   "get-blockchain-info",
      "params":   {
        },  
      "id":       3,
      "version":  "1.0"
    }
    const assets = []

    return fetch(this._baseUrl, {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then((res)=>{
      return this._getResponseData(res)
    })
    .then((data) => {
      if (typeof data.error === 'undefined') { 
        data.result["supported-assets"].forEach((value) => {
          assets.push({
            "code":  value["code"],
            "name":    value["name"]
          })
        })
      } else {
        assets = null
      }
      return assets
    })
  }

  getInputTransaction(wallet, assetCode) {
    const params = {
      "jsonrpc":  "2.0",
      "method":   "get-transactions-to-wallet",
      "params":   {
          "wallet": wallet,
          "code-transaction":4,
          "count-transaction":20
        }, 
      "id":       5,
      "version":  "1.0"
    }
    const transaction=[];

    return fetch(this._baseUrl, {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then((res)=>{
      return this._getResponseData(res)
    })
    .then((res)=>{
      if (typeof res.error === 'undefined') {
        res.result["transactions"].forEach((value) => {
          let amount =  value["description"]["asset"]; 
          let code = assetCode.find(item => item.code ==  amount[0]["code"]);
          transaction.push({
                  "description":  value["description"]["description"],
                  "wallet":    value["description"]["from"],
                  "amount":    amount[0]["amount"],
                  "code":      code["name"]
              });
          });
      }
      return transaction;
    })
  }

  getOutputTransaction(wallet, assetCode) {
    const params = {
      "jsonrpc":  "2.0",
      "method":   "get-transactions-from-wallet",
      "params":   {
          "wallet": wallet,
          "code-transaction":4,
          "count-transaction":20
        }, 
      "id":       5,
      "version":  "1.0"
    }
    const transaction=[];

    return fetch(this._baseUrl, {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then((res)=>{
      return this._getResponseData(res)
    })
    .then((res)=>{
      if(typeof res.error === 'undefined' && res.result.counter > 0){
        res.data.result["transactions"].forEach((value) => {
          let amount =  value["description"]["asset"];
          let code = assetCode.find(item => item.code ==  amount[0]["code"]);
          transaction.push({
            "description":  value["description"]["description"],
              "wallet":    value["description"]["to"],
              "amount":    amount[0]["amount"],
              "code":      code["name"]
          });
        });
      }
      return transaction;
    })
  }

  getWalletState(wallet, assetCode) {
    const params = {
      "jsonrpc":  "2.0",
      "method":   "get-wallet-state",
      "params":   {
          "public-key": wallet
        }, 
      "id":       3,
      "version":  "1.0"
    }
    const balance = []
    let isLoaded = false
    let tableTransaction = false

    return fetch(this._baseUrl, {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then((res)=>{
      return this._getResponseData(res)
    })
    .then((res) => {
      if('exist' in res.result && res.result.exist === "0") {
        balance.push({"code": 0,"amount": 0});
        isLoaded = true;
        tableTransaction = true;
      } else {
        if ('balance' in res.result && res.result.balance.lenght > 0) {
          res.result.balance.forEach((value) => {
            const code = assetCode.find(item => String(item.code) ==  String(value["code"]));  
            balance.push({
              "code":        code["name"],
              "amount":    value["amount"]
            });
          });
          isLoaded = true;
          tableTransaction =true;
        }
      }
      return {
        balance: balance,
        isLoaded: isLoaded,
        tableTransaction: tableTransaction
      }
    })
  }

/*POST INFO */

  sendTransaction(parceTransactionBody) {
    const params = {
      "jsonrpc":  "2.0",
      "method":   "send-transaction",
      "params":   parceTransactionBody,
      "id":       3,
      "version":  "1.0"
    }

    return fetch(this._baseUrl, {
      method: 'POST',
      body: JSON.stringify(params)
    })
    .then((res)=>{
      return this._getResponseData(res)
    })
  }




}

const api = new Api({
  baseUrl: globalConfig.BackendServer
})

export default api