<template>
<div class="content">
  <loading  :active.sync="isLoading" 
            :can-cancel="false" 
            :is-full-page="true">
  </loading>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card md-theme="aurus">
        <md-card-header>
          <h4 class="title">Found {{ total }} entries</h4>
        </md-card-header>
        <md-card-content >
        <div id="paginated-toolbar" class="flex">
          <form v-on:submit.prevent="FilterSubmit('by_id')">
            <md-field>
              <md-input
                type="search"
                pattern="\d*"
                class="mb-3"
                style="width: 200px"
                v-model="id_filter"
                data-vv-name="id_filter"
                placeholder="Search by ID"
                :disabled="!id_filter_enabled"
              >
              </md-input>
            </md-field>
          </form>
          <form v-on:submit.prevent="FilterSubmit('by_digest')">
            <md-field>
            
              <md-input
                type="search"
                class="mb-3"
                style="width: 200px"
                v-model="digest_filter"
                data-vv-name="digest_filter"
                placeholder="Search by block digest"
                :disabled="!digest_filter_enabled"
              >
              </md-input>
              
            </md-field>
            
          </form>
          <form v-on:submit.prevent="FilterSubmit('by_date')">
          <md-field>
            <flat-picker 
               :config="{allowInput: true, mode: 'range'}"
               class="form-control datepicker"
               v-model="date_filter"
               placeholder="pick dates"
               :disabled="!date_filter_enabled"
               >
            </flat-picker>
            <md-icon>event</md-icon>
          </md-field>
          </form>
          <form v-on:submit.prevent="FilterSubmit('by_round')">
            <md-field>
              <md-input
                type="search"
                class="mb-3"
                style="width: 200px"
                v-model="round_filter"
                data-vv-name="round_filter"
                placeholder="Search by round"
                :disabled="!round_filter_enabled"
              >
              </md-input>
            </md-field>
          </form>
          <md-button 
              @click="updateTableFiltered"
              class="md-success md-primary md-sm"
          >Fetch</md-button>
          <md-button v-if="table_mode != 'default'" v-on:click="clearAndUpdateTable"
              class="md-icon-button accent_red" id="clear_btn"
            ><md-icon class="accent_red">highlight_off</md-icon></md-button>
        </div>
        <md-table
            :value="last_blocks_table_data"
            class="table-striped table-hover text-accent md-scrollbar"
            :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="sortHandler"
        >
          <md-table-empty-state
            md-label="Nothing found">
          </md-table-empty-state>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="ID" md-sort-by="block-id">{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Block-header digest" md-sort-by="previous" class="tooltip">{{ item.prev_block.slice(0, 4)+'...'}}
                <span class="tooltiptext">
                  {{item.prev_block}}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Merkle root" class="tooltip">{{item.merkle_root.slice(0, 4)+'...'}}
                <span class="tooltiptext">
                  {{item.merkle_root}}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Timestamp" md-sort-by="timestamp">{{ item.ts }}</md-table-cell>
              <md-table-cell md-label="round" md-sort-by="round">{{ item.round }}</md-table-cell>
              <md-table-cell md-label="count transactions">{{ item.nt }}</md-table-cell>   
              <md-table-cell md-label="Actions">
                <router-link :to="'/explorer/block/'+item.id+'/'" target="_blank">
                  <md-button class="md-just-icon">
                    <md-icon>search</md-icon>
                  </md-button>
                </router-link>
              </md-table-cell>
          </md-table-row>
        </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @input="pageChangeFunc"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
import axios from "axios";
import globalConfig from "@/globalConfig.js";
import { Pagination } from "@/components";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    components: {
        Pagination,
        flatPicker,
        Loading
    },
    computed: {
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        console.log("Total records: "+parseInt(this.blocks_count));
        return parseInt(this.blocks_count);
      }
    },
    methods: {
      // this method is not used but it has a nice snippet 
      // for router.push(...)
      showBlock(block_id) {
        console.log(block_id);
        this.$router.push({path: `/explorer/block/${block_id}`, target: "_blank"});
      },
      checkFilter() {
        let table_mode="default";
        if (this.id_filter != "") {
          table_mode = "by_id";
        } else 
        if (this.digest_filter!="") {
          table_mode = "by_digest";
        } else 
        if (this.date_filter!="") {
          table_mode = "by_date";
        } else 
        if (this.round_filter!="") {
          table_mode = "by_round";
        } else {
          this.id_filter_enabled = true;
          this.digest_filter_enabled = true;
          this.date_filter_enabled = true;
          this.round_filter_enabled = true;
        }
        this.table_mode = table_mode;
        if (this.table_mode == "default")
          this.updateTable();
      },
      clearAndUpdateTable() {
        this.table_mode = "default";
        this.currentSort = "default";
        this.id_filter = "";
        this.digest_filter = "";
        this.date_filter = "";
        this.round_filter="";
        this.id_filter_enabled = true;
        this.digest_filter_enabled = true;
        this.date_filter_enabled = true;
        this.round_filter_enabled = true;
        this.lock_auto_update = false;
        this.blocks_count = this.blocks_count_default;
        this.pagination.currentPage = 1;
        this.pagination.prevPage = null;
      },
      updateTableFiltered() {
        this.currentSort = "default";
        if (this.id_filter!="") {
          this.table_mode = "by_id";
        }
        if (this.digest_filter!="") {
          this.table_mode = "by_digest";
        }
        if (this.date_filter!="") {
          this.table_mode = "by_date";
        }
        if (this.round_filter!="") {
          this.table_mode = "by_round";
        }
        this.updateTable();
      },
      FilterSubmit(mode="default") {
        this.table_mode = mode;
        this.pagination.currentPage = 1;
        this.pagination.prevPage = null;
        this.currentSort = "default";
        this.updateTable();
      },
      pageChangeFunc(pageNum) {
        console.log("Switch page to "+pageNum+" (current page is "+this.pagination.prevPage+")");
        if (pageNum == 1)
          this.lock_auto_update = false;
        else
          this.lock_auto_update = true;
        if (this.pagination.prevPage == pageNum) {
          if (this.pagination.prevPage == null)
            this.pagination.prevPage = 1;
          console.log("Cancel fetch");
          return;
        }
        this.pagination.prevPage = pageNum;
        //switch(this.table_mode) {
        //default: {
        this.updateTable(pageNum);
        //}
        //};
      },
      updateTable_byTimer: function() {
        
        if (/*id == 0 && */this.lock_auto_update) {
          console.log("Update table by timer - locked");
          return;
        } else {
          console.log("Update table by timer");
          this.updateTable();
        }
      },
      sortHandler: function(value) {
        console.log("sortHandler, "+value+", currentSort: "+this.currentSort+ ", sortOrder: "+this.currentSortOrder);
        this.updateTable();
      },
      updateTable: function(pageNum=1) {  
        let start_offset = this.pagination.perPage*(pageNum-1);
        // show loader spinner
        this.isLoading = true;
        let query={
          "method":"get-header-blocks-on-id",
          "params":{
              "offset":  start_offset,
              "count-block": this.pagination.perPage,
              "add-nodes":1
              }, 
          "id": 1, 
          "jsonrpc": "2.0", 
          "version": "1.0"
        };
        console.log("Filtering by "+this.table_mode);
        switch(this.table_mode) {
          case "by_id": {
            query.params["start-block"] = parseInt(this.id_filter);
            query.params["count-block"] = 1;
            break;
          }
          case "by_digest": {
            delete query.params["add-nodes"];
            query.method = "get-header-blocks-on-digest";
            query.params["header-digest"]=this.digest_filter;
            break;
          }
          case "by_date": {
            delete query.params["add-nodes"];
            query.method = "get-header-blocks-on-time";
            query.params["header-time0"] = this.date0_filter;
            query.params["header-time1"] = this.date1_filter;
            //query.params["header-limit"] = 300;
            break;
          }
          case "by_round": {
            delete query.params["add-nodes"];
            //delete query.params["start-block"];
            query.method = "get-header-blocks-on-round";
            query.params["header-round"] = this.round_filter;
            query.params["header-limit"] = 100;
            break;
          }
          
          default: {
            break;
          }
        }
        if (this.currentSort != "default") {
          query.params["sorted-by"] = this.currentSort;
          query.params["sort-order"] = this.currentSortOrder;
        }
        axios.all([
            axios.post(globalConfig.BackendServer,
            {
                "jsonrpc":  "2.0",
                "method":   "get-blockchain-state",
                "params":   [],
                "id":       3,
                "version":  "1.0"
            }),
            axios.post(globalConfig.BackendServer, query)
        ]).then(axios.spread((resp, headerBlocks) => {
            // hide loader spinner
            this.isLoading = false;
            console.log(headerBlocks);
            
            // storing total pages number when by_id filter is active
            if (pageNum==1) {
              if ( headerBlocks.data.result && headerBlocks.data.result.hasOwnProperty("counter") ) {
                this.blocks_count = headerBlocks.data.result["counter"];
              } else {
                this.blocks_count = resp.data.result["block-count"];
              }
            }
            if (this.table_mode == "by_digest") {
              this.blocks_count = 1;
            }
            if (this.table_mode == "default")
              this.blocks_count_default = this.blocks_count;
            let blocks_data=[];
            let i=0;
            
            console.log(this.blocks_count);
            if (headerBlocks.data.result.counter > 0) 
            if (this.table_mode == "by_id") {
              let app = this
              headerBlocks.data.result['block-data'].forEach(function (value) {
                i++;
                if (value["id"]==parseInt(app.id_filter)) {
                  blocks_data.push({
                      "index":        i,
                      "prev_block":   value["block-header-digest"],
                      "merkle_root":  value["merkle-root"],
                      "ts":           value["timestamp"],
                      "id":           value["id"],
                      "round":        value["round"],
                      "nt":           value["transaction-count"]
                  });
                }
              });
            } else {
              headerBlocks.data.result['block-data'].forEach(function (value) {
                  i++;
                  blocks_data.push({
                      "index":        i,
                      "prev_block":   value["block-header-digest"],
                      "merkle_root":  value["merkle-root"],
                      "ts":           value["timestamp"],
                      "id":           value["id"],
                      "round":        value["round"],
                      "nt":           value["transaction-count"]

                  });
              });
            }
            this.last_blocks_table_data = blocks_data;
        }));

      }
    },
    props: {
      // milliseconds to update map nodes
      update_interval: {
          type: Number,
          default: 20000
      }
    },
    data() {
      return {
        currentSort: "default",
        currentSortOrder: "asc",
        isLoading:  false,
        date0_filter: "",
        date1_filter: "",
        round_filter: "",
        date_filter: "",
        digest_filter: "",
        id_filter: "",
        table_mode: "default",
        id_filter_enabled: true,
        digest_filter_enabled: true,
        date_filter_enabled: true,
        round_filter_enabled: true,
        pagination: {
          prevPage: null,
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25],
          total: 0
        },
        //transaction_count: -1,
        blocks_count: -1,
        blocks_count_default: -1,
        timer:              null,
        last_blocks_table_data: [],
        number: "",
        lock_auto_update: false,
        touched: {
            number: false,
        },
        modelValidations: {
            required: {
              required: true
            },
            number: {
              required: true,
              numeric: true
            }
        }
      };
    },
    async beforeMount() {
      console.log("Pre mount");
      // Fetch block chain state and info, get total number of transactions and stuff 
      // to draw pagination and init some comboboxes
      let jsonRes = await axios.all([
        axios.post(globalConfig.BackendServer,
        {
            "jsonrpc":  "2.0",
            "method":   "get-blockchain-state",
            "params":   [],
            "id":       3,
            "version":  "1.0"
        })
      ]);
      let blockChainStateInfo = jsonRes[0];
      this.blocks_count = parseInt(blockChainStateInfo.data.result["block-count"]);
      this.updateTable();  
    },
    mounted() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("md-table");
      } 
        //this.updateTable();
        //this.timer = setInterval(this.updateTable_byTimer, Number(this.update_interval));
    },//mounted
    watch: {
        number() {
          this.touched.number = true;
        },
        id_filter(v) {
          if (v!="") {
            this.table_mode = "by_id";
            this.id_filter_enabled = true;
            this.digest_filter_enabled = false;
            this.date_filter_enabled = false;
            this.round_filter_enabled = false;
          } else {
            this.checkFilter();
          }
        },
        digest_filter(v) {
          if (v!="") {
            this.table_mode = "digest_filter";
            this.id_filter_enabled = false;
            this.digest_filter_enabled = true;
            this.date_filter_enabled = false;
            this.round_filter_enabled = false;
          } else {
            this.checkFilter();
          }
        },
        date_filter(v) {
          if (v!="") {
            this.table_mode = "date_filter";
            let dates = v.split(" to ");
            if (dates.length == 2) {
              this.date0_filter = dates[0];
              this.date1_filter = dates[1];
            } else {
              let d = new Date(dates[0]);
              this.date0_filter = dates[0];
              d.setDate(d.getDate() + 1);
              this.date1_filter = d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
            }
            this.id_filter_enabled = false;
            this.digest_filter_enabled = false;
            this.date_filter_enabled = true;
            this.round_filter_enabled = false;
          } else {
            this.checkFilter();
          }
        },
        round_filter(v) {
          if (v!="") {
            this.table_mode = "round_filter";
            this.id_filter_enabled = false;
            this.digest_filter_enabled = false;
            this.date_filter_enabled = false;
            this.round_filter_enabled = true;
          } else {
            this.checkFilter();
          }
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
};
</script>
<style lang="scss" scoped>
  .datepicker {
    background: none;
  }
  .flex {
      display: flex;
      flex-wrap: wrap;
  }
  #clear_btn .accent_red, .accent_red {
    color: white !important;
    background-color: red !important;
  }
  .text-right {
    display: flex;
  }
  .md-field {
    margin-left:10px;
  }
</style>
