/* eslint-disable */
<template>

    <div class="md-layout">

        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="aurus">
                <template slot="header">
                    <div class="card-icon">
                        <i class="fab fa-linode"></i>
                    </div>
                    <p class="category">Total blocks including genesis</p>
                    <h3 class="title">
                        <animated-number :value="blocks_count"></animated-number>
                    </h3>
                </template>
            </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="aurus">
                <template slot="header">
                    <div class="card-icon">
                        <i class="fa fa-database"></i>
                    </div>
                    <p class="category">Total nodes</p>
                    <h3 class="title">
                        <animated-number :value="nodes_count"></animated-number>
                    </h3>
                </template>
            </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="aurus">
                <template slot="header">
                    <div class="card-icon">
                        <i class="fas fa-chart-area"></i>
                    </div>
                    <p class="category">Total transactions</p>
                    <h3 class="title">
                        <animated-number :value="transaction_count"></animated-number>
                    </h3>
                </template>
            </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
            <stats-card header-color="aurus">
                <template slot="header">
                    <div class="card-icon">
                        <i class="fas fa-file-signature"></i>
                    </div>
                    <p class="category">Consensus round</p>
                    <h3 class="title">
                        #<animated-number :value="consensus_round"></animated-number>
                    </h3>
                </template>
            </stats-card>
        </div>
        <div class="md-layout-item md-size-100">
          <md-card>
            <md-card-header>

              <h4 class="title">Last 5 blocks</h4>
            </md-card-header>
            <md-card-content>
              <md-table v-model="last_blocks_table_data" table-header-color="aurus"
              class="table-striped table-hover text-accent md-scrollbar">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="ID">{{ item.id }}
                  </md-table-cell>
                  <md-table-cell md-label="Prev-block digest" class="tooltip">{{item.prev_block.slice(0, 4)+'...'}}
                    <span class="tooltiptext">
                      {{item.prev_block}}
                    </span>
                  </md-table-cell>
                  <md-table-cell md-label="Merkle root" class="tooltip">{{item.merkle_root.slice(0, 4)+'...'}}
                    <span class="tooltiptext">
                      {{item.merkle_root}}
                    </span>
                  </md-table-cell>
                  <md-table-cell md-label="Timestamp">{{ item.ts }}
                  </md-table-cell>
                  <md-table-cell md-label="round">{{ item.round }}
                  </md-table-cell>
                  <md-table-cell md-label="count transactions">{{ item.nt }}
                  </md-table-cell>
		            </md-table-row>
              </md-table>
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-size-100">
          <md-card>
            <md-card-header>
              <h4 class="title">The map is provided by a third party service.  The data on the location of the nodes is indicative.</h4>
            </md-card-header>
          </md-card>
        </div>


        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
            <nodes-map :update_interval=20000></nodes-map>
        </div>
        
    </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
import axios from "axios";
import globalConfig from "@/globalConfig.js";
//import AsyncWorldMap from "@/components/WorldMap/AsyncWorldMap.vue";
import {
    StatsCard,
//    ChartCard,
//    ProductCard,
    AnimatedNumber,
//    GlobalSalesCard,
//    GlobalSalesTable,
//    Map
} from "@/components";

export default {
    components: {
        StatsCard,
        //ChartCard,
        AnimatedNumber,
//        ProductCard,
//        GlobalSalesCard,
//        GlobalSalesTable,
//        AsyncWorldMap,
//        Map
    },
    methods: {
      updateProjectStatus: function() {  
        
        axios.all([
            axios.post(globalConfig.BackendServer,
            {
                "jsonrpc":  "2.0",
                "method":   "get-blockchain-state",
                "params":   [],
                "id":       3,
                "version":  "1.0"
            }),
            axios.post(globalConfig.BackendServer,
            {
                "method":"get-header-blocks-on-id",
                "params":{
                    "start-block": 0,
                    "count-block":5,
                    "add-nodes":1
                    }, 
                "id": 1, 
                "jsonrpc": "2.0", 
                "version": "1.0"
            })
        ]).then(axios.spread((resp, headerBlocks) => {
            this.project_name = resp.data.result["project"];
            this.blocks_count = resp.data.result["block-count"];
            this.nodes_count = resp.data.result["node-count"];
            this.consensus_round = resp.data.result["consensus-round"];
            this.transaction_count = resp.data.result["transaction-count"];
            let blocks_data=[];
            let i=0;
            headerBlocks.data.result['block-data'].forEach(function (value) {
                i++;
                blocks_data.push({
                    "index":        i,
                    "prev_block":   value["previous-block-digest"],
                    "merkle_root":  value["merkle-root"],
                    "ts":           value["timestamp"],
                    "id":           value["id"],
                    "round":        value["round"],
                    "nt":           value["transaction-count"]
                });
            });
            this.last_blocks_table_data = blocks_data;
        }));

      }
    },
    props: {
      // milliseconds to update map nodes
      update_interval: {
          type: Number,
          default: 20000
      }
    },
    data() {
        return {
            project_name:       "",
            transaction_count:  0,
            consensus_round:    0,
            nodes_count:        0,
            timer:              null,
            blocks_count:       0,
            last_blocks_table_data: []
        };
    },
    mounted() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("md-table");
      } 
      this.updateProjectStatus();
      this.timer = setInterval(this.updateProjectStatus, Number(this.update_interval));
    },//mounted
    beforeDestroy() {
        clearInterval(this.timer)
    }
};
</script>
