import SmartContractsFiles from "./SmartContractsFiles.vue";
import SmartPagination from "./SmartPagination.vue";
import WalletsWork from "./WalletsWork.vue";

const SmartContractsFilesPlugin = {
  install(Vue) {
    Vue.component("smart-contracts-files", SmartContractsFiles);
    Vue.component("smart-contracts-pagination", SmartPagination);
    Vue.component("smart-contracts-wallets", WalletsWork);
  }
};

export default SmartContractsFilesPlugin;
