<template>
  <div class="md-layout ">
    <loading  :active.sync="isLoading" 
            :can-cancel="false" 
            :is-full-page="true"
    />
    <div class="md-layout-item md-size-100"> 
      <img src="/img/aurus-full.png" style="height: 50px;width:auto; margin-bottom: 40px;"/>
    </div>
    <div class="md-layout-item md-large-size-75 md-medium-size-100 md-medium-small-100"> 
      <md-card class="wallet-widget-height " >
        <md-card-header class="md-card-header-icon md-card-header-aurus">
            <div class="card-icon"><md-icon>account_balance_wallet</md-icon></div>
              <h4 v-show="!wallet.loaded" class="title">Existing wallet</h4>
              <h4 v-show="wallet.loaded" class="title" style="overflow-wrap: break-word;">Wallet: {{ wallet.address }}&nbsp;<span v-if="smallLoader">Updating data... </span></h4>
        </md-card-header>
        <md-card-content>
          <div class="rates_grid" v-if="wallet.loaded">
                <div class="rates_grid_item" >
                  <strong>AUS/USD</strong>: {{ xchange.rates.aus_usd }}
                </div>
                <div class="rates_grid_item">
                  <strong>USD/AUS</strong>: {{ xchange.rates.usd_aus }}
                </div>
                <div class="rates_grid_item">
                  <strong>AUS/EUR</strong>: {{ xchange.rates.aus_eur }}
                </div>
                <div class="rates_grid_item">
                  <strong>EUR/AUS</strong>: {{ xchange.rates.eur_aus }}
                </div>
                <div class="rates_grid_item">
                  <strong>EUR/UST</strong>: {{ xchange.rates.eur_ust }}
                </div>
                <div class="rates_grid_item">
                  <strong>EUR/USD</strong>: {{ xchange.rates.eur_usd }}
                </div>
                <div class="rates_grid_item">
                  <strong>UST/USD</strong>: {{ xchange.rates.ust_usd }}
                </div>
                <div class="rates_grid_item">
                  <strong>UST/AUS</strong>: {{ xchange.rates.ust_aus }}
                </div>
                <div class="rates_grid_item">
                  <strong>UST/EUR</strong>: {{ xchange.rates.ust_eur }}
                </div>
                <div class="rates_grid_item">
                  <strong>USD/UST</strong>: {{ xchange.rates.usd_ust }}
                </div>
                <div class="rates_grid_item">
                  <strong>USD/EUR</strong>: {{ xchange.rates.usd_eur }}
                </div>
                <div class="rates_grid_item">
                  <strong>AUS/UST</strong>: {{ xchange.rates.aus_ust }}
                </div>
          </div>
          <div v-show="!wallet.loaded">
            <md-button  class="md-success md-fileinput button-fixed-width-wide" md-alignment="left">
              Log in
              <input type="file" @change="loadContainerFile" />
            </md-button> 
          </div>
          <div v-show="wallet.loaded">
          <!-- display tokens balance -->
          <md-table v-model="wallet.balance" table-header-color="aurus" class="table-striped table-hover text-accent" >
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Asset">{{ item.code }}</md-table-cell>
              <md-table-cell md-label="Balance">{{ item.amount }}</md-table-cell>
              <md-table-cell md-label="">
                <!-- <md-button class="md-notice button-fixed-width " md-alignment="left" @click="OpenSendForm(item.code)">
                  SEND <i class="fas fa-paper-plane" style="padding-left:10px;font-size:0.9rem !important"></i>
                </md-button>
                <span>&nbsp;</span>-->
                <md-button class="md-notice button-fixed-width " v-if="assetExchangeable(item.code)" md-alignment="left" @click="XChangeForm(item.code)">
                  EXCHANGE <i class="fas fa-sync-alt" style="padding-left:10px;font-size:0.9rem !important"></i>
                </md-button>
                <span> &nbsp;</span>
                <md-button class="md-notice button-fixed-width " v-if="assetExchangeable(item.code)" md-alignment="left" @click="CashoutForm(item.code)">
                  WITHDRAW <i class="fas fa-hand-holding-usd" style="padding-left:10px;font-size:0.9rem !important"></i>
                </md-button>
              </md-table-cell>
            </md-table-row>

          </md-table>
          <md-button v-if="wallet.address" class="md-notice button-fixed-width-wide " md-alignment="left" @click="CloseWallet">
            Log out <i class="fas fa-sign-out-alt" style="padding-left:10px;font-size:0.9rem !important"></i>
          </md-button>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <!-- SEND TOKENS -->
    <div v-show="sendtokenForm" class="md-layout-item md-small-size-100">
        <md-card class="wallet-widget-height" md-theme="aurus">
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon">
            <md-icon>arrow_upward</md-icon>
          </div>
          <h4 class="title">Transfer</h4>
        </md-card-header>
        <md-card-content>
          <md-field>
            <label>Address</label>
            <md-input v-model="transaction.destination" type="text"></md-input>
          </md-field>
          <!--
            <md-field>
            <label>Asset</label>
            <md-input v-model="transaction.asset" type="text"></md-input>
          </md-field>
          -->
          <md-field >
            <label for="token_list">Asset</label>
            <md-select v-model="transaction.asset" name="token_list">
              <md-option
                v-for="item in wallet.balance"
                :key="item.code"
                :label="item.code"
                :value="item.code"
              >
                {{ item.code }}
              </md-option>
            </md-select>
          </md-field>
          <md-field>
            <label>Amount</label>
            <md-input v-model="transaction.amount" type="text" pattern="^[0-9]+$" placeholder="0" ></md-input>
          </md-field>
          <md-field>
            <label>Description</label>
            <md-input v-model="transaction.description" type="text"></md-input>
          </md-field>
          <md-button v-if="wallet.address" class="md-success button-fixed-width" md-alignment="left" @click="sendTransaction" :disabled="blockSend">
            Send
          </md-button>
          <md-button v-if="wallet.address" class="md-notice button-fixed-width" md-alignment="left" @click="cancelSend">
            Cancel
          </md-button>
        </md-card-content>
      </md-card>
    </div>
    <!-- EXCHANGE TOKENS -->
    <div v-show="xchangeForm" class="md-layout-item md-small-size-100 md-large-size-25 md-medium-size-50 md-medium-small-50">
        <md-card class="wallet-widget-height" md-theme="aurus">
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon">
            <md-icon>arrow_upward</md-icon>
          </div>
          <h4 class="title">Exchange</h4>
        </md-card-header>
        <md-card-content>
          <md-field >
            <label for="token_list_b">Buying asset</label>
            <md-select v-model="xchange.buy_asset" name="token_list_b" @input="buyAssetChanged">
              <md-option
                v-for="item in valid_buy_asset"
                :key="item.code"
                :label="item.name"
                :value="item.name"
              >
                {{ item.name }}
              </md-option>
            </md-select>
          </md-field>
          <md-field >
            <label for="token_list_s">Selling asset</label>
            <md-select v-model="xchange.sell_asset" name="token_list_s" @input="sellAssetChanged">
              <md-option
                v-for="item in valid_sell_asset"
                :key="item.code"
                :label="item.name"
                :value="item.name"
              >
                {{ item.name }}
              </md-option>
            </md-select>
          </md-field>
          <md-field>
            <label>Amount</label>
            <md-input v-model="xchange.amount" type="text" pattern="^[0-9]+$" placeholder="0" @input="xchangeAmountChanged"></md-input>
            <md-button @click="setMaxAmount">MAX</md-button>
          </md-field>
          <div>
            <span>Rates:{{xchange.sell_asset}}/{{xchange.buy_asset}}: {{currentRate}}</span>
          </div>
          <div style="display:flex;flex-flow:column" v-if="xchange.amount>0">             
            <span>You pay: {{xchange.amount}} {{xchange.sell_asset}} </span>
            <span>You get: {{xchange.price}} {{xchange.buy_asset}} </span>
          </div>

          <md-button v-if="wallet.address" class="md-success button" md-alignment="left" @click="xchangeTransaction" :disabled="!xchangeBtnEnabled || blockSend || coolDownTimer > 0">
            Confirm <span v-if="coolDownTimer>0">({{coolDownTimer}})</span>
          </md-button>
          <md-button v-if="wallet.address" class="md-notice button-fixed-width" md-alignment="left" @click="cancelXChange">
            Cancel
          </md-button>
        </md-card-content>
      </md-card>
    </div>
    <!-- CASHOUT -->
    <div v-show="cashoutForm" class="md-layout-item md-small-size-100">
        <md-card class="wallet-widget-height" md-theme="aurus">
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon">
            <md-icon>arrow_upward</md-icon>
          </div>
          <h4 class="title">Withdraw</h4>
        </md-card-header>
        <md-card-content>
          <md-field >
            <label for="token_list">Asset</label>
            <md-select v-model="cashout.asset" name="token_list" >
              <md-option
                v-for="item in valid_cashout_asset"
                :key="item.code"
                :label="item.name"
                :value="item.name"
              >
                {{ item.name }}
              </md-option>
            </md-select>
          </md-field>
          <md-field>
            <label>Amount</label>
            <md-input v-model="cashout.amount" type="text" pattern="^[0-9]+$" placeholder="0" @input="withdrawAmountChanged"></md-input>
          </md-field>
          <md-button v-if="wallet.address" class="md-success button-fixed-width" md-alignment="left" @click="cashoutTransaction" :disabled="!withdrawBtnEnabled">
            Confirm
          </md-button>
          <md-button v-if="wallet.address" class="md-notice button-fixed-width" md-alignment="left" @click="cancelCashout">
            Cancel
          </md-button>
        </md-card-content>
      </md-card>
    </div>
  </div>
  
  
</template>

<script>

import { setTimeout, clearTimeout } from "timers";
import Swal from "sweetalert2";
import axios from "axios";
import globalConfig from "@/globalConfig.js"
import {FetchCyclic} from "@/lib/fetchCyclic.js"
import "@/pages/Dashboard/Wasm/shampoox.wasm"
import Shampoox from "@/pages/Dashboard/Wasm/shampoox";
import "@/pages/Dashboard/Wasm/Container.wasm";
import Container from "@/pages/Dashboard/Wasm/Container";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "user-wallet",
  components: {
  Loading
  },
  props: {

    node_url: {
      type: String,
      default: globalConfig.BackendServer
    }
  },
  computed: {
    xchangeBtnEnabled() {
      let amount_ok=false
      let inputAmount = this.xchange.amount
      if (inputAmount) {
        try {
          let tmp =parseFloat(inputAmount*1)
          if (tmp) {
            amount_ok = true
          }
        } catch {
          amount_ok = false
        }
      }
      return amount_ok
    },
    withdrawBtnEnabled() {
      let amount_ok=false
      let inputAmount = this.cashout.amount
      if (inputAmount) {
        try {
          let tmp =parseFloat(inputAmount*1)
          if (tmp) {
            amount_ok = true
          }
        } catch {
          amount_ok = false
        }
      }
      return amount_ok
    },
    valid_buy_asset: function() {
      let app = this
      return this.wallet.assetCode.filter(function(u) {
        
        //return app.xchange.sell_asset != u.name && app.assetExchangeable(u.name) && !(app.xchange.sell_asset != "AUS" && ["USD","UST","EUR"].includes(u.name))
        return app.assetExchangeable(u.name) && (app.xchange.sell_asset != u.name)
      })
    },
    valid_cashout_asset: function() {
      let app = this
      let wallet= this.wallet.balance
      return this.wallet.assetCode.filter(function(u) {
        //return u.name != "AUS" && app.assetExchangeable(u.name)
        let inwallet = wallet.find(item => item.code ==  u.name)
        return app.assetExchangeable(u.name) && inwallet
      })
    },
    valid_sell_asset: function() {
       let xchange = this.xchange
       // tokens available in current wallet
       let wallet= this.wallet.balance
       let app = this
       // can only sell tokens that are available in this wallet
       return this.wallet.assetCode.filter(function(u) {
         let inwallet = wallet.find(item => item.code ==  u.name)
         return (u.name != xchange.buy_asset) && inwallet!==undefined && app.assetExchangeable(u.name)
    })},
    currentRate: function() {
      if (this.xchange.buy_asset == "AUS" && this.xchange.sell_asset=="USD") //1
        {
          return  this.xchange.rates.usd_aus
        }
       if (this.xchange.buy_asset == "USD" && this.xchange.sell_asset=="AUS") //2
       {
          return this.xchange.rates.aus_usd
       }
       if (this.xchange.buy_asset == "AUS" && this.xchange.sell_asset=="EUR") //
       {
          return this.xchange.rates.eur_aus
       }
       if (this.xchange.buy_asset == "EUR" && this.xchange.sell_asset=="AUS") //4
       {
          return this.xchange.rates.aus_eur
       }
       if (this.xchange.buy_asset == "EUR" && this.xchange.sell_asset=="UST") //5
       {
          return this.xchange.rates.ust_eur
       }
       if (this.xchange.buy_asset == "EUR" && this.xchange.sell_asset=="USD") //6
       {
          return this.xchange.rates.usd_eur
       }
       if (this.xchange.buy_asset == "UST" && this.xchange.sell_asset=="USD") //7
       {
          return this.xchange.rates.usd_ust
       }
       if (this.xchange.buy_asset == "UST" && this.xchange.sell_asset=="AUS") //8
       {
          return this.xchange.rates.aus_ust 
       }
       if (this.xchange.buy_asset == "UST" && this.xchange.sell_asset=="EUR") //9
       {
          return this.xchange.rates.eur_ust
       }
       if (this.xchange.buy_asset == "USD" && this.xchange.sell_asset=="UST") //10
       {
          return this.xchange.rates.ust_usd
       }
       if (this.xchange.buy_asset == "USD" && this.xchange.sell_asset=="EUR") //11
       {
          return this.xchange.rates.eur_usd
       }
       if (this.xchange.buy_asset == "AUS" && this.xchange.sell_asset=="UST") //12
       {
          return this.xchange.rates.ust_aus
       }
      return null
    },
  },
  data() {
    //host, wallet, asset, amount, description
    return {
      coolDownTimer: 0,
      isLoading:  false,
      smallLoader: false,
      passphrase: "",
      sendtokenForm: false,
      xchangeForm: false,
      cashoutForm: false,
      blockSend:  false,
      blockWithdraw: false,
      blockExchange: false,
      transaction:{
        destination: "",
        asset:0,
        amount:null,
        description:"",
        body: ""
      },
      xchange : {
        buy_asset: 0,
        sell_asset: 0,
        price:0,
        amount:0,
        cfg: {
          // wallet that publishes xchange rate
          rate_source: "jajaHge4XcsRDz51uNT5Vn2YzALGL2SDLgzNKSXmf8texKHcU",
          xchange_map:[
            "2UDCtCUTLgLpJd9q7zrLoPfhBh94dtRXvNEmSjSCgVhcA5ZXiv",//1  aus-usd
            "2QA62YVKvNuXG61tY2zTd7AgeMZpaBX5zawgR484V2HqypCo4Y",//2  usd-aus
            "2nQHeNfJ6PtvfcumFMVdjMJwfgyDkBvxYa443nmNJ3BKA3fYEz",//3  aus-eur
            "JooDfC3GynVtUPjg9FmkHwQkSJnTh3i6uxLJQs8YhT5Fxh18Z", //4  eur-aus
            "mqwds5dVc8VwMrRMKpvCsbPzRxa6Kz8mJPkX2L3LfVprToKdZ", //5  eur-ust
            "2iVHksKa9QjhK6m3dtW755k9RHfi16Em1NAzbeSpARgjyNrTEo",//6  eur-usd
            "2tPnbuNWPfq24tuuB8diHgLj74NKdobfcLSW198oo8JN3x5CKe",//7  ust-usd
            "2hGLr2UMLPCcEneqKNC5kVHM7ygvFzdKnQCf91StNPNv3Uq5XY",//8  ust-aus
            "2fkRoaBZgwdH5HYBRTCqJgjyC6Lzjrj4Jsv1nWhypiHd8ZCJRf",//9  ust-eur
            "2m7mE8Q8N4ehYfSK24RWve86fE7tN2GS4kj227mqNYNNkez3gt",//10 usd-ust
            "56u9bRaasngGZsrUrmqyFeRUBRnuhkjuAbrmqXAVJbSjVBdhQ", //11 usd-eur
            "ZesNcqeM9J6jrftUpvnDvPjFXR49UgZenJm3KPLPpSPzE6zzE", //12 aus-ust
          ]
        },
        rates: {
          aus_eur: null,
          eur_aus: null, 
          aus_usd: null,
          usd_aus: null,
          eur_ust: null,
          eur_usd: null,
          ust_usd: null,
          ust_aus: null,
          ust_eur: null,
          usd_ust: null,
          usd_eur: null,
          aus_ust: null,
        },
        selectedRate: null
      },
      cashout: {
        asset: 0,
        amount: null
      },
      wallet: {
        address: "",
        message: "",
        hash: "",
        keyPair:{},
        inputTransaction:{},
        outputTransaction:{},
        assetCode:[],
        name: "not set",
        size: 0,
        loaded: false,
        table_transaction: false,
        transaction_id: 1,
        progress: {
          show: false,
          value: 0
        },
        
        balance:[]
      }
    };
  },

  methods: {
    buyAssetChanged(value) {
      this.xchange.amount = null
      
    },
    getBalanceByToken(token_code) {
      let tokenData = this.wallet.balance.find(item=> {
        return item.code.toUpperCase() == token_code.toUpperCase()
      })
      if (tokenData) {
        return tokenData.amount
      } else {
        return 0
      }
    },
    setMaxAmount() {
      
      let available_balance = this.getBalanceByToken(this.xchange.sell_asset)
      //let max_amount = Number(available_balance / this.currentRate).toFixed(2)
      let max_amount = Number(available_balance).toFixed(4)
      this.xchange.amount = max_amount
    },
    sellAssetChanged(value) {
      this.xchange.amount = null
      switch(value) {
        case "AUS": 
        case "UST": 
        case "EUR": {this.xchange.buy_asset="USD";break;}
        case "USD": {this.xchange.buy_asset="AUS";break;}
      }
    },
    assetExchangeable(code) {
      return ["AUS","USD","UST","EUR"].includes(String(code).toUpperCase())
    },
    withdrawAmountChanged(value) {
      if (value)
        this.cashout.amount = String(value).replace(',','.')
    },
    xchangeAmountChanged(value) {
      if (this.xchange.amount)
        this.xchange.amount =this.xchange.amount.replace(',','.')
      let rate;
      if (this.xchange.buy_asset == "AUS" && this.xchange.sell_asset=="USD") //1
        rate = this.xchange.rates.usd_aus
      if (this.xchange.buy_asset == "USD" && this.xchange.sell_asset=="AUS") //2
        rate = this.xchange.rates.aus_usd
      if (this.xchange.buy_asset == "AUS" && this.xchange.sell_asset=="EUR") //3
        rate = this.xchange.rates.eur_aus
      if (this.xchange.buy_asset == "EUR" && this.xchange.sell_asset=="AUS") //4
        rate = this.xchange.rates.aus_eur
      if (this.xchange.buy_asset == "EUR" && this.xchange.sell_asset=="UST") //5
        rate = this.xchange.rates.ust_eur
      if (this.xchange.buy_asset == "EUR" && this.xchange.sell_asset=="USD") //6
        rate = this.xchange.rates.usd_eur
      if (this.xchange.buy_asset == "UST" && this.xchange.sell_asset=="USD") //7
        rate = this.xchange.rates.usd_ust
      if (this.xchange.buy_asset == "UST" && this.xchange.sell_asset=="AUS") //8
        rate = this.xchange.rates.aus_ust
      if (this.xchange.buy_asset == "UST" && this.xchange.sell_asset=="EUR") //9
        rate = this.xchange.rates.eur_ust
      if (this.xchange.buy_asset == "USD" && this.xchange.sell_asset=="UST") //10
        rate = this.xchange.rates.ust_usd
      if (this.xchange.buy_asset == "USD" && this.xchange.sell_asset=="EUR") //11
        rate = this.xchange.rates.eur_usd
      if (this.xchange.buy_asset == "AUS" && this.xchange.sell_asset=="UST") //12
        rate = this.xchange.rates.ust_aus
      this.xchange.price = (parseFloat(value)*parseFloat(rate).toFixed(4)).toFixed(2)
    },
    OpenSendForm(token_code) {
      this.sendtokenForm = true
      this.transaction.asset = token_code
      this.transaction.amount = 0
      console.log(token_code)
    },
    XChangeForm(token_code) {
      this.xchange.sell_asset = token_code
      this.xchange.amount = null
      this.xchangeForm = true
    },
    CashoutForm(token_code) {
      this.cashoutForm = true
      this.cashout.asset = token_code
    },
    async cashoutTransaction() {
      let dst;
      if (this.cashout.asset == "UST")
        dst = "dtocsG3eM4xJqreRJTovDU99oozsoYCkTb98wdRGC4gPNDu1k"
      if (this.cashout.asset == "EUR")
        dst = "WEyDL7Ggg3fpU8bukDWhPFVBDoVwZfGNSuXV4QqJBtpn189WE"
      if (this.cashout.asset == "AUS")
        dst = "21rgncwGrFWZeuKPpavFz7oaAAAF84TJzXeagmiBgsFzPePn5i"
      if (this.cashout.asset == "USD")
        dst = "2JhhZFX3L1TZoX4zRc4sTAP5FWvbk34khShEyWKxVhTBZNwXq7"
      this.doTransfer(
        this.node_url,
        dst,
        this.cashout.asset,
        this.cashout.amount,
        "withdrawal"
      );
        
    },
    async xchangeTransaction() {
      let dst;
      let to_asset  = this.xchange.buy_asset
      let from_asset= this.xchange.sell_asset
      if (to_asset == "AUS" && from_asset=="USD") //2
        dst= this.xchange.cfg.xchange_map[1]
      if (to_asset == "USD" && from_asset=="AUS") //1
        dst= this.xchange.cfg.xchange_map[0]

      if (to_asset == "AUS" && from_asset=="EUR") //4
        dst= this.xchange.cfg.xchange_map[3]
      if (to_asset == "EUR" && from_asset=="AUS") //2
        dst= this.xchange.cfg.xchange_map[2]

      if (to_asset == "EUR" && from_asset=="UST") //9
        dst= this.xchange.cfg.xchange_map[8]

      if (to_asset == "EUR" && from_asset=="USD") //1
        dst= this.xchange.cfg.xchange_map[10]

      if (to_asset == "UST" && from_asset=="USD") //10
        dst= this.xchange.cfg.xchange_map[9]

      if (to_asset == "UST" && from_asset=="AUS") //12
        dst= this.xchange.cfg.xchange_map[11]

      if (to_asset == "UST" && from_asset=="EUR") //5
        dst= this.xchange.cfg.xchange_map[4]

      if (to_asset == "USD" && from_asset=="UST") //7
        dst= this.xchange.cfg.xchange_map[6]

      if (to_asset == "USD" && from_asset=="EUR") //6
        dst= this.xchange.cfg.xchange_map[5]

      if (to_asset == "AUS" && from_asset=="UST") //12
        dst= this.xchange.cfg.xchange_map[7]

      this.doTransfer(
        this.node_url,
        dst,
        from_asset,
        this.xchange.amount,
        "xchange "+from_asset+" -> "+to_asset
      );
    },
    async sendTransaction() {
      this.doTransfer(
        this.node_url,
        this.transaction.destination,
        this.transaction.asset,
        this.transaction.amount,
        this.transaction.description,
      );
    },
    handleFetchError() {

    },
    async getXChangeRate() {
      let dst = this.xchange.cfg.rate_source
      let params = {
        "jsonrpc":  "2.0",
        "method":   "get-transactions-from-wallet",
        "params":   {"wallet": dst,
                    "code-transaction":36,  // ==user-defined-transaction
                    "count-transaction":100}, 
        "id":       3,
        "version":  "1.0"
      };
      const rate_codes = [1,9,17,25,33,41,49,57,65,73,81,89]
      FetchCyclic.setErrorCallback(err=> {console.log("error callback!",err)})
      FetchCyclic.setSuccessCallback(response=> {
        {
        let result = response.json()
        let rates_markers = {}
        result = result.result
        if (result && result.counter>0) {
          let breakFlag = false
          result.transactions.forEach((item)=> {
            if (breakFlag)
              return
            let value = item.description.message
            let msgID = Number(item.description["message-id"])
            
            if (rates_markers[msgID] === undefined) {
              rates_markers[msgID] = Number(value)
            }
            let breakCounter=0
            rate_codes.forEach(code => {
              if (code in rates_markers)
                breakCounter++
              if (breakCounter==rate_codes.length)
                breakFlag = true
            })
            
          })
          if (1 in rates_markers) this.xchange.rates.aus_usd = rates_markers[1]
          if (9 in rates_markers) this.xchange.rates.usd_aus = rates_markers[9]
          if (17 in rates_markers) this.xchange.rates.aus_eur = rates_markers[17]
          if (25 in rates_markers) this.xchange.rates.eur_aus = rates_markers[25]
          if (33 in rates_markers) this.xchange.rates.eur_ust = rates_markers[33]
          if (41 in rates_markers) this.xchange.rates.eur_usd = rates_markers[41]
          if (49 in rates_markers) this.xchange.rates.ust_usd = rates_markers[49]
          if (57 in rates_markers) this.xchange.rates.ust_aus = rates_markers[57]
          if (65 in rates_markers) this.xchange.rates.ust_eur = rates_markers[65]
          if (73 in rates_markers) this.xchange.rates.usd_ust = rates_markers[73]
          if (81 in rates_markers) this.xchange.rates.usd_eur = rates_markers[81]
          if (89 in rates_markers) this.xchange.rates.aus_ust = rates_markers[89]
          
        }
      }
      })
      await FetchCyclic.post(this.node_url,{method: "POST",body: JSON.stringify(params)})
    },
    doTransfer(host, wallet, asset, amount, description) {
      if (Number(amount)==0 || !amount) {
        this.$notify({
          message: "Unable to send zero tokens",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          timeout: 0,
          type: "danger"
        });
        return ;
      }
      if (wallet=="") {
        this.$notify({
          message: "Please specify destination wallet",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          timeout: 0,
          type: "danger"
        });
        return ;
      }
      Shampoox().then(async api => {
        var error = new api.Error();
        var Pair = new api.Pair.fromPrivateKey(this.wallet.keyPair.private_key,error);
        var transaction = new api.Transaction();
        this.wallet.table_transaction = true;
        //this.sendtokenForm = false;
        let assets = this.wallet.assetCode;
        let code = assets.find(item => item.name ==  asset);
        if (code === undefined){
            var assetNames;
              assets.forEach(function (value) { 
                  assetNames = assetNames + " or " +value["name"];
                  });
            this.$notify({
                message: "Asset name "+asset+" not present in blockchain. Asset name must be "+assetNames,
                    icon: "add_alert",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    timeout: 0,
                    type: "fail"
                  });
        }
        var assetCode= code["code"];
        console.log(code)
        this.blockSend = true;
        this.isLoading = true
        let blockID = await this.getLastBlockID()
        if (!blockID) {
          this.$notify({
              message: "Unable to fetch last block id from backend",
              icon: "add_alert",
              horizontalAlign: "center",
              verticalAlign: "top",
              timeout: 0,
              type: "danger"
          })
          this.blockSend = false
          this.isLoading = false
          return
        }
        transaction.transfer(
          Pair,
          String(wallet),
          String(blockID),
          String(this.wallet.transaction_id),
          Number(assetCode),
          Number(amount),
          0.0,
          description,
          error
        );
        this.transaction.body = transaction.body;
        let params;
        try {
          params = JSON.parse(this.transaction.body)  
        } catch (e) {
          this.$notify({
              message: "Error creating transaction, "+error.what,
              icon: "add_alert",
              horizontalAlign: "center",
              verticalAlign: "top",
              timeout: 0,
              type: "danger"
            });
            this.blockSend = false
            this.isLoading = false
            return 
        }
        
        axios.all([
        axios.post(this.node_url,
          {
              "jsonrpc":  "2.0",
              "method":   "send-transaction",
              "params":   params, 
              "id":       3,
              "version":  "1.0"
          })
        ]).catch((e)=> {
            this.$notify({
              message: "Unknown error, please refer to console log for details",
              icon: "add_alert",
              horizontalAlign: "center",
              verticalAlign: "top",
              timeout: 0,
              type: "danger"
            });
            console.error("Got error while executing 'send-transaction': ",e)
            this.blockSend = false
            this.isLoading = false
        }).then(axios.spread((resp) => {
          this.blockSend = false
          this.isLoading = false
          this.sendtokenForm = false
          if(resp.data.result!="true"){
              this.$notify({
                message: resp.data.error["message"],
                icon: "add_alert",
                horizontalAlign: "center",
                verticalAlign: "top",
                timeout: 0,
                type: "fail"
              });

            }
            else
            {
              this.$notify({
                message: "Sent successfully",
                icon: "add_alert",
                horizontalAlign: "center",
                verticalAlign: "top",
                timeout: 0,
                type: "success"
              });
              this.coolDownTimer = 40
              this.transaction.amount=null
              this.cashout.amount=null
              this.xchange.amount=null
              this.wallet.transaction_id += 1
            }
        }))
      })
    },
    async getLastBlockID(){
      let response;
      let result;
      let params = {
                "jsonrpc":  "2.0",
                "method":   "get-current-block-id",
                "params":   {
                  }, 
                "id":       3,
                "version":  "1.0"
            };
        response = await fetch(this.node_url, {method: "POST",body: JSON.stringify(params)});
        if (response.ok) {
          result = await response.json()
          result = result.result
          return result["current-block-id"]
        } else {
          return null
        }
    },
    async getAssetCode(){
        let params = {
          "jsonrpc":  "2.0",
          "method":   "get-blockchain-info",
          "params":   {
            },  
          "id":       3,
          "version":  "1.0"
        }
        let response = await fetch(this.node_url, {method: "POST",body: JSON.stringify(params)});
        if (response.ok) {
          let assets=[];
          let result =  await response.json()
          result = result.result
          if(typeof result.error === "undefined"){ 
            result["supported-assets"].forEach(function (value) { 
              assets.push({
                "code":  value["code"].toUpperCase(),
                "name":    value["name"].toUpperCase()
              })
            })
            return assets
          } else {
            return null
          }
        } else {
          return null
        }
    },
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!  
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    CloseWallet(){
      this.wallet.keyPair = {};
      this.wallet.address = "";
      this.wallet.balance = [];
      this.wallet.inputTransaction = {};
      this.wallet.outputTransaction = {};
      this.wallet.loaded = false;
      this.existPrivateKey = false;
      this.wallet.table_transaction =false;
      this.sendtokenForm=false;
      //clearInterval(this.timer);
    },
    toggleTransactionsHistory() {
      this.showHistoryCard = !this.showHistoryCard;
    },
    openTransferAssetForm(){
      this.sendtokenForm=true;
      this.wallet.table_transaction = false;
    },
    
    cancelPrivateKey(){
      this.existPrivateKey=false;
    },
    openCreateAssetForm(){
      this.createasset=true;
      this.wallet.table_transaction = false;
    },
    openRegisterContractForm(){
      this.registercontract=!this.registercontract;
      this.wallet.table_transaction = false;
    },
    cancelSend(){
      this.sendtokenForm=false;
      this.registercontract = false;
      this.createasset = false;
      this.wallet.table_transaction = true;
    },
    cancelXChange() {
      this.xchangeForm = false
    },
    cancelCashout() {
      this.cashoutForm = false
    },

  parseHexString(str) { 
      var result = [];
      while (str.length >= 8) { 
          result.push(parseInt(str.substring(0, 8), 16));

          str = str.substring(8, str.length);
      }

      return result;
  },

createHexString(arr) {
    var result = "";
    var z;

    for (var i = 0; i < arr.length; i++) {
        var str = arr[i].toString(16);

        z = 8 - str.length + 1;
        str = Array(z).join("0") + str;

        result += str;
    }

    return result;
  },

    download(data, filename) {
    var file = new Blob([data], {
    type: "application/octet-stream"
  });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
        url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
      }
    },


    /**
     * Функция запроса стейта кошелька
     */
  updateWalletStatistic: async function () {
    this.smallLoader = true;
    let assets = await this.getAssetCode()
    await this.getXChangeRate()
    if (assets) {
      this.wallet.assetCode = assets
    } else {
      console.warn("error fetching tokens list from backend")
      return
    }
    axios.all([
        axios.post(this.node_url,
        {
            "jsonrpc":  "2.0",
            "method":   "get-wallet-state",
            "params":   {
                "public-key": this.wallet.address
              }, 
            "id":       3,
            "version":  "1.0"
        })
    ]).then(axios.spread((resp) => {
        let balance=[];
        let assets = this.wallet.assetCode;
        if("exist" in resp.data.result && resp.data.result["exist"]==="0"){
          balance.push({"code": 0,"amount": 0});
          this.wallet.balance = balance;
          this.wallet.loaded = true;
          this.wallet.table_transaction =true;
        }
        else{
          
          if ("balance" in resp.data.result)                                   
          resp.data.result.balance.forEach(function (value) {  
            let code = assets.find(item => String(item.code) ==  String(value["code"]));  
            
            balance.push({
              "code":        code["name"].toUpperCase(),
              "amount":    value["amount"]
            });
          });
          this.wallet.balance = balance;
          this.wallet.loaded = true;
          this.wallet.table_transaction =true;

        }
        this.smallLoader = false;
    }));
    },
    /**
     * Загрузка файла контейнера
     */
    loadContainerFile(infl) {
      
      this.existPrivateKey = false;
      let files = infl.target.files || infl.dataTransfer.files;
      if (!files.length) return;

      let reader = new FileReader();
      reader.onloadstart = ls_e => {
        this.wallet.progress.value = 0;
        if (ls_e.lengthComputable) this.wallet.progress.show = true;
      };
      reader.onprogress = lp_e => {
        if (lp_e.lengthComputable)
          this.wallet.progress.value = parseInt(
            (lp_e.loaded / lp_e.total) * 100,
            10
          );
      };
      reader.onerror = ler_e => {
        this.$notify({
          message:
            "File load failed: [" +
            ler_e.target.error.code +
            "] " +
            ler_e.target.error.message,
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0
        });
      };

      reader.onload = e => {
        try {
          var arrayBufferNew = null;
          arrayBufferNew  = e.target.result;
          var uint8array = this.parseHexString(arrayBufferNew);
          Swal.fire({
              title: "Input secret phrase",
              input: "password",
              inputAttributes: {
                autocapitalize: "off"
                },
              showCancelButton: true,
              showLoaderOnConfirm: true,
              confirmButtonClass: "md-button md-success",
              cancelButtonClass: "md-button md-danger",
              preConfirm: (phrase) => {
                if (!phrase.length) {
                  return Swal.showValidationMessage(`Phrase is empty`)
                  }
                },
            }).then((result) => {
                if (result.value) {
                  Container().then((ctr) => {
                    let errorContainer = new ctr.Error();
                    let keys = ctr.open_container(uint8array, result.value, errorContainer);
                    if (keys === undefined) {
                      Swal.fire({
                        type: "fail",
                        html: "Container not load",
                        confirmButtonClass: "md-button md-success",
                        buttonsStyling: false});
                    } else {
                      Swal.fire({
                        type: "success",
                        html:"<br> Wallet address:<br>"+keys.public_key,
                        confirmButtonClass: "md-button md-success",
                        buttonsStyling: false});
                      this.wallet.keyPair = keys;
                      this.wallet.address = keys.public_key;
                      this.updateWalletStatistic();
                      this.timer = setInterval(this.updateWalletStatistic, Number(20000));                                                              
                    }
                  });
                }
              });
        } catch (e) {
          this.$notify({
            message: "Calculate hash failed: " + e.stack,
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0
          });
        } finally {
          infl.target.value = null;
        }
        this.wallet.progress.value = 100;
        this.wallet.progress.show = false;
      };
      try {
        reader.readAsBinaryString(files[0]);
      } catch (e) {
        this.$notify({
          message: "File load failed: " + e.stack,
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0
        });
      }
    },
    // subtract 1 second from cooldown timer if it has been set
    manageCooldown() {
      if (this.coolDownTimer>0)
        this.coolDownTimer -= 1
    }
  },
  
  mounted() {
    this.coolDownTimerHandler = setInterval(this.manageCooldown, Number(1000));                                                             
  },
  watch: {
    
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.coolDownTimerHandler)
  }
};
</script>

<style lang="scss" scoped>
  .rates_grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 60%;
    border-left: 1px solid red;
    padding-left: 15px;
  }
  .rates_grid > .rates_grid_item {
    padding-top: 10px;
    flex: 1 1 33%;
    height: 55px;
  }
  @media (max-width: 991px) {
    .rates_grid {
      width: 60%;
    }
    .rates_grid > .rates_grid_item {
      padding-top: 10px;
      flex: 1 1 50%;
      height: 55px;
    }
  }
  .wallet-widget-height {
    min-height:200px;
  }
  .button-fixed-width {
    width: 105px;
  }
  .button-fixed-width-wide {
    width: 170px;
  }
  .button-text-left {
    

  }
</style>
