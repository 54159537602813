<template>
  <div class="md-layout-item md-size-100">
    <certificate-authority-wallets v-model="wallet.wallet">
    </certificate-authority-wallets>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon"><md-icon>mail_outline</md-icon></div>
          <h4 class="title">Certificates list</h4>
      </md-card-header>
      <md-card-content>
        <md-field>
          <md-input
            type="search"
            class="mb-3"
            clearable
            style="width: 300px"
            placeholder="Search"
            v-model="certificates_list.search.query"
          >
          </md-input>
        </md-field>
        <md-table
          :value="certificates_list_data"
          :md-sort.sync="certificates_list.sort.cell"
          :md-sort-order.sync="certificates_list.sort.order"
          :md-sort-fn="customSort"
          class="table-striped table-hover"
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="ID" md-sort-by="id">{{
              item.id
            }}</md-table-cell>
            <md-table-cell md-label="Public Key" md-sort-by="pubk">{{
              item.pubk
            }}</md-table-cell>
            <md-table-cell md-label="File name" md-sort-by="filename">{{
              item.filename
            }}</md-table-cell>
            <md-table-cell md-label="File MD5" md-sort-by="filehash">{{
              item.filehash
            }}</md-table-cell>
            <md-table-cell md-label="Run">
              <md-button
                v-show="wallet.wallet !== undefined && wallet.wallet.loaded && wallet.wallet.priv.length"
                class="md-just-icon md-info md-simple"
                @click.native="runContractFile(item)"
              >
                <md-icon>dvr</md-icon>
              </md-button>
            </md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
      <md-card-actions md-alignment="space-between" v-if="certificates_list_total">
        <div class="">
          <p class="card-category">
            Showing {{ certificates_list_from + 1 }} to
            {{ certificates_list_to_print }}
            of {{ certificates_list_total }} entries
          </p>
        </div>
        <certificate-authority-pagination
          class="certificateauthoritypagination-no-border certificateauthoritypagination-info"
          v-model="certificates_list.pagination.currentPage"
          :per-page="certificates_list.pagination.perPage"
          :total="certificates_list_total"
        >
        </certificate-authority-pagination>
      </md-card-actions>
    </md-card>

    <md-card v-if="wallet.wallet">
      <md-card-header class="md-card-header-icon md-card-header-aurus">
        <div class="card-text">
          <div class="card-icon"><md-icon>mail_outline</md-icon></div>
          <h4 class="title">Create certificate request</h4>
        </div>
      </md-card-header>
      <md-card-content>
        <md-field>
          <label for="pages">Wallet</label>
          <p><br/>{{ (wallet.wallet !== undefined && wallet.wallet.pub !== undefined) ? wallet.wallet.pub : "Not selected" }}</p>
        </md-field>
        <md-field>
          <label>Country</label>
          <md-input v-model="request_data.country" type="text"></md-input>
        </md-field>
        <md-field>
          <label>City</label>
          <md-input v-model="request_data.citry" type="text"></md-input>
        </md-field>
        <md-field>
          <label>Country</label>
          <md-input v-model="request_data.organization" type="text"></md-input>
        </md-field>
        <md-field>
          <label>Common</label>
          <md-input v-model="request_data.common" type="text"></md-input>
        </md-field>
        <md-button class="md-success" md-alignment="left" @click="requestCertificate">
          Request certificate
        </md-button>
      </md-card-content>
    </md-card>
    <p style="text-align:right;font-size:25%;">Rev. 2 [12-09-2019]</p>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import $ from "jquery";
import { setTimeout, clearTimeout } from "timers";
import Fuse from "fuse.js";
import axios from "axios";
import "@/pages/Dashboard/Wasm/shampoox.wasm"
import Shampoox from "@/pages/Dashboard/Wasm/shampoox";

export default {
  name: "certificate-authority",
  props: {
    alert_timeout: {
      type: Number,
      default: 2
    },
    refresh_list_period: {
      type: Number,
      default: 15
    },
    certificates_list_per_page: {
      type: Number,
      default: 15
    },
    certificates_list_req_count: {
      type: Number,
      default: 100
    },
    certificates_list_transaction_type: {
      type: Number,
      default: 33
    },
    certificates_list_contract_id: {
      type: Number,
      default: 6613
    },
    node_url: {
      type: String,
      default: ""
    }
  },
  computed: {
    apiURL() {
      return this.node_url + "/api";
    },
    certificates_list_data() {
      let result = this.certificates_list.data.data;
      if (this.certificates_list.search.searched.length > 0) {
        result = this.certificates_list.search.searched;
      }
      return result.slice(this.certificates_list_from, this.certificates_list_to);
    },
    certificates_list_to() {
      let highBound = this.certificates_list_from + this.certificates_list.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.certificates_list_total;
      }
      return highBound;
    },
    certificates_list_to_print() {
      return this.certificates_list_to < this.certificates_list_total
        ? this.certificates_list_to
        : this.certificates_list_total;
    },
    certificates_list_from() {
      return (
        this.certificates_list.pagination.perPage *
        (this.certificates_list.pagination.currentPage - 1)
      );
    },
    certificates_list_total() {
      return this.certificates_list.search.searched.length > 0
        ? this.certificates_list.search.searched.length
        : this.certificates_list.data.data.length;
    },
    searchQuery() {
      return this.certificates_list.search.query;
    },
    certificates_list_current_page() {
      return this.certificates_list.pagination.currentPage;
    }
  },
  data() {
    return {
      tmo_ref: undefined,
      wallet: {
        wallet: undefined
      },
      request_data: {
        country: "",
        city: "",
        organization: "",
        common: ""
      },
      certificates_list: {
        transaction_id: 1,
        data: {
          data: [],
          min: -1,
          max: -1
        },
        refresh_enabled: true,
        pagination: {
          perPage: this.certificates_list_per_page,
          currentPage: 1
        },
        refreshing: true,
        cread: 0,
        sort: {
          cell: "id",
          order: "asc"
        },
        search: {
          query: "",
          cells_to_search: ["id", "pubk", "filename", "filehash"],
          searched: [],
          fuse: null
        }
      }
    };
  },
  methods: {
    certificates_list_request_files(_force_update = false) {
      if (_force_update || this.certificates_list.pagination.currentPage === 1) {
        try {
          if (_force_update && this.tmo_ref !== undefined)
            clearTimeout(this.tmo_ref);
          this.tmo_ref = undefined;
          let start_id = 0;
          let count_id = parseInt(this.certificates_list_req_count);
          if (this.certificates_list.pagination.currentPage !== 1) {
            if (this.certificates_list.data.min < parseInt(this.certificates_list_req_count))
              count_id =
                parseInt(this.certificates_list_req_count) - this.certificates_list.data.min;
            start_id = this.certificates_list.data.max - this.certificates_list.data.min;
          } else {
            start_id = this.certificates_list.cread;
          }
          let reqd = {
            "start-transaction": start_id,
            "count-transaction": count_id,
            "code-contract": [
              parseInt(this.certificates_list_contract_id)
            ],
            "code-transaction": parseInt(this.certificates_list_transaction_type)
          };
          axios.post(this.apiURL, this.apiBody(1, "get-transactions-on-id", reqd)).then(
            _response => {
              if (_response.data.error === undefined) {
                let res = _response.data.result;
                if (
                  res !== undefined &&
                  res.transactions !== undefined &&
                  res.transactions.length > 0
                ) {
                  let curr_arr = [];
                  let curr_min = this.certificates_list.data.min;
                  let curr_max = this.certificates_list.data.max;

                  if (
                    this.certificates_list.pagination.currentPage === 1 &&
                    this.certificates_list.data.data.length
                  )
                    this.certificates_list.cread = this.certificates_list.cread + count_id;
                  for (var i = 0; i < res.transactions.length; i++) {
                    let trns = res.transactions[i];
                    if (
                      parseInt(trns.description["contract-id"]) ===
                      parseInt(this.certificates_list_contract_id)
                    ) {
                      let trns_id = parseInt(trns.ID);
                      if (
                        this.certificates_list.data.min > trns_id ||
                        this.certificates_list.data.max < trns_id
                      ) {
                        if (curr_arr.length === 0 && curr_min === -1) {
                          curr_min = trns_id;
                          curr_max = trns_id;
                        }
                        if (curr_min > trns_id) curr_min = trns_id;
                        if (curr_max < trns_id) curr_max = trns_id;
                        let add_trns = {};
                        let cont;
                        add_trns.id = trns.ID;
                        add_trns.pubk = trns.description["public-key"];
                        cont = trns.description["message-contract"].split(":");
                        add_trns.filename = cont[0];
                        add_trns.filehash = cont[1];
                        curr_arr.push(add_trns);
                      } else {
                        this.certificates_list.cread = 0;
                      }
                    }
                  }
                  if (curr_arr.length) {
                    if (this.certificates_list.data.min > curr_min)
                      this.certificates_list.data.min = curr_min;
                    if (this.certificates_list.data.max < curr_max)
                      this.certificates_list.data.max = curr_max;
                    this.certificates_list.data.data = curr_arr.concat(
                      this.certificates_list.data.data
                    );
                  }
                } else if (
                  res === undefined ||
                  res.transactions === undefined
                ) {
                  this.$notify({
                    message: "<b>Incorrect response format</b>",
                    icon: "add_alert",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger"
                  });
                }
              } else {
                this.$notify({
                  message:
                    "[" +
                    _response.data.error.code +
                    "] <b>" +
                    _response.data.error.message +
                    "</b>",
                  icon: "add_alert",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "danger"
                });
              }
              if (this.certificates_list.refreshing) {
                if (this.certificates_list.cread) {
                  this.certificates_list_request_files();
                } else {
                  this.tmo_ref = setTimeout(() => {
                    this.certificates_list_request_files();
                  }, parseInt(this.refresh_list_period) * 1000);
                }
              }
            },
            _e_response => {
              this.$notify({
                message: "<b><pre>" + _e_response.stack + "</pre></b>",
                icon: "add_alert",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger"
              });
            }
          );
        } catch (e) {
          this.$notify({
            message: "<b><pre>" + e.stack + "</pre></b>",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "rose"
          });
        }
      }
    },

    requestCertificate(item) {
      if (this.wallet.wallet !== undefined && this.wallet.wallet.pub) {
        Shampoox().then(api => {
          try {
            var error = new api.Error();
            var keyPairs = new api.Pair.fromPrivateKey(this.wallet.wallet.priv, error);
            axios.all([
              axios.post(
                this.apiURL,
                this.apiBody(2, "get-current-block-id", {})
              )
            ]) .then(axios.spread((res) => {
              let transaction = new api.Transaction();

              transaction.x509Request(
                keyPairs,
                String(res.data.result["current-block-id"]),
                String(this.certificates_list.transaction_id),
                String(this.certificates_list_contract_id),
                String("MESSAGE ID"), // TODO
                String(this.request_data.country),
                String(this.request_data.city),
                String(this.request_data.organization),
                String(this.request_data.common),
                0.0,
                error
              );

              axios.all([
                axios.post(
                  this.apiURL,
                  this.apiBody(3, "send-transaction", JSON.parse(transaction.body))
                )
              ]) .then(axios.spread((r) => {
                console.log(r);
                if(r.data.error === undefined) {
                  Swal.fire({
                    title: "Create certificate request success",
                    type: "success",
                    timer: this.alert_timeout * 1000,
                    confirmButtonClass: "md-button md-success",
                    buttonsStyling: false
                  });
                  this.certificates_list.transaction_id = this.certificates_list.transaction_id + 1;
                } else {
                  Swal.fire({
                    title: "Create certificate request failed",
                    html: "[" + r.data.error.code + "] " + r.data.error.message,
                    type: "error",
                    timer: this.alert_timeout * 1000,
                    confirmButtonClass: "md-button md-success",
                    buttonsStyling: false
                  });
                }
              })) .catch(e => {
                console.log(e.stack);
                Swal.fire({
                  title: "Create certificate request failed",
                  html: "<pre>" + e.stack + "</pre>",
                  type: "error",
                  timer: this.alert_timeout * 1000,
                  confirmButtonClass: "md-button md-success",
                  buttonsStyling: false
                });
              });
            })) .catch(e => {
              if(e !== undefined) console.log(e.stack);
              Swal.fire({
                title: "Run file failed",
                html: e !== undefined ? "<pre>" + e.stack + "</pre>" : "",
                type: "error",
                timer: this.alert_timeout * 1000,
                confirmButtonClass: "md-button md-success",
                buttonsStyling: false
              });
            });
          } catch (e) {
            if(e !== undefined) console.log(e.stack);
            Swal.fire({
              title: "Run file failed",
              html: e !== undefined ? "<pre>" + e.stack + "</pre>" : "",
              type: "error",
              timer: this.alert_timeout * 1000,
              confirmButtonClass: "md-button md-success",
              buttonsStyling: false
            });
          }
        });
      }
    },

    /**
     * Функция переключения сортировки столбцов в таблице
     */
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.certificates_list.sort.cell;
        if (this.certificates_list.sort.order === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    apiBody(id, method, params = {}) {
      return {
        method: method,
        params: params,
        id: id,
        jsonrpc: "2.0",
        version: "1.0"
      };
    }
  },
  mounted() {
    /**
     * Запуск автоматического периодического чтения списка файлов
     */
    this.certificates_list.refreshing = true;
    this.certificates_list_request_files(true);

    this.certificates_list.search.fuse = new Fuse(this.certificates_list.data.data, {
      keys: this.certificates_list.search.cells_to_search,
      threshold: 0.3
    });
},
  watch: {
    searchQuery(value) {
      let result = this.certificates_list.data.data;
      if (value !== "") {
        result = this.certificates_list.search.fuse.search(
          this.certificates_list.search.query
        );
        console.log("SEARCH RESULT", result);
      }
      this.certificates_list.search.searched = result;
    },
    certificates_list_current_page() {
      let pg_cnt = Math.ceil(this.certificates_list_total / this.certificates_list_per_page);
      if (
        (this.certificates_list.data.min > 1 &&
          this.certificates_list.pagination.currentPage >= pg_cnt - 1) ||
        this.certificates_list.pagination.currentPage === 1
      ) {
        this.certificates_list.refreshing = true;
        this.certificates_list_request_files(true);
      } else
        this.certificates_list.refreshing = false;
    }
  },
  beforeDestroy() {
    this.certificates_list.refreshing = false;
    if (this.tmo_ref !== undefined) {
      clearTimeout(this.tmo_ref);
      this.tmo_ref = undefined;
    }
  }
};
</script>

<style lang="scss" scoped></style>
