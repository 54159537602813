<template>
  <div class="md-layout ">
    <loading  :active.sync="isLoading" 
            :can-cancel="false" 
            :is-full-page="true"
    />
    <div class="md-layout-item md-size-100"> 
      <img src="/img/aurus-full.png" style="height: 50px;width:auto; margin-bottom: 40px;"/>
    </div>
    <div class="md-layout-item md-large-size-75 md-medium-size-100 md-medium-small-100"> 
      <md-card class="wallet-widget-height " >
        <md-card-header class="md-card-header-icon md-card-header-aurus">
            <div class="card-icon"> <md-icon>account_balance_wallet</md-icon></div>
              <h4 v-show="!wallet.loaded" class="title">Existing wallet</h4>
              <h4 v-show="wallet.loaded" class="title">Wallet: {{ wallet.address }}</h4>
        </md-card-header>
        <md-card-content>
          <div v-show="!wallet.loaded">
            <md-button  class="md-success md-fileinput button-fixed-width-wide" md-alignment="left">
              Log in
              <input type="file" @change="loadContainerFile" />
            </md-button> 
          </div>
          <div v-show="wallet.loaded">
          
          <div class="md-layout-item md-size-75 md-small-size-100">
            <h4>Update exchange rates</h4>
          <div class="fields_row">
            <md-field>
            <label>AUS/USD {{ current_rates[1] }}</label>
            <md-input v-model="updateForm[1]" type="text"></md-input>
            <md-button @click="UpdateRate(1)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[1]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[1] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>USD/AUS {{ current_rates[9] }}</label>
            <md-input v-model="updateForm[9]" type="text"></md-input>
            <md-button @click="UpdateRate(9)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[9]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[9] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>AUS/EUR {{ current_rates[17] }}</label>
            <md-input v-model="updateForm[17]" type="text"></md-input>
            <md-button @click="UpdateRate(17)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[17]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[17] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>EUR/AUS {{ current_rates[25] }}</label>
            <md-input v-model="updateForm[25]" type="text"></md-input>
            <md-button @click="UpdateRate(25)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[25]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[25] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>EUR/UST {{ current_rates[33] }}</label>
            <md-input v-model="updateForm[33]" type="text"></md-input>
            <md-button @click="UpdateRate(33)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[33]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[33] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>EUR/USD {{ current_rates[41] }}</label>
            <md-input v-model="updateForm[41]" type="text"></md-input>
            <md-button @click="UpdateRate(41)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[41]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[41] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>UST/USD {{ current_rates[49] }}</label>
            <md-input v-model="updateForm[49]" type="text"></md-input>
            <md-button @click="UpdateRate(49)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[49]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[49] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>UST/AUS {{ current_rates[57] }}</label>
            <md-input v-model="updateForm[57]" type="text"></md-input>
            <md-button @click="UpdateRate(57)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[57]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[57] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>UST/EUR {{ current_rates[65] }}</label>
            <md-input v-model="updateForm[65]" type="text"></md-input>
            <md-button @click="UpdateRate(65)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[65]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[65] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>USD/UST {{ current_rates[73] }}</label>
            <md-input v-model="updateForm[73]" type="text"></md-input>
            <md-button @click="UpdateRate(73)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[73]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[73] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>USD/EUR {{ current_rates[81] }}</label>
            <md-input v-model="updateForm[81]" type="text"></md-input>
            <md-button @click="UpdateRate(81)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[81]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[81] }} blocks ago )</span>
          </div>
          <div class="fields_row">
            <md-field>
            <label>AUS/UST  {{ current_rates[89] }}</label>
            <md-input v-model="updateForm[89]" type="text"></md-input>
            <md-button @click="UpdateRate(89)">Update</md-button>
            </md-field>
            <span v-if="tokenTimeouts[89]<4000">✅</span>
            <span v-else>❌ (rate is outdated: {{ tokenTimeouts[89] }} blocks ago )</span>
          </div>
          </div>
          <div>
          <md-button v-if="wallet.address" class="md-notice button-fixed-width-wide " md-alignment="left" @click="CloseWallet">
            Log out <i class="fas fa-sign-out-alt" style="padding-left:10px;font-size:0.9rem !important"></i>
          </md-button>
          </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>

import { setTimeout, clearTimeout } from "timers";
import Swal from "sweetalert2";
import axios from "axios";
import globalConfig from "@/globalConfig.js";
import "@/pages/Dashboard/Wasm/shampoox.wasm"
import Shampoox from "@/pages/Dashboard/Wasm/shampoox";
import "@/pages/Dashboard/Wasm/Container.wasm";
import Container from "@/pages/Dashboard/Wasm/Container";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "user-wallet",
  components: {
  Loading
  },
  props: {

    node_url: {
      type: String,
      default: globalConfig.BackendServer
    }
  },
  computed: {
  },
  data() {
    //host, wallet, asset, amount, description
    return {
      isLoading:  false,
      passphrase: "",
      update_interval: 20000,
      timer: null,
      current_rates: {
      },
      updateForm: {
        1:null,
        9:null,
        17:null,
        25:null,
        33:null,
        41:null,
        49:null,
        57:null,
        65:null,
        73:null,
        81:null,
        89:null
      },
      tokenTimeouts: {
        1:null,
        9:null,
        17:null,
        25:null,
        33:null,
        41:null,
        49:null,
        57:null,
        65:null,
        73:null,
        81:null,
        89:null
      },
      wallet: {
        address: "",
        message: "",
        hash: "",
        keyPair:{},
        inputTransaction:{},
        outputTransaction:{},
        assetCode:{},
        name: "not set",
        size: 0,
        loaded: false,
        table_transaction: false,
        transaction_id: 1,
        progress: {
          show: false,
          value: 0
        },
        
        balance:[]
      }
    };
  },

  methods: {
    async getLastBlockID(){
      let response;
      let result;
      let params = {
                "jsonrpc":  "2.0",
                "method":   "get-current-block-id",
                "params":   {
                  }, 
                "id":       3,
                "version":  "1.0"
            };
        response = await fetch(this.node_url, {method: 'POST',body: JSON.stringify(params)});
        if (response.ok) {
          result = await response.json()
          result = result.result
          return result['current-block-id']
        } else {
          return null
        }
    },
    async getAssetCode(){
        let params = {
          "jsonrpc":  "2.0",
          "method":   "get-blockchain-info",
          "params":   {
            },  
          "id":       3,
          "version":  "1.0"
        }
        let response = await fetch(this.node_url, {method: 'POST',body: JSON.stringify(params)});
        if (response.ok) {
          let assets=[];
          let result =  await response.json()
          result = result.result
          if(typeof result.error === 'undefined'){ 
            result["supported-assets"].forEach(function (value) { 
              assets.push({
                "code":  value["code"],
                "name":    value["name"]
              })
            })
            return assets
          } else {
            return null
          }
        } else {
          return null
        }
    },
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!  
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    async getXChangeRate() {
      let rate_source = "jajaHge4XcsRDz51uNT5Vn2YzALGL2SDLgzNKSXmf8texKHcU"
      let current_block_id = await this.getLastBlockID()
      let dst = rate_source
      let params = {
        "jsonrpc":  "2.0",
        "method":   "get-transactions-from-wallet",
        "params":   {"wallet": dst,
                    "code-transaction":36,  // ==user-defined-transaction
                    "count-transaction":100}, 
        "id":       3,
        "version":  "1.0"
      };
      const rate_codes = [1,9,17,25,33,41,49,57,65,73,81,89]
      let response = await fetch(this.node_url, {method: "POST",body: JSON.stringify(params)});
      if (response.ok) {
        let result = await response.json()
        let rates_markers = {}
        result = result.result
        if (result && result.counter>0) {
          console.log(result)
          let breakFlag = false
          result.transactions.forEach((item)=> {
            if (breakFlag)
              return
            let value = item.description.message
            let msgID = Number(item.description["message-id"])
            
            if (rates_markers[msgID] === undefined) {
              rates_markers[msgID] = Number(value)
              this.tokenTimeouts[msgID] = current_block_id - Number(item.description.seed)
            }
            let breakCounter=0
            rate_codes.forEach(code => {
              if (code in rates_markers)
                breakCounter++
              if (breakCounter==rate_codes.length)
                breakFlag = true
            })
            
          })
          this.current_rates = rates_markers
          console.log( this.tokenTimeouts)
        }
      } else {
        console.warn("Couldn't fetch exchange rates")
      }


    },
    async UpdateRate(x_type) {
      this.isLoading = true
      let block_id = await this.getLastBlockID()
      if (!block_id)
        this.isLoading = false
      Shampoox().then(api => {
        var error = new api.Error();
        var keyPairs = new api.Pair.fromPrivateKey(this.wallet.keyPair.private_key,error);
        var transaction = new api.Transaction();
        let amount = null
        amount = this.updateForm[x_type]
        
        if (!this.isNumeric(amount)) {
          this.$notify({
            message: "Rate value has to be numeric",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "fail",
            timeout: 0
          })
          this.isLoading = false
          return
        }
        this.wallet.transaction_id+=1
        transaction.userDefineTransaction(
          keyPairs,
          String(block_id), //block id
          String(this.wallet.transaction_id),  // transaction id
          Number(x_type),// message Id = token pair 
          String(amount),
          error
        )
        axios.all([
          axios.post(this.node_url,
            {
                "jsonrpc":  "2.0",
                "method":   "send-transaction",
                "params":   JSON.parse(transaction.body), 
                "id":       3,
                "version":  "1.0"
            })
          ]).then(axios.spread((resp) => {
            this.isLoading = false
            if(resp.data.result!="true"){
                this.$notify({
                  message: resp.data.error["message"],
                  icon: "add_alert",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "fail",
                  timeout: 0
                });

              }
              else
              {
                this.$notify({
                  message: "Updated successfully",
                  icon: "add_alert",
                  horizontalAlign: "center",
                  verticalAlign: "top",
                  type: "success",
                  timeout: 0
                });
              }
          }))
      });
    },
    CloseWallet(){
      this.wallet.keyPair = {};
      this.wallet.address = "";
      this.wallet.balance = [];
      this.wallet.inputTransaction = {};
      this.wallet.outputTransaction = {};
      this.wallet.loaded = false;
      this.existPrivateKey = false;
      this.wallet.table_transaction =false;
      this.sendtoken=false;
      //clearInterval(this.timer);
    },
    toggleTransactionsHistory() {
      this.showHistoryCard = !this.showHistoryCard;
    },
    openTransferAssetForm(){
      this.sendtoken=true;
      this.wallet.table_transaction = false;
    },
    
    cancelPrivateKey(){
      this.existPrivateKey=false;
    },
    openCreateAssetForm(){
      this.createasset=true;
      this.wallet.table_transaction = false;
    },
    openRegisterContractForm(){
      this.registercontract=!this.registercontract;
      this.wallet.table_transaction = false;
    },
    cancelSend(){
      this.sendtoken=false;
      this.registercontract = false;
      this.createasset = false;
      this.wallet.table_transaction = true;
    },

parseHexString(str) { 
    var result = [];
    while (str.length >= 8) { 
        result.push(parseInt(str.substring(0, 8), 16));

        str = str.substring(8, str.length);
    }

    return result;
},

createHexString(arr) {
    var result = "";
    var z;

    for (var i = 0; i < arr.length; i++) {
        var str = arr[i].toString(16);

        z = 8 - str.length + 1;
        str = Array(z).join("0") + str;

        result += str;
    }

    return result;
  },

    download(data, filename) {
    var file = new Blob([data], {
    type: 'application/octet-stream'
  });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
        url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
      }
    },


    /**
     * Функция запроса стейта кошелька
     */
      updateWalletStatistic: async function () {
        this.isLoading = true;
        let assets = await this.getAssetCode();
        if (assets) {
          this.wallet.assetCode = assets
        } else {
          console.warn("error fetching tokens list from backend")
          return
        }
        axios.all([
            axios.post(this.node_url,
            {
                "jsonrpc":  "2.0",
                "method":   "get-wallet-state",
                "params":   {
                    "public-key": this.wallet.address
                  }, 
                "id":       3,
                "version":  "1.0"
            })
        ]).then(axios.spread((resp) => {
            let balance=[];
            let assets = this.wallet.assetCode;
            if('exist' in resp.data.result && resp.data.result["exist"]==="0"){
              balance.push({"code": 0,"amount": 0});
              this.wallet.balance = balance;
	            this.wallet.loaded = true;
              this.wallet.table_transaction =true;
            }
            else{
              
              if ('balance' in resp.data.result)                                   
              resp.data.result.balance.forEach(function (value) {  
                let code = assets.find(item => String(item.code) ==  String(value["code"]));  
                
                balance.push({
                  "code":        code["name"],
                  "amount":    value["amount"]
                });
              });
              this.wallet.balance = balance;
              this.wallet.loaded = true;
              this.wallet.table_transaction =true;

            }
            this.isLoading = false;
        }));
    },
    
    /**
     * Загрузка файла контейнера
     */
    loadContainerFile(infl) {
      
      this.existPrivateKey = false;
      let files = infl.target.files || infl.dataTransfer.files;
      if (!files.length) return;

      let reader = new FileReader();
      reader.onloadstart = ls_e => {
        this.wallet.progress.value = 0;
        if (ls_e.lengthComputable) this.wallet.progress.show = true;
      };
      reader.onprogress = lp_e => {
        if (lp_e.lengthComputable)
          this.wallet.progress.value = parseInt(
            (lp_e.loaded / lp_e.total) * 100,
            10
          );
      };
      reader.onerror = ler_e => {
        this.$notify({
          message:
            "File load failed: [" +
            ler_e.target.error.code +
            "] " +
            ler_e.target.error.message,
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0
        });
      };

      reader.onload = e => {
        try {
          var arrayBufferNew = null;
          arrayBufferNew  = e.target.result;
          var uint8array = this.parseHexString(arrayBufferNew);
          Swal.fire({
              title: 'Input secret phrase',
              input: 'password',
              inputAttributes: {
                autocapitalize: 'off'
                },
              showCancelButton: true,
              showLoaderOnConfirm: true,
              confirmButtonClass: "md-button md-success",
              cancelButtonClass: "md-button md-danger",
              preConfirm: (phrase) => {
                if (!phrase.length) {
                  return Swal.showValidationMessage(`Phrase is empty`)
                  }
                },
            }).then((result) => {
                if (result.value) {
                  Container().then((ctr) => {
                    let errorContainer = new ctr.Error();
                    let keys = ctr.open_container(uint8array, result.value, errorContainer);
                    if (keys === undefined) {
                      Swal.fire({
                        type: "fail",
                        html: "Container not load",
                        confirmButtonClass: "md-button md-success",
                        buttonsStyling: false});
                    } else {
                      Swal.fire({
                        type: "success",
                        html:"<br> Wallet address:<br>"+keys.public_key,
                        confirmButtonClass: "md-button md-success",
                        buttonsStyling: false});
                      this.wallet.keyPair = keys;
                      this.wallet.address = keys.public_key;
                      this.updateWalletStatistic();
                      this.getXChangeRate()
                      //this.timer = setInterval(this.updateWalletStatistic, Number(20000));                                                              
                    }
                  });
                }
              });
        } catch (e) {
          this.$notify({
            message: "Calculate hash failed: " + e.stack,
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0
          });
        } finally {
          infl.target.value = null;
        }
        this.wallet.progress.value = 100;
        this.wallet.progress.show = false;
      };
      try {
        reader.readAsBinaryString(files[0]);
      } catch (e) {
        this.$notify({
          message: "File load failed: " + e.stack,
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
          timeout: 0
        });
      }
    },



  },
  
  mounted() {
    this.timer = setInterval(this.getXChangeRate, Number(this.update_interval));
  },
  watch: {
    
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss" scoped>
.md-field {
  width: 60%;
}
.fields_row {
  display: flex;
  
  span {
    padding-top: 25px;
    padding-left: 10px;
  }
  @media (max-width: 960px) {
    flex-flow: column;
    span {
      padding:0;
      margin-bottom: 15px;
    }
  }
}
.wallet-widget-height {
  min-height:200px;
}
.button-fixed-width {
  width: 105px;
}
.button-fixed-width-wide {
  width: 170px;
}
.button-text-left {
  

}
</style>
