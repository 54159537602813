<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-33 md-size-100">
        <chart-card
          header-animation="true"
          :chart-data="data"
          :chart-options="multipleBarsChart.options"
          :id="chartId"
          chart-type="Bar"
          header-icon
          chart-inside-content
          no-footer
          background-color="aurus"
          margin-left="90px"
        >
          <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class="title">
              Node Chart Statisitc
              <small> - Node Chart</small>
            </h4>
          </template>
        </chart-card>
      </div>


      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header>
            <h4 class="title">Node statistics</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="node_statistic" table-header-color="aurus">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
                <md-table-cell md-label="IP">{{ item.IP }}</md-table-cell>
                <md-table-cell
                  md-label="Public Key"
                  md-sort-by="previous"
                  class="tooltip"
                  >{{ item.key.slice(0, 4) + "..." }}
                  <span class="tooltiptext">
                    {{ item.key }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="Last ID block cosed as master">{{
                  item.BlockId
                }}</md-table-cell>
                <md-table-cell md-label="Count close block as master">{{
                  item.master
                }}</md-table-cell>
                <md-table-cell md-label="Count close block as escort">{{
                  item.escort
                }}</md-table-cell>
                <md-table-cell md-label="Registered">{{
                  item.register
                }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>

        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import globalConfig from "@/globalConfig.js";
import { ChartCard } from "@/components";
import PerfectScrollbar from "perfect-scrollbar";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}


export default {
  components: {
    ChartCard,
  },
  name: "nodes-stat",
  props: {
    // milliseconds to update map nodes
    update_interval: {
      type: Number,
      default: 2000,
    },
  },
  methods: {
    updateNodeStatistic: function () {
      let label = [];
      let master = [];
      let escort = [];
      let data = {
        labels: ["17", "18", "19"],
        series: [],
      };
      axios
        .all([
          axios.post(globalConfig.BackendServer, {
            jsonrpc: "2.0",
            method: "get-nodes",
            params: [],
            id: 3,
            version: "1.0",
          }),
          axios.post(globalConfig.BackendServer, {
            method: "get-nodes-stat",
            params: {},
            id: 1,
            jsonrpc: "2.0",
            version: "1.0",
          }),
        ])
        .then(
          axios.spread((resp, stat) => {
            let statistic = [];
            let id = 0;
            //let allStat =  stat.data.result["node-statistic"];
            resp.data.result.forEach(function (value) {
              let nodeStatistic = stat.data.result["node-statistic"][id];
              id++;
              const tmp = value["node-id"];
              label.push(String(tmp));
              master.push(Number(nodeStatistic["master"]));
              escort.push(Number(nodeStatistic["escort"]));
              statistic.push({
                id: value["node-id"],
                IP: value["address"],
                key: value["public-key"],
                BlockId: nodeStatistic["last-master"],
                register: nodeStatistic["registered"],
                master: nodeStatistic["master"],
                escort: nodeStatistic["escort"],
              });
            });
            this.node_statistic = statistic;
            data.labels = label;
            data.series.push(master);
            data.series.push(escort);
            this.data = data;
          })
        );
    },
  },
  data() {
    return {
      data: {},
      multipleBarsChart: {
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false,
          },
          height: "300px",
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      node_statistic: [],
      chartId: "nodeGraph",
    };
  },

  mounted() {
    let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("md-table");
        } 
    this.updateNodeStatistic();
    this.timer = setInterval(
      this.updateNodeStatistic,
      Number(this.update_interval)
    );
  }, //mounted
  watch: {},
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}
.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}
</style>
