<template>
<div id="content" >
  <loading  :active.sync="isLoading" 
            :can-cancel="false" 
            :is-full-page="true"
  >
  </loading>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card md-theme="aurus">
        <md-card-header class="md-card-header-icon md-card-header-aurus">
          <div class="card-icon" >
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Transactions history</h4>
        </md-card-header>
        <md-card-content >
            <div id="paginated-toolbar" class="flex">
            <!--
              <md-field >
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              -->
              <form v-on:submit.prevent="FilterSubmit('by_id')">
                <md-field>
                  <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    v-model="id_filter"
                    data-vv-name="id_filter"
                    placeholder="Search by ID"
                  >
                  </md-input>
                </md-field>
              </form>
              <form v-on:submit.prevent="walletFilterSubmit">
                <md-field>
                  <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    v-model="wallet_filter"
                    data-vv-name="wallet_filter"
                    placeholder="Search by from-wallet"
                  >
                  </md-input>
                </md-field>
              </form>
              <form v-on:submit.prevent="toWalletFilterSubmit">
                <md-field>
                  <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    v-model="wallet_filter_to"
                    data-vv-name="wallet_filter_to"
                    placeholder="Search by to-wallet"
                  >
                  </md-input>
                </md-field>
              </form>
              <form>
                <md-field >
                  <label for="trTypes">Transaction type</label>
                  <md-select v-model="transaction_type_filter" name="trTypes">
                    <md-option
                      v-for="(trTypeIndex, trTypeName) in supportedTransactions"
                      :key="trTypeName"
                      :label="trTypeName"
                      :value="trTypeIndex"
                    >
                    {{trTypeName}}
                      
                    </md-option>
                  </md-select>
                </md-field>
              </form>
              <md-button 
                  @click="updateTableFiltered"
                  class="md-primary md-sm"
              >Fetch</md-button>
              <md-button v-if="table_mode != 'default'" v-on:click="clearAndUpdateTable"
                  class="md-icon-button accent_red" id="clear_btn"
                ><md-icon class="accent_red">highlight_off</md-icon>
              </md-button>
              </div>
          <md-table
            :value="tableData"
            class="table-striped table-hover text-accent md-scrollbar"
            :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="sortHandler"
          >

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Index" md-sort-by="id">{{
                item.id
              }}</md-table-cell>
              <md-table-cell md-label="Tr Id" md-sort-by="transaction-id">{{
                item.tr_id
              }}</md-table-cell>
              <md-table-cell md-label="Tr type" md-sort-by="transaction-type">{{
                item.tr_type
              }}</md-table-cell>
              <md-table-cell md-label="From" md-sort-by="wallet-id" class="tooltip">{{item.src_short+'...'}}
                <!--<md-icon>assignment</md-icon>
                <md-tooltip md-direction="left">{{item.from}}</md-tooltip>-->
                <span class="tooltiptext">
                  {{item.src}}
                </span>
              </md-table-cell>
              <md-table-cell md-label="To" md-sort-by="destination-wallet" class="tooltip">{{item.to_short+'...'}}
                <span class="tooltiptext">
                  {{item.to}}
                </span>
              </md-table-cell>
              <md-table-cell md-label="Fee" >
                {{item.fee}}
              </md-table-cell>
              
              <md-table-cell md-label="Actions">
                <router-link :to="'/explorer/transaction/'+item.id+'/'" target="_blank">
                  <md-button class="md-just-icon">
                    <md-icon>search</md-icon>
                  </md-button>
                </router-link>
              </md-table-cell>              
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @input="pageChangeFunc"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
import { Pagination } from "@/components";
//import users from "./users";
//import temp_response from "./temp_resp.js";
//import transaction_ext_fields from "./transaction_types_fields.js";
import axios from "axios";
import globalConfig from "@/globalConfig.js";
import Vue from "vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    Pagination,
    Loading
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return parseInt(this.transaction_count);
    }
  },
  props: {
    // milliseconds to update map nodes
    update_interval: {
        type: Number,
        default: 20000
    }
  },
  data() {
    return {
      currentSort: "default",
      currentSortOrder: "asc",
      isLoading:  false,
      pagination: {
        prevPage: null,
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25],
        total: 0
      },
      table_mode: "default", // [default, from_wallet, ]
      wallet_filter: "",
      wallet_filter_to: "",
      id_filter: "",
      tableData: [],
      transaction_count: -1,
      transaction_count_total: -1,
      supportedTransactions: {},
      transaction_type_filter: "",
      //ext_fields: transaction_ext_fields,
    };
  },
  methods: {
    checkFilter() {
      let table_mode = "default";
      console.log("Updating table");
      if (this.wallet_filter!="") 
        table_mode = "by_wallet_from";
      if (this.wallet_filter_to!="")
        table_mode = "by_wallet_to";
      if (this.transaction_type_filter!="")
        table_mode = "by_tr_type";
      if (this.id_filter != "") {
        table_mode = "by_id";
      }
      this.table_mode = table_mode;
      if (this.table_mode == "default")
        this.updateTable();
    },
    clearAndUpdateTable: function() {
      this.table_mode = "default";
      this.currentSort = "default";
      this.wallet_filter = "";
      this.wallet_filter_to = "";
      this.transaction_type_filter = "";
      this.id_filter="";
      //this.lock_auto_update = false;
      //this.blocks_count = this.blocks_count_default;
      this.pagination.currentPage = 1;
      this.pagination.prevPage = null;
    },      
    updateTableFiltered() {
      this.currentSort = "default";
      if (this.wallet_filter!="") 
        this.table_mode = "by_wallet_from";
      if (this.wallet_filter_to!="")
        this.table_mode = "by_wallet_to";
      if (this.transaction_type_filter!="")
        this.table_mode = "by_tr_type";
      if (this.id_filter != "") {
        this.table_mode = "by_id";
      }
      this.updateTable();
    },
    FilterSubmit(mode="default") {
      this.table_mode = mode;
      this.pagination.currentPage = 1;
      this.pagination.prevPage = null;
      this.currentSort = "default";
      this.updateTable();
    },
    sortHandler: function(value) {
      console.log("Sorting by "+this.currentSort+ ", order: "+this.currentSortOrder);
      this.updateTable();
    },
    pageChangeFunc: function(pageNum) {
      console.log("Switch page to "+pageNum+" (current page is "+this.pagination.prevPage+")");
      if (pageNum == 1)
        this.lock_auto_update = false;
      else
        this.lock_auto_update = true;
      if (this.pagination.prevPage == pageNum) {
        if (this.pagination.prevPage == null)
          this.pagination.prevPage = 1;
        console.log("Cancel fetch");
        return;
      }
      this.pagination.prevPage = pageNum;
      //switch(this.table_mode) {
      //default: {
      this.updateTable(pageNum);
      //}
      //};
    },
    parseTransactionsOutput(jsonResult) {
      let i=0;
      let transaction_data = []
      if (!jsonResult.data.result['transactions']) {
        return [];
      }
      console.log(jsonResult);
      //let ext = this.ext_fields;
      jsonResult.data.result['transactions'].forEach(function (value) {
            i++;
            /* let details= {};
            if (value.description["transaction-type"] in ext) {
              ext[value.description["transaction-type"]].forEach(function (field) {
                details[field]=value.description[field];
              });
              
            } */
            let src_short = "";
            let to_short = "";
            if (value.description["from"])
              src_short = value.description["from"].slice(0,5);
            if (value.description["to"])
              to_short = value.description["to"].slice(0,5);
              
            transaction_data.push({
                "index":        i,
                "seed":         value.description["seed"],
                "tr_type":      value.description["transaction-type"],
                "tr_id":        value.description["transaction-id"],
                "src":          value.description["from"],
                "src_short":    src_short,
                "to":           value.description["to"],
                "to_short":     to_short,
                "fee":          value.description["fee"],
                "signature":    value.description["signature"],
                "type":         value["type"],
                "digest":       value["digest"],
                "id":           value["ID"]
                //"details":      details
            });
        });
      return transaction_data;
    },
     // fetch and draw table with all transactions
    updateTable: function(pageNum=1) {
      let start_offset = this.pagination.perPage*(pageNum-1);
      // show loader spinner
      this.isLoading = true;
      let query={
        "method":"get-transactions-on-id",
        "params":{
            "offset":  start_offset,
            "count-transaction": this.pagination.perPage
            }, 
        "id": 1, 
        "jsonrpc": "2.0", 
        "version": "1.0"
      };
      console.log("Filtering by "+this.table_mode);
      switch(this.table_mode) {
        case "by_id": {
          query.params["start-transaction"] = parseInt(this.id_filter);
          query.params["count-transaction"] = 1;
          break;
        }
        case "by_wallet_from": {
          query.method = "get-transactions-from-wallet";
          query.params["wallet"] = this.wallet_filter;
          break;
        }
        case "by_wallet_to": {
          query.method = "get-transactions-to-wallet";
          query.params["wallet"] = this.wallet_filter_to;
          break;
        }
        case "by_tr_type": {
          query.method = "get-transactions-on-id";
          query.params["code-transaction"] = parseInt(this.transaction_type_filter);
          query.params["start-transaction"] = this.transaction_count_total;
          break;
        }
        default: {
          query.params["start-transaction"] = this.transaction_count_total;
          break;
        }
      }
      if (this.currentSort != "default") {
        query.params["sorted-by"] = this.currentSort;
        query.params["sort-order"] = this.currentSortOrder;
      }
      axios.all([
        axios.post(globalConfig.BackendServer, query)
      ]).then(axios.spread((transactionsHistory) => {
        this.isLoading = false;
        let transaction_data=[];
        if (pageNum==1) {
          if ( transactionsHistory.data.result && transactionsHistory.data.result.hasOwnProperty("counter") ) {
            this.transaction_count = transactionsHistory.data.result["counter"];
          } else {
            //this.transaction_count = resp.data.result["block-count"];
          }
        }
        transaction_data = this.parseTransactionsOutput(transactionsHistory)
        this.tableData = transaction_data;
      }));
    }
  },
  async beforeMount() {
    console.log("Pre mount");
    // Fetch block chain state and info, get total number of transactions and 
    // a list of transaction types
    // to draw pagination and init some comboboxes
    let jsonRes = await axios.all([
      axios.post(globalConfig.BackendServer,
      {
          "jsonrpc":  "2.0",
          "method":   "get-blockchain-state",
          "params":   [],
          "id":       3,
          "version":  "1.0"
      }),
      axios.post(globalConfig.BackendServer,
      {
          "method":"get-blockchain-info",
          "params":   {}, 
          "id": 1, 
          "jsonrpc": "2.0", 
          "version": "1.0"
      })
    ]);
    let blockChainStateInfo = jsonRes[0];
    let blockChainInfo = jsonRes[1];
    console.log(blockChainInfo);
    this.transaction_count = parseInt(blockChainStateInfo.data.result["transaction-count"]);
    this.transaction_count_total = this.transaction_count;
    let supportedTransactions = blockChainInfo.data.result["supported-transaction-types"];
    for (let trName in supportedTransactions) {
      this.supportedTransactions[trName] = supportedTransactions[trName];
    }
    this.updateTable(); 
    
  },
  mounted() {
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("md-table");
    } 
    console.log("Mounted");
    // TBD - disable permanently ?
    //this.timer = setInterval(this.updateTable, Number(this.update_interval));
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    wallet_filter_to(value) {
      if (value!="") {
        this.table_mode = "by_wallet_to";
      } else {
        this.checkFilter();
      }
    },
    wallet_filter(value) {
      if (value!="") {
        this.table_mode = "by_wallet_from";
      } else {
        this.checkFilter();
      }
    },
    transaction_type_filter(value) {
      if (value!="") {
        this.table_mode = "by_tr_type";
      } else {
        this.checkFilter();
      }       
    },
    id_filter(value) {
      if (value!="") {
        this.table_mode = "by_id";
      } else {
        this.checkFilter();
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="css" scoped>
  .flex {
      display: flex;
      flex-wrap: wrap;
  }         
  .flex > form {
    margin-right: 5px;
  }                                            
  .md-card .md-card-actions{
    border: 0;
    margin-left: 20px;
    margin-right: 20px;
  }
  #clear_btn .accent_red, .accent_red {
    color: white !important;
    background-color: red !important;
  }
</style>
