<template>
<div class="content">
  <div class="md-layout">  
    <div class="md-layout-item md-size-33 md-size-100">
      <chart-card
        header-animation="true"
        :chart-data="BlockByDay"
        :chart-options="multipleBarsChart.options"
          :chart-responsiveOptions="multipleBarsChart.responsiveOptions"
        :id="chartId"
        :strokre = "red"
        chart-type="Line"
        header-icon
        chart-inside-content
        no-footer
        background-color="aurus"
      >
        <template slot="chartInsideHeader">
          <div class="card-icon">
            <md-icon>insert_chart</md-icon>
          </div>
          <h4 class="title">
            Block by day statistics
            <small> - Block By Day</small>
          </h4>
          </template>
          </chart-card>
    </div>
      <div class="md-layout-item md-size-33 md-size-100">
        <chart-card
          header-animation="true"
          :chart-data="RoundByDay"
          :chart-options="multipleBarsChart.options"
          :chart-responsiveOptions="multipleBarsChart.responsiveOptions"
          :id="chartId"
          chart-type="Line"
          header-icon
          chart-inside-content
          no-footer
          background-color="aurus"
        >
          <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class="title">
              Round by day statistics
              <small> - Round By Day</small>
            </h4>
            </template>
            </chart-card>
    </div>
      <div class="md-layout-item md-size-33 md-size-100">
        <chart-card
          header-animation="true"
          :chart-data="TopRound"
          :chart-options="multipleBarsChart.options"
          :chart-responsiveOptions="multipleBarsChart.responsiveOptions"
          :id="chartId"
          chart-type="Bar"
          header-icon
          chart-inside-content
          no-footer
          background-color="aurus"
        >
          <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class="title">
              Top round statistics
              <small> - Top Round</small>
            </h4>
            </template>
            </chart-card>
    </div>
      <div class="md-layout-item md-size-33 md-size-100">
        <chart-card
          header-animation="true"
          :chart-data="LastRound"
          :chart-options="multipleBarsChart.options"
          :chart-responsiveOptions="multipleBarsChart.responsiveOptions"
          :id="chartId"
          chart-type="Bar"
          header-icon
          chart-inside-content
          no-footer
          background-color="aurus"
        >
          <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class="title">
              Last 30 block round chart
              <small> - Last round chart</small>
            </h4>
            </template>
            </chart-card>
    </div>        
  </div>
</div>
</template>
<script>
import axios from "axios";
import globalConfig from "@/globalConfig.js";
import { ChartCard } from "@/components";

export default {
 components: {
    ChartCard
  },
  name: "nodes-stat",
  props: {
      // milliseconds to update map nodes
      update_interval: {
          type: Number,
          default: 2000
      }
  },
  methods: {
      updateNodeStatistic: function () {
        let label= [];
        let blocks= [];
        let data = {
              labels: [],
              series: []
        }
        axios.all([
            axios.post(globalConfig.BackendServer,
            {
                "jsonrpc":  "2.0",
                "method":   "get-block-by-day-statistic",
                "params":   [],
                "id":       3,
                "version":  "1.0"
            })
        ]).then(axios.spread((resp) => {
            let statistic=[];
            let allStat =  resp.data.result["block-by-day"];
            allStat.forEach(function (value) {
                label.push(String(value["date"]));
                blocks.push(Number(value["count"]));
                });
            data.labels = label;
            data.series.push(blocks);
            this.BlockByDay = data;
        }));
    },
      updateRoundStatistic: function () {
        let label= [];
        let blocks= [];
        let data = {
              labels: [],
              series: []
        }
        axios.all([
            axios.post(globalConfig.BackendServer,
            {
                "jsonrpc":  "2.0",
                "method":   "get-round-by-day-statistic",
                "params":   [],
                "id":       3,
                "version":  "1.0"
            })
        ]).then(axios.spread((resp) => {
            let statistic=[];
            let allStat =  resp.data.result["round-by-day"];
            allStat.forEach(function (value) {
                label.push(String(value["date"]));
                blocks.push(Number(value["count"]));
                });
            data.labels = label;
            data.series.push(blocks);
            this.RoundByDay = data;
        }));
    },
      updateTopRound: function () {
        let label= [];
        let rounds= [];
        let data = {
              labels: [],
              series: [ ]
        }
        axios.all([
            axios.post(globalConfig.BackendServer,
            {
                "jsonrpc":  "2.0",
                "method":   "get-top-round",
                "params":   [],
                "id":       3,
                "version":  "1.0"
            })
        ]).then(axios.spread((resp) => {
            let statistic=[];
            let allStat =  resp.data.result["top-round"];
            allStat.forEach(function (value) {
                label.push(String(value["block-id"]));
                rounds.push(Number(value["round"]));
                });
            data.labels = label;
            data.series.push(rounds);
            this.TopRound = data;
        }));
    },
      updateLastRound: function () {
        let label= [];
        let rounds= [];
        let data = {
              labels: [],
              series: [ ]
        }
        axios.all([
            axios.post(globalConfig.BackendServer,
            {
                "jsonrpc":  "2.0",
                "method":   "get-last-round",
                "params":   [],
                "id":       3,
                "version":  "1.0"
            })
        ]).then(axios.spread((resp) => {
            let statistic=[];
            let allStat =  resp.data.result["last-round"];
            allStat.forEach(function (value) {
                label.push(String(value["block-id"]));
                rounds.push(Number(value["round"]));
                });
            data.labels = label;
            data.series.push(rounds);
            this.LastRound = data;
        }));
    }



  },
    data() {
        return {
          BlockByDay: {},
          RoundByDay: {},
          TopRound:{},
          LastRound:{},
          multipleBarsChart:{
              options: {
                  seriesBarDistance: 10,
                  axisX: {
                      showGrid: true,
                      showLabel: true
                  },
                  axisY: {
                      showGrid: true,
                      showLabel: true
                  },
                  height: "300px",
              },
          responsiveOptions: [
            ["screen and (max-width: 640px)",
            {seriesBarDistance: 5,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value[0];
                    }
              }
            }
            ]
          ]
          },
          chartId: "nodeGraph"
      };
    },

    mounted() {
        this.updateNodeStatistic();
        this.updateRoundStatistic();
        this.updateTopRound();
        this.updateLastRound();
        this.timer = setInterval(this.updateNodeStatistic, Number(this.update_interval));
    },//mounted
    watch: {
  },
    beforeDestroy() {
        clearInterval(this.timer)
    }
};
</script>
<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.category {
  margin: 0;
}

.md-card-actions .md-layout-item {
  padding: 0;
}
</style>
