<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar }
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <!-- <mobile-menu></mobile-menu> -->
      <template slot="links">
        <sidebar-item
          :link="{ name: 'State', icon: 'dashboard', path: '/dashboard' }"
        >
        </sidebar-item>
        <sidebar-item :link="{ name: 'Explorer', icon: 'travel_explore' }">
          <sidebar-item
            :link="{ name: 'Blocks', path: '/explorer/blocks' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Transactions', path: '/explorer/transactions' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Statistics', path: '/explorer/statistics' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'Nodes', icon: 'cloud' }">
          <sidebar-item
            :link="{ name: 'Map', path: '/nodes/map' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Statistics', path: '/nodes/statistics' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'Tokens', icon: 'attach_money' }">
          <sidebar-item
            :link="{ name: 'Assets', path: '/tokens/assets' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Wallet', path: '/tokens/wallet' }">
          </sidebar-item>
          
        </sidebar-item>        
      </template>
    </side-bar>
    <div class="main-panel" :style="mainPanelStyle">
      <!--<top-navbar></top-navbar>-->
        <md-toolbar
          md-elevation="0"
          class="md-transparent"
          :class="{
            'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute
          }"
        >
          <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
              <h3 class="md-title">{{ $route.name }} {{$route.proj_name}}</h3>
            </div>
            <div class="md-toolbar-section-end">
              <md-button
                class="md-just-icon md-round md-simple md-toolbar-toggle"
                :class="{ toggled: $sidebar.showSidebar }"
                @click="toggleSidebar"
              >
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </md-button>

              
            </div>
          </div>
        </md-toolbar>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="minimizeSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

//import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
//import MobileMenu from "./Extra/MobileMenu.vue";
//import FixedPlugin from "../../FixedPlugin.vue";
//import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
 //   TopNavbar,
    ContentFooter,
  //  MobileMenu,
  //  FixedPlugin,
  //  UserMenu,
    ZoomCenterTransition
  },
  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "aurus",
      sidebarBackgroundImage: "./img/sidebar-2.jpg",
      mainPanelBackgroundImage: "./img/bgpattern2.png",
      // right sideBar is minimizable 
      sidebarMini: true,
      sidebarImg: true
    };
  },
  methods: {
    toggleSidebar() {
      console.log("toobar click");
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        //this.$sidebar.toggleMinimize();
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  computed: {
    mainPanelStyle() {
      return {
        backgroundImage: `url(${this.mainPanelBackgroundImage})`
      };
    }
  },
  mounted() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");
      initScrollbar("main-panel");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    }
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
