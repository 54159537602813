<template>
  <ul class="smartpagination" :class="smartpaginationClass">
    <li
      class="smart-page-item smart-prev-page"
      :class="{ disabled: value === 1, 'smart-no-arrows': noArrows }"
    >
      <a class="smart-page-link" aria-label="Previous" @click="prevPage">
        <i class="fas fa-angle-double-left"></i>
      </a>
    </li>
    <li
      class="smart-page-item"
      v-for="item in range(minPage, maxPage)"
      :key="item"
      :class="{ active: value === item }"
    >
      <a class="smart-page-link" @click="changePage(item)">{{ item }}</a>
    </li>
    <li
      class="smart-page-item smart-page-pre smart-next-page"
      :class="{ disabled: value === totalPages, 'smart-no-arrows': noArrows }"
    >
      <a class="smart-page-link" aria-label="Next" @click="nextPage">
        <i class="fas fa-angle-double-right"></i>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: "user-pagination",
  props: {
    type: {
      type: String,
      default: "primary",
      validator: value => {
        return [
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
          "rose"
        ].includes(value);
      }
    },
    noArrows: Boolean,
    pageCount: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 1
    }
  },
  computed: {
    smartpaginationClass() {
      return `smartpagination-${this.type}`;
    },
    totalPages() {
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.value - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    }
  },
  data() {
    return {
      defaultPagesToDisplay: 5
    };
  },
  methods: {
    range(min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changePage(item) {
      this.$emit("input", item);
    },
    nextPage() {
      if (this.value < this.totalPages) {
        this.$emit("input", this.value + 1);
      }
    },
    prevPage() {
      if (this.value > 1) {
        this.$emit("input", this.value - 1);
      }
    }
  },
  watch: {
    perPage() {
      this.$emit("input", 1);
    },
    total() {
      this.$emit("input", 1);
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/material-dashboard.scss";
.smart-page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #2196f3;
  background-color: transparent;
  border: 0 solid #dee2e6;
}

.smart-no-arrows {
  display: none;
}

.smartpagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;

  > .smart-page-item > .smart-page-link,
  > .smart-page-item > span {
    border: 0;
    border-radius: 30px !important;
    transition: all 0.3s;
    margin: 0 3px;
    padding: 0;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    color: $gray-color;
    font-weight: $font-weight-default;
    font-size: $mdb-btn-font-size-base;
    text-transform: uppercase;
    background: transparent;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $gray-color !important;
    }
  }

  > .smart-page-item.active > a,
  > .smart-page-item.active > span {
    color: $gray-color;
    &,
    &:focus,
    &:hover {
      background-color: $brand-primary;
      border-color: $brand-primary;
      color: $white-color !important;
      @include shadow-4dp-color($brand-primary);
    }
  }

  // Colors
  &.smartpagination-info {
    > .smart-page-item.active > a,
    > .smart-page-item.active > span {
      &,
      &:focus,
      &:hover {
        background-color: $brand-info;
        border-color: $brand-info;
        @include shadow-4dp-color($brand-info);
      }
    }
  }

  &.smartpagination-success {
    > .smart-page-item.active > a,
    > .smart-page-item.active > span {
      &,
      &:focus,
      &:hover {
        background-color: $brand-success;
        border-color: $brand-success;
        @include shadow-4dp-color($brand-success);
      }
    }
  }
  &.smartpagination-rose {
    > .page-item.active > a,
    > .page-item.active > span {
      &,
      &:focus,
      &:hover {
        background-color: $brand-rose;
        border-color: $brand-rose;
        @include shadow-4dp-color($brand-rose);
      }
    }
  }

  &.smartpagination-warning {
    > .smart-page-item.active > a,
    > .smart-page-item.active > span {
      &,
      &:focus,
      &:hover {
        background-color: $brand-warning;
        border-color: $brand-warning;
        @include shadow-4dp-color($brand-warning);
      }
    }
  }

  &.smartpagination-danger {
    > .smart-page-item.active > a,
    > .smart-page-item.active > span {
      &,
      &:focus,
      &:hover {
        background-color: $brand-danger;
        border-color: $brand-danger;
        @include shadow-4dp-color($brand-danger);
      }
    }
  }
}
</style>
